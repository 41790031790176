import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { FolioRowTotalPipe } from '../helpers/pipes/folio-row-total.pipe';
import { OrwiArrayFilter } from '../helpers/pipes/orwi-array-filter';
import { OrwiCurrencyPipe } from '../helpers/pipes/orwi-currency.pipe';
import { OrwiDiscontPipe } from '../helpers/pipes/orwi-discont.pipe';
import { OrwiFoliosTotalPricePipe } from '../helpers/pipes/orwi-folios-total-price.pipe';
import { OrwiTimePipe } from '../helpers/pipes/orwi-time.pipe';
import { OrwPhoneInputListComponent } from './components/orw-phone-input-list/orw-phone-input-list.component';
import { OrwiButtonComponent } from './components/orwi-button/orwi-button.component';
import { OrwiFolioButtonComponent } from './components/orwi-folio-button/orwi-folio-button.component';
import { OrwiListModalComponent } from './components/orwi-list-modal/orwi-list-modal.component';
// import { OrwiModalHeaderComponent } from './components/orwi-modal-header/orwi-modal-header.component';
import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxSelectBoxModule,
  DxTagBoxModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { ArraySorterPipe } from '../helpers/pipes/array-sorter.pipe';
import { OrwiArrayFilterLivePipe } from '../helpers/pipes/orwi-array-filter-live.pipe';
import { PriceWithKdvPipe } from '../helpers/pipes/price-with-kdv.pipe';
import { PreviewModalComponent } from '../modules/backOffice/central-order/accept-goods/components/preview-modal/preview-modal.component';
import { HeaderBarComponent } from '../modules/backOffice/shared/components/header-bar/header-bar.component';
import { BarcodeScanningModalComponent } from '../services/barcode-scan/barcode-scan.modal';
import { KerzzButtonComponent } from './components/buttons/button.base';
import { ChildStoreComponent } from './components/child-store/child-store.component';
import { CurrentAccountFormComponent } from './components/current-account-form/current-account-form.component';
import { InvoiceCurrentAccountModalComponent } from './components/invoice-current-account-modal/invoice-current-account-modal.component';
import { OrwiBarcodeListModalComponent } from './components/orwi-barcode-list-modal/orwi-barcode-list-modal.component';
import { OrwiDatatableComponent } from './components/orwi-datatable/orwi-datatable.component';
import { OrwiModalHeaderComponent } from './components/orwi-modal-header/orwi-modal-header.component';
import { OrwiPhoneInputComponent } from './components/orwi-phone-input/orwi-phone-input.component';
import { OrwiSelectComponent } from './components/orwi-select/orwi-select.component';
import { ProgressModalComponent } from './components/progress-modal/progress-modal.component';
import { QuestionDialogComponent } from './components/question-dialog/question-dialog.component';
import { StoreListModalComponent } from './components/store-list-modal/store-list-modal.component';
import { FolioLogFormComponent } from './components/folio-log-form/folio-log-form.component';
import { FolioRowTotalWithModifiersPipe } from '../helpers/pipes/folioRowTotalWithModifiers.pipe';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';

LicenseManager.setLicenseKey(
  '[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-069969}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{14 December 2024}____[v3]_[0102]_MTczNDEzNDQwMDAwMA==780fd123a2c611cd03659bed35285e94'
);

export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // I will only use the swap gesture so
    // I will deactivate the others to avoid overlaps
    pinch: { enable: true },
    rotate: { enable: true },
  };
}

@NgModule({
  declarations: [
    OrwiCurrencyPipe,
    ArraySorterPipe,
    OrwiArrayFilter,
    OrwiButtonComponent,
    QuestionDialogComponent,
    OrwiFolioButtonComponent,
    ProgressModalComponent,
    OrwiTimePipe,
    FolioRowTotalPipe,
    OrwiFoliosTotalPricePipe,
    OrwiDiscontPipe,
    OrwiModalHeaderComponent,
    OrwiPhoneInputComponent,
    HeaderBarComponent,
    OrwPhoneInputListComponent,
    StoreListModalComponent,
    ChildStoreComponent,
    OrwiListModalComponent,
    InvoiceCurrentAccountModalComponent,
    CurrentAccountFormComponent,
    PriceWithKdvPipe,
    //OrwiTitlePipe,
    KerzzButtonComponent,
    BarcodeScanningModalComponent,
    OrwiBarcodeListModalComponent,
    PreviewModalComponent,
    OrwiSelectComponent,
    OrwiArrayFilterLivePipe,
    OrwiDatatableComponent,
    FolioLogFormComponent,
    FolioRowTotalWithModifiersPipe,
    ImageUploaderComponent,
  ],
  imports: [
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true,
    }),
    CommonModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HammerModule,
    //DxDataGridModule,
    //DxAccordionModule,
    DxButtonModule,
    DxDataGridModule,
    //DxDateBoxModule,
    //DxFormModule,
    //DxHtmlEditorModule,
    //DxPieChartModule,
    //DxSelectBoxModule,
    //DxSpeedDialActionModule,
    //DxTagBoxModule,
    DxTextBoxModule,
    //DxTileViewModule,
    DxCheckBoxModule,
    AgGridModule,
  ],
  exports: [
    OrwiCurrencyPipe,
    ArraySorterPipe,
    OrwiArrayFilter,
    OrwiArrayFilterLivePipe,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    OrwiButtonComponent,
    QuestionDialogComponent,
    OrwiFolioButtonComponent,
    OrwiTimePipe,
    FolioRowTotalPipe,
    OrwiFoliosTotalPricePipe,
    OrwiDiscontPipe,
    OrwiModalHeaderComponent,
    OrwiPhoneInputComponent,
    OrwPhoneInputListComponent,
    NgSelectModule,
    StoreListModalComponent,
    ChildStoreComponent,
    OrwiListModalComponent,
    HeaderBarComponent,
    DxDataGridModule,
    DxTagBoxModule,
    //DxAccordionModule,
    DxButtonModule,

    //DxDateBoxModule,
    //DxFormModule,
    //DxHtmlEditorModule,
    //DxPieChartModule,
    DxSelectBoxModule,
    //DxSpeedDialActionModule,
    //DxTagBoxModule,
    DxTextBoxModule,
    //DxTileViewModule,
    PriceWithKdvPipe,
    //OrwiTitlePipe,
    KerzzButtonComponent,
    BarcodeScanningModalComponent,
    OrwiBarcodeListModalComponent,
    PreviewModalComponent,
    OrwiSelectComponent,
    DxCheckBoxModule,
    AgGridModule,
    OrwiDatatableComponent,
    IonicModule,
    FolioLogFormComponent,
    FolioRowTotalWithModifiersPipe,
    ImageUploaderComponent,
  ],
  providers: [FolioRowTotalPipe, OrwiCurrencyPipe],
})
export class SharedModule {}
