<ion-card id="address-{{ addressData.id }}">
  <ion-card-content>
    <div *ngIf="isEditing">
      <ion-accordion [value]="addressData.id">
        <ion-item style="font-weight: 600;
    font-size: 16px; color: #171e4e;" slot="header" lines="none">
          {{ addressFormGroup.get("addressName").value }}
        </ion-item>
        <div slot="content">
          <ion-row [formGroup]="addressFormGroup">
            <ion-col size="12">
              <ion-item lines="none" class="selector-container">
                <ion-input
                  label="{{ 'Address Name' | transloco }}"
                  formControlName="addressName"
                ></ion-input>
              </ion-item>
            </ion-col>
            <!-- <ion-col size="12" [sizeMd]="12">
            <ion-item lines="none">
              <ion-label position="stacked">
                {{ "Country" | transloco }}
              </ion-label>
  
              <ion-select
                formControlName="Country"
                [placeholder]="'Select Country' | transloco"
                >>
                <ion-select-option
                  [value]="item.code"
                  *ngFor="let item of CountryInfo.countries"
                  >{{ item.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col> -->

            <ion-col size="12" [sizeMd]="6">
              <ion-item lines="none" class="selector-container">
                <ion-select
                  cancelText="{{ 'Cancel' | transloco }}"
                  okText="{{ 'Done' | transloco }}"
                  formControlName="city"
                  [label]="'City' | transloco"
                  class="warehouse-selector"
                >
                  <ion-select-option
                    [value]="item.code"
                    *ngFor="let item of CountryInfo.cities"
                    >{{ item.name }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>

            <ion-col size="12" [sizeMd]="6">
              <ion-item lines="none" class="selector-container">
                <ion-select
                  cancelText="{{ 'Cancel' | transloco }}"
                  okText="{{ 'Done' | transloco }}"
                  class="warehouse-selector"
                  formControlName="town"
                  [label]="'Town' | transloco"
                >
                  <ion-select-option
                    [value]="item.code"
                    *ngFor="let item of CountryInfo.towns"
                    >{{ item.name }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item lines="none" class="selector-container">
                <ion-textarea
                  label="{{ 'Address' | transloco }}"
                  placeholder="{{ 'Address' | transloco }}"
                  formControlName="address"
                ></ion-textarea>
              </ion-item>
            </ion-col>
            <ion-col size="12">
              <div class="address-area ion-justify-content-end">
                <ion-button
                  (click)="onDeleteAddress.emit()"
                  style="--background: #ed4c4c"
                >
                  {{ "Delete Address" | transloco }}
                </ion-button>
                <!-- <ion-button
                  (click)="onCancelAddress.emit()"
                  style="--background: #ed924c"
                >
                  {{ "cancel" | transloco }}
                </ion-button> -->
                <ion-button
                  (click)="onSaveAddress.emit()"
                  style="--background: #4cd9ed"
                >
                  {{ "Save Address" | transloco }}
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </ion-accordion>
    </div>

    <div *ngIf="!isEditing">
      <div class="address-area">
        <div class="address-title">
          <div style="display: flex; align-items: center">
            <ion-radio labelPlacement="end" [value]="addressData.id">{{
              addressFormGroup.get("addressName").value
            }}</ion-radio>
          </div>
        </div>
        <!-- <div class="address-buttons">
          <ion-button fill="clear" color="primary" (click)="openEdit()"
            >Düzenle</ion-button
          >
        </div> -->
      </div>
    </div>
  </ion-card-content>
</ion-card>
