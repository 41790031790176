import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, map } from 'rxjs';
import { MenuState, MenuStore } from './menu.store';

@Injectable({ providedIn: 'root' })
export class MenuQuery extends Query<MenuState> {
  constructor(protected store: MenuStore) {
    super(store);
  }

  private static sortByOrderName(a: any, b: any): number {
    if (a.order !== b.order) {
      return a.order - b.order;
    }
    return a.name.localeCompare(b.name);
  }

  groups$ = this.select('groups');

  activeProducts$ = this.select('activeProducts').pipe(
    map((data) => data?.sort(MenuQuery.sortByOrderName))
  );

  subGroups$ = this.select('subGroups').pipe(
    map((subGroups) =>
      subGroups
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .map((subGroup) => ({
          ...subGroup,
          group: subGroup.group.sort(MenuQuery.sortByOrderName),
        }))
    )
  );

  selectedProduct$ = this.select('selectedProduct');
  selectedGroup$ = this.select('selectedGroup');
  selectedSubGroup$ = this.select('selectedSubGroup');

  products$ = this.select('products').pipe(
    map((data) => data.sort(MenuQuery.sortByOrderName))
  );

  productGroupModifiers$ = this.select('groupModifiers');

  groupModifiers$ = this.productGroupModifiers$.pipe(
    map((data) => data.filter((dt) => dt.modif[0]?.type === 'group'))
  );

  productModifiers$ = this.select('productModifiers').pipe(
    map((data) => data.filter((dt) => dt.modif[0]?.type === 'product'))
  );

  customModifiers$ = combineLatest([
    this.select('productModifiers'),
    this.select('groupModifiers'),
  ]).pipe(
    map(([productModifiers, groupModifiers]) =>
      [...productModifiers, ...groupModifiers].filter(
        (dt) => dt.modif[0]?.type === 'custom'
      )
    )
  );

  allGroups$ = this.select('allGroups').pipe(
    map((data) => data.sort(MenuQuery.sortByOrderName))
  );

  get allGroups() {
    return this.getValue().allGroups;
  }

  get subGroups() {
    return this.getValue().subGroups;
  }
}
