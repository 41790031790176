import {
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Directive } from '@angular/core';
import { Gesture, GestureController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ITable } from 'src/app/services/dto/tables';
import { TableService } from 'src/app/modules/tables/state/table.service';
import { TableStore } from 'src/app/modules/tables/state/table.store';

@Directive({
  selector: '[OrwiDesignTable]',
})
export class DesignTabeDirective implements OnChanges {
  modeSubscription: Subscription;
  mode: 'design' | 'move' = 'design';
  findedTableId = '';
  findedTableGroupId = '';
  groupId = '';

  @Input() table: ITable;
  gesture: Gesture;

  @Output() dragOut: EventEmitter<boolean> = new EventEmitter();
  tableElements: HTMLElement[] = [];
  tableGroupElements: HTMLElement[] = [];
  gestureStarted: boolean;
  outOfTheBox: boolean;

  constructor(
    private tableService: TableService,
    private tableStore: TableStore,
    public gestureCtrl: GestureController,
    private plt: Platform,
    private elementRef: ElementRef
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    changes; //reserved

    this.init();
  }

  ngAfterViewInit() {
    if (this.plt.is('desktop')) {
      this.pressingEnd = false;
      this.pressing = true;
      this.gestureStarted = true;
    }
  }

  ngOnDestroy() {}

  @HostListener('click', ['$event'])
  onClick() {}

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  pressing = false;
  pressingEnd = false;
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  init() {
    if (!this.table.transform) this.table.transform = { rotate: 0, scale: 1 };

    let cover: HTMLElement = this.elementRef.nativeElement;

    if (this.table.coordinates?.x !== '0px') {
      cover.style.position = 'absolute';
      cover.style.top = this.table.coordinates.y;
      cover.style.left = this.table.coordinates.x;
      cover.style.transform = `
      rotate(${this.table.transform.rotate}deg)
      scale(${this.table.transform.scale})`;
      cover.style.zIndex = "1";
    } else {
      cover.style.position = '';
      cover.style.top = '';
      cover.style.left = '';
      cover.style.transform = ``;
      cover.style.zIndex = "1";
    }

    let fars = 0;
    let mars = 0;

    let ne: HTMLElement = this.elementRef.nativeElement;
    let tableCover: HTMLElement = document.getElementById('table-cover');

    let computedStyle = getComputedStyle(document.body);
    let safeAreaStr = computedStyle.getPropertyValue('--ion-safe-area-top');
    let safeArea = 0;
    if (safeAreaStr) {
      safeArea = parseInt(safeAreaStr.replace('px', ''));
    }

    tableCover.style.position = 'relative';

    if (cover) {
      this.gesture = this.gestureCtrl.create(
        {
          el: cover,
          gestureName: 'design-table',
          threshold: 0,
          maxAngle: 0,
          gesturePriority: 1,
          disableScroll: true,
          passive: true,

          onStart: (detail) => {
            cover.style.position = 'absolute';

            console.log(
              detail.currentY,
              cover.offsetTop + tableCover.offsetTop
            );
            fars = detail.currentY - (cover.offsetTop + tableCover.offsetTop);
            mars = detail.currentX - (cover.offsetLeft + tableCover.offsetLeft);
          },

          onEnd: (detail) => {
            //if (!this.gestureStarted) return

            cover.classList.remove('xicon');
            cover.style.filter = 'unset';
            ne.style.filter = 'unset';
            cover.style.transform = `translate(0,0)
          rotate(${this.table.transform.rotate}deg)
          scale(${this.table.transform.scale})`;

            let top =
              detail.currentY -
              tableCover.offsetTop -
              safeArea -
              cover.offsetHeight;
            let left =
              detail.currentX - tableCover.offsetLeft - cover.offsetWidth / 2;

            top = detail.currentY - tableCover.offsetTop - fars;
            left = detail.currentX - tableCover.offsetLeft - mars;

            console.log(detail.currentY, tableCover.offsetTop, cover.offsetTop);

            cover.style.top = top + 'px';
            cover.style.left = left + 'px';

            this.table.coordinates = {
              x: cover.style.left,
              y: cover.style.top,
            };
            this.tableStore.update({ inDesignTable: this.table });

            this.tableService.saveTable(this.table);
          },

          onMove: (detail) => {
            const droppableArea = cover.getBoundingClientRect();
            if (
              detail.currentX < droppableArea.left ||
              detail.currentX > droppableArea.right
            ) {
              cover.style.filter = '';
              this.outOfTheBox = true;
            }
            cover.style.transform = `
          translate(${detail.deltaX}px, ${detail.deltaY}px)
          rotate(${this.table.transform.rotate}deg)
          scale(${this.table.transform.scale})`;
          },
        },
        true
      );

      this.gesture.enable();
    }
  }
}
