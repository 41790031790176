<ion-content>
  <div class="folio">
    <orwi-folio-header></orwi-folio-header>

    <folio-rows id="folio-cover-folio" style="flex-grow: 2"></folio-rows>

    <div>
      <div class="qty-buttons">
        <ion-button class="qty-button" (click)="setQty('1')">1 </ion-button>
        <ion-button class="qty-button" (click)="setQty('2')">2 </ion-button>
        <ion-button class="qty-button" (click)="setQty('3')">3 </ion-button>
        <ion-button class="qty-button" (click)="setQty('4')">4 </ion-button>
        <ion-button class="qty-button" (click)="setQty('5')">5 </ion-button>
        <ion-button class="qty-button" (click)="setQty('6')">6 </ion-button>
        <ion-button class="qty-button" (click)="setQty('7')">7 </ion-button>
        <ion-button class="qty-button" (click)="setQty('8')">8 </ion-button>
        <ion-button class="qty-button" (click)="setQty('9')">9 </ion-button>
        <ion-button class="qty-button" (click)="setQty('0')">0 </ion-button>
        <ion-button class="qty-button" (click)="setQty('.')">. </ion-button>
        <ion-button class="qty-button" (click)="setQty('del')"
          >{{ "Del" | transloco }}
        </ion-button>
      </div>

      <div class="button-cover">
        <ion-button
          class="action-button"
          id="cancel-button"
          (click)="goBack()"
        >
          {{ "cancel" | transloco }}
        </ion-button>
        <ion-button
          class="action-button"
          id="print-button"
          (click)="printFolio()"
        >
          {{ "print" | transloco }}
        </ion-button>
        <ion-button
          class="action-button"
          id="payment-button"
          (click)="payment()"
        >
          {{ "pay" | transloco }}
        </ion-button>
        <ion-button class="action-button" id="done-button" (click)="done()">
          {{ "done" | transloco }}
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
