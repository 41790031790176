<div [ngClass]="{
    'in-design': (tQ.designMode$ | async),
    'in-design-selected':
      (tQ.designMode$ | async) && (tQ.inDesignTable$ | async)?.id == table.id,
    locked: table.lock.status == 'locked',
    unlocked: table.lock.status !== 'locked'
  }" class="table-btn open ripple-parent ion-activatable" *ngIf="table.state == 'open'" id="tbl-btn-{{ table.id }}"
  (click)="select()">
  <div class="info-cover">
    <div class="table-btn-timer">
      <ion-icon name="timer"></ion-icon>
      <div style="margin-left: 5px; font-size: 10px">
        <div>
          {{ table.info.duration | orwiTime }}
        </div>
      </div>
    </div>
    <div (click)="info($event, table)" class="table-btn-info">
      <ion-label style="font-size: 12px; color: #9de2f3">
        #{{ table.folios[0]?.sequenceNo }}</ion-label>
      <!-- <ion-icon color="secondary" name="information-circle-outline"></ion-icon> -->
    </div>
  </div>
  <!-- <div class="table-btn-timer" *ngIf="table.folios[0].eftPos">
    <ion-icon
      *ngIf="paymentDone()"
      style="color: #0df211"
      name="cash-outline"
    ></ion-icon>
    <ion-icon style="color: #9de2f3" name="calculator-outline"></ion-icon>
  </div> -->
  <div class="pax-cover" *ngIf="table.folios[0]?.type !=='delivery'">
    <ion-icon name="people"></ion-icon>
    <div class="pax">{{ table.info.pax }}</div>
  </div>
  <div class="table-btn-timer" *ngIf="table.folios[0]?.eftPos">
    <ion-icon *ngIf="paymentDone()" style="color:#0df211" name="cash-outline"></ion-icon>
    <ion-icon style="color:#9de2f3" name="calculator-outline"></ion-icon>
</div>
  <div style="position: relative;display: flex;">
    <div class="name">
      {{ table.name }}
    </div>
    <div class="name" style="text-align: right;">
      {{ table.info.amount| orwiCurrency }}
    </div>
  </div>
  <ion-ripple-effect></ion-ripple-effect>
</div>

<div id="tbl-btn-{{ table.id }}" [ngClass]="{
    'in-design-selected':
      (tQ.designMode$ | async) && (tQ.inDesignTable$ | async)?.id === table.id,
    'in-design': (tQ.designMode$ | async)
  }" [ngStyle]="{background: table.color ? table.color : 'rgba(0, 0, 0, 0.6784313725) 0% 0% no-repeat padding-box'}"
  class="table-btn close ripple-parent ion-activatable" *ngIf="table.state === 'close'" (click)="select()">
  <div class="info-cover">
    <div class="table-btn-timer"></div>
    <div class="table-btn-info"></div>
  </div>
  <div>
    <div class="pax-cover">
      <div class="pax"></div>
    </div>
    <div class="name">
      {{ table.name }}
    </div>
    <div class="info">
      <!-- {{table.defaultPax}} Kişilik Masa -->
    </div>
  </div>
  <ion-ripple-effect></ion-ripple-effect>
</div>