const langs = [
  { code: "en", name: "English", icon: "🇬🇧" },
  { code: "tr", name: "Türkçe", icon: "🇹🇷" },
  { code: "de", name: "Deutsch", icon: "🇩🇪" },
  { code: "ar", name: "العربية", icon: "🇸🇦" },
];

module.exports = {
  rootTranslationsPath: "src/assets/i18n/",
  langs: langs.map((lang) => lang.code),
  keysManager: {},
  langDetails: langs,
};
