<div id="modifier-cover" class="modifiers-form">
  <orwi-modal-header
    [closeButton]="false"
    [title]="'Modifiers' | transloco"
  ></orwi-modal-header>

  <!-- <div>{{'Modifiers' | transloco}}</div> -->

  <!-- <div style="height: 400px; overflow: scroll;">

    <div class="modif1 modifiers" *ngFor="let mod_item of folioQuery.activeModifiersGroups$ | async;  index as i">

      <div *ngFor="let item of mod_item.modifierGroup">
        <div
          *ngTemplateOutlet="tplModifier; context: { modifier: item, rowParentId: (folioQuery.activeFolioRow$ | async).id, index : mod_item.order }">
        </div>
      </div>
    </div>

  </div> -->

  <div class="modier-container">
    <div
      class="modif1 modifiers"
      *ngIf="(folioQuery.showModiferGroup$ | async) === 'std'"
    >
      <div *ngFor="let item of (folioQuery.activeFolioRow$ | async).modifiers">
        @defer (on viewport) {
        <div
          *ngTemplateOutlet="
            tplModifier;
            context: {
              modifier: item,
              rowParentId: (folioQuery.activeFolioRow$ | async).id,
              index: 1
            }
          "
        ></div>
        } @placeholder {
        <div style="padding: 5px">
          <ion-skeleton-text
            style="height: 40px; width: 100%"
          ></ion-skeleton-text>
        </div>
        }
      </div>
    </div>

    <div
      class="modif1 modifiers"
      *ngIf="(folioQuery.showModiferGroup$ | async) === 'must'"
    >
      <div
        *ngFor="let item of (folioQuery.activeFolioRow$ | async)?.mustModifiers"
      >
        <div
          *ngTemplateOutlet="
            tplModifier;
            context: {
              modifier: item,
              rowParentId: (folioQuery.activeFolioRow$ | async).id,
              index: 1
            }
          "
        ></div>
      </div>
    </div>
  </div>

  <div class="dialog-footer">
    <ion-button style="width: 100%" (click)="done()">{{
      "Done" | transloco
    }}</ion-button>
  </div>
</div>
<!-- <ion-backdrop  *ngIf="folioQuery.showModifiers$ | async"></ion-backdrop> -->
<div class="backdrop" *ngIf="folioQuery.showModifiers$ | async"></div>
<!-- TEMPLATE: MODIFIERS -->
<ng-template
  #tplModifier
  let-modifier="modifier"
  let-rowParentId="rowParentId"
  let-index="index"
>
  <!-- MODIFIER HEADER -->
  <div
    id="header-{{ modifier.id }}-{{ index }}"
    [ngStyle]="{ top: index === 1 ? '0px' : '30px' }"
    style="position: sticky; top: 0px; z-index: 28"
  >
    <div class="modifier-header">
      <div>
        {{ modifier.group }}
      </div>

      <div style="display: flex; align-items: center">
        <div
          style="font-size: 12px"
          *ngIf="modifier.minSelect === 0 && modifier.maxSelect > 0"
        >
          {{ "Max Select" | transloco }} {{ modifier.maxSelect }}
        </div>

        <div
          style="font-size: 12px"
          *ngIf="
            modifier.minSelect > 0 && modifier.maxSelect === modifier.minSelect
          "
        >
          {{ "Select" | transloco }} {{ modifier.maxSelect }}
          {{ "item" | transloco }}
        </div>
      </div>
    </div>
  </div>

  <div id="collapse-{{ modifier.id }}-{{ index }}" style="display: block">
    <!-- DETACHABLE MODIFIERS -->
    <div *ngIf="modifier.detachable">
      <div
        *ngTemplateOutlet="
          tplDetachableModifier;
          context: {
            modifier: modifier,
            rowParentId: rowParentId,
            index: index
          }
        "
      ></div>
    </div>

    <!-- NORMAL MODIFIERS -->
    <div
      class="modif-tpl"
      [ngStyle]="{
        'max-height': modifier.detachable === false ? '500px' : '0px'
      }"
      *ngIf="modifier.detachable === false"
    >
      <div *ngFor="let modif_products of modifier.products">
        @defer (on viewport) {
        <ion-item>
          <ion-label> {{ modif_products.productName }}</ion-label>
          <ion-note style="margin-top: 17px">{{
            modif_products.price | orwiCurrency
          }}</ion-note>
          <ion-checkbox
            (ionChange)="
              change($event, modif_products, modifier, rowParentId, index)
            "
            [(ngModel)]="modif_products.selected"
            color="primary"
            slot="start"
          >
          </ion-checkbox>
        </ion-item>
        } @placeholder {
        <div style="padding: 5px">
          <ion-skeleton-text
            style="height: 40px; width: 100%"
          ></ion-skeleton-text>
        </div>
        }

        <!-- SUB MODIFIERS (Cycle) -->
        <div style="padding-left: 15px;" *ngFor="let xitem of  modif_products.subModifiers">
          <div
            *ngTemplateOutlet="tplModifier; context: { modifier: xitem, rowParentId: modif_products.temp_rowID, index: index }">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- TEMPLATE: DETACHABLE MODIFIERS -->
<ng-template
  #tplDetachableModifier
  let-modifier="modifier"
  let-rowParentId="rowParentId"
  let-index="index"
>
  <ion-chip
    [color]="mitem.selected ? 'success' : 'warning'"
    *ngFor="let mitem of modifier.products"
    (click)="deattach($event, mitem, modifier, rowParentId, index)"
  >
    <ion-label>
      <span
        [ngStyle]="{
          'text-decoration': mitem.selected ? 'line-through' : 'none'
        }"
      >
        {{ mitem.productName }} - {{ mitem.price | orwiCurrency }}
      </span>
    </ion-label>
  </ion-chip>
</ng-template>

<div
  *ngIf="folioQuery.showModifiers$ | async"
  id="modifier-arrow"
  class="modifier-arrow animate__animated"
>
  <div class="modifier-arrow-text">
    {{ "Complete Selection" | transloco }}
  </div>
  <div class="arrow-down"></div>
</div>
