import { Component, OnInit } from '@angular/core';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { ITable, TableGroup } from 'src/app/services/dto/tables';
import { GlobalService } from 'src/app/services/global.service';
import { SideMenuService } from 'src/app/modules/side-menu/state/side-menu.service';
import { TableQuery } from '../../../state/table.query';
import { TableService } from '../../../state/table.service';
import { TableStore } from '../../../state/table.store';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'orwi-table-combine',
  templateUrl: './table-combine.component.html',
  styleUrls: ['./table-combine.component.scss'],
})
export class TableCombineComponent implements OnInit {
  constructor(
    public tq: TableQuery,
    public glb: GlobalService,
    private ts: TableService,
    private tableStore: TableStore,
    private folioService: FolioService,
    private sideMenuService: SideMenuService,
    private transloco: TranslocoService,
  ) {}

  tableGroupSelected(tableGroup: TableGroup) {
    this.ts.selectTableGroup(tableGroup.id);

    //this.storeService.tableGroups.filter(o => o.id !== tableGroup.id).map(x => x.selected = false)
    //this.oe.activeTableGroup = tableGroup
    //this.oe.tableGroupEvents.next("select")
  }
  async tableSelected(tb: ITable) {
    if (!this.tableStore.getValue().tables.some((o) => o.selected)) {
      tb.selected = true;
      this.tableStore.update({ selectedTable: tb });
      this.glb.toast(
        `Lütfen ${tb.name} masasını birleştireceğiniz masayı seçiniz`,
        '',
        'bottom',
        'success'
      );
    } else {
      const combinedTable: ITable = this.tableStore.getValue().selectedTable;
      const combinedFolio: Folio = combinedTable?.folios[0];

      const infoModal = await this.glb.openAlert({
        backdropDismiss: true,
        cssClass: 'question-dialog',
        header: this.transloco.translate('Table Combine'),
        message: this.transloco.translate('tableCombineMessage', {
          movedTable: combinedTable.name,
          tb: tb.name,
        }),
        buttons: [
          this.transloco.translate('Hayır'),
          {
            text: this.transloco.translate('Yes'),
            handler: () => {},
            role: 'evet',
          },
        ],
      });
      infoModal.onDidDismiss().then(async ({ role }) => {
        // console.log(data);
        if (role == 'evet') {
          await this.folioService.combineFolio(tb, combinedFolio, tb.folios[0]);
          this.tableStore
            .getValue()
            .tables.forEach((el) => (el.selected = false));
          this.glb.toast('Başarıyla Güncellendi', '', 'bottom', 'success');
          this.sideMenuService.selectMenuById('open-folios');
        }
      });
    }
    return;
  }
  ngOnInit() {}

  checkTableGroupChange(tableGroup: TableGroup) {
    return tableGroup.info.open <= 0;
  }
  checkTableChange(table: ITable) {
    return table.state === 'close' || table.selected;
  }
}
