import { Injectable } from '@angular/core';
import { arrayUpdate } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import { NumpadQuery } from 'src/app/components/ui/numpad/state/numpad.query';
import { NumpadService } from 'src/app/components/ui/numpad/state/numpad.service';
import { FolioService as FolioService } from 'src/app/modules/folio/state/folio.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { ModifiersService } from 'src/app/modules/menu/modifiers/state/modifiers.service';
import { DeliveryStore } from 'src/app/modules/delivery/state/delivery.store';
import { GlobalService } from 'src/app/services/global.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { SideMenuQuery } from './side-menu.query';
import { SideMenu, sideMenuId, SideMenuStore } from './side-menu.store';
import { EftPosService } from 'src/app/services/ecr/eft-post.service';
import { SSOSessionService } from '../../session/state/sso-session.service';
import * as moment from 'moment';
import { PrintCoverService } from 'src/app/services/printer/print-cover.service';


@Injectable({ providedIn: 'root' })
export class SideMenuService {
  constructor(
    private folioStore: FolioStore,
    private folioService: FolioService,
    numpadQuery: NumpadQuery,
    private numpadService: NumpadService,
    private modifiersService: ModifiersService,
    private sideMenuQuery: SideMenuQuery,
    private deliveryStore: DeliveryStore,
    private translocoService: TranslocoService,
    private modalService: ModalService,
    private sideMenuStore: SideMenuStore,
    private glb: GlobalService,
    private eftPosService :EftPosService,
    private ssoService: SSOSessionService,
    private printerService: PrintCoverService
  ) {
    this.initFolioMenu();
    numpadQuery.value$('qty').subscribe((o) => {
      this.updateQty(o);
    });
    numpadQuery.value$('pax').subscribe((o) => {
      this.updatePax(o);
    });
  }
  initFolioMenu() {
    let folioMenu: SideMenu[] = [];

    folioMenu.push({
      menuType: 'folio',
      id: 'gift',
      selected: false,
      route: '',
      title: 'Gift',
      icon: 'local_cafe',
    });
    folioMenu.push({
      menuType: 'folio',
      id: 'delete',
      selectable: false,
      selected: false,
      route: '',
      title: 'Delete',
      icon: 'delete',
    });
    folioMenu.push({
      menuType: 'folio',
      id: 'note',
      selected: false,
      route: '',
      title: 'Note',
      icon: 'description',
    });
    folioMenu.push({
      menuType: 'folio',
      id: 'loyalty',
      selected: false,
      route: '',
      title: 'Loyalty',
      icon: 'loyalty',
    });
    folioMenu.push({
      menuType: 'folio',
      id: 'sendEft',
      selected: false,
      route: '',
      title: 'Send EFT',
      icon: 'loyalty',
    });
    folioMenu.push({
      menuType: 'folio',
      id: 'transfer',
      selected: false,
      route: '',
      title: 'Transfer',
      icon: 'compare_arrows',
    });
    folioMenu.push({
      menuType: 'folio',
      id: 'modifier',
      selected: false,
      route: '',
      title: 'Modifier',
      icon: 'tune',
    });
    folioMenu.push({
      menuType: 'folio',
      id: 'price',
      selected: false,
      route: '',
      title: 'Price',
      icon: 'tune',
    });
    folioMenu.push({
      menuType: 'folio',
      id: 'qty',
      selected: false,
      route: '',
      title: 'Qty',
      icon: 'keyboard',
      badge: '0',
    });
    folioMenu.push({
      menuType: 'folio',
      id: 'pax',
      selected: false,
      route: '',
      title: 'Pax',
      icon: 'pax',
    });
    folioMenu.map((el) => ({
      ...el,
      title: this.translocoService.translate(el.title),
    }));
    this.sideMenuStore.update({ folioMenu: folioMenu });
  }

  async selectMenuItem(item: SideMenu) {
    let currentDate = moment(new Date)
    if (item.menuType == 'pos') {
      this.sideMenuStore.update({ selectedPosMenu: item });
    } else if (item.menuType == 'folio') {
      this.sideMenuStore.update({ selectedFolioMenu: item });
    }

    if (item.id == 'delete' && this.folioStore.getValue().selectedRow) {
      if (
        !this.ssoService.checkPermission('folio-delete-product') ||
        (
            !this.ssoService.checkPermission('folio-delete-product-10m') &&
            currentDate.diff(moment(this.folioStore.getValue().selectedRow.creation), 'minutes') > 10
        ) ||
        (
            !this.ssoService.checkPermission('folio-delete-old-product') &&
            this.folioStore.getValue().selectedRow.recordStatus === 'old'
        )
    ) {
        this.glb.permissionToast();
        return;
    }
      this.folioService.askDeleteSelectedRows();
    }

    if (item.id == 'delivery') {
      this.deliveryStore.update({ deliveryFormVisible: true });
    }

    if (item.id == 'note') {
      this.folioService.addNote();
    }

    if (item.id == 'qty') {
      if(!this.ssoService.checkPermission('folio-change-product-count')){
        this.glb.permissionToast()
        return
      }
      if (this.folioStore.getValue().selectedRow) {
        this.numpadService.open(
          'qty',
          this.folioStore.getValue().selectedRow?.qty
        );
      } else {
        this.numpadService.open('qty');
      }
    }
    if (item.id == 'price') {
      if(!this.ssoService.checkPermission('folio-change-product-price')){
        this.glb.permissionToast()
        return
      }
      if (this.folioStore.getValue().selectedRow) {
        this.numpadService.open(
          'price',
          this.folioStore.getValue().selectedRow?.unitPrice
        );
      }
    }
    if (item.id == 'pax') {
      this.folioService.setPax();
    }
    if (item.id == 'transfer') {
      if(!this.ssoService.checkPermission('folio-transfer-product')){
        this.glb.permissionToast()
        return
      }
      this.modalService.showTransferProductModal();
    }

    if (item.id == 'gift') {
      if (
        !this.ssoService.checkPermission('folio-gift-product') ||
        (
            !this.ssoService.checkPermission('folio-gift-old-product') &&
            this.folioStore.getValue().selectedRow.recordStatus === 'old'
        )
    ) {
        this.glb.permissionToast();
        return;
    }


      this.folioService.askGiftSelectedRows();
    }

    if (item.id == 'modifier') {
      if (this.folioStore.getValue().selectedRow.recordStatus == 'new') {
        this.modifiersService.modifiers(this.modifiersService.hasModifiers(this.folioStore.getValue().selectedRow.itemID));
      } else {
        this.glb.toast('', 'Gönderilmiş Ürüne Varyasyon Giremezsiniz.', 'bottom', 'warning')
      }

    }

    if (item.id == 'self-service') {
      this.sideMenuStore.update({ servicetType: 'self' });
    } else if (item.id == 'table-service') {
      this.sideMenuStore.update({ servicetType: 'table' });
    } else if (item.id == 'delivery') {
      this.sideMenuStore.update({ servicetType: 'delivery' });
    } else if (item.id == 'take-away') {
      this.sideMenuStore.update({ servicetType: 'take-away' });
    }
    if (item.id == 'eft')
    {
      this.eftPosService.openEftPosList()
    }

    if (item.id == 'sendEft')
    {
      if(!this.ssoService.checkPermission('folio-eft-send')){
        this.glb.permissionToast()
        return
      }
      if (this.folioStore.getValue().activeFolio.rows.length == 0)
      {
        this.glb.toast('','Eft Pos a Gönderilecek Ürün Bulunamadı.','bottom','warning')
        return
      }
      else
      {
        this.printerService.printUnit(this.folioStore.getValue().activeFolio)
        this.eftPosService.sendFolioToEftPos(this.folioStore.getValue().activeFolio,true)
      }

    }
  }

  selectMenuById(id: sideMenuId) {
    const item: SideMenu = this.sideMenuQuery
      .getValue()
      .posMenu.find((el) => el.id === id);
    if (item) {
      this.selectMenuItem(item);
    } else {
      console.error(id + ' id ye ait menü bulunamadı.');
    }
  }

  updateQty(value) {
    let ids = [];
    let rows = this.sideMenuStore
      .getValue()
      .folioMenu.filter((menuItem) => menuItem.id == 'qty');

    rows.map((o) => (o.badge = value));
    for (const iterator of rows) {
      ids.push(iterator.id);
    }

    this.sideMenuStore.update(({ folioMenu }) => ({
      folioMenu: arrayUpdate(folioMenu, ids, rows, 'id'),
    }));
  }

  updatePax(value = '0') {
    const pax = this.sideMenuStore
      .getValue()
      .folioMenu.find((menuItem) => menuItem.id == 'pax');
    // this.folioStore.getValue().activeFolio.pax = 5;
    this.folioStore.update(({ activeFolio }) => ({
      activeFolio: { ...activeFolio, pax: +value },
    }));
    this.sideMenuStore.update(({ folioMenu }) => ({
      folioMenu: arrayUpdate(folioMenu, [pax.id], [pax], 'id'),
    }));
  }




}
