import { Injectable } from '@angular/core';
import { HuginProtocol } from 'libs/hugin-pay/src';
import { EcrBaseService } from './ecr.base.service';
import { Folio, rowType } from '../dto/orwi-folio';
import { GlobalService } from '../global.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import * as moment from 'moment';
import { IdGeneratorService } from '../helpers/id-generator.service';
import { TranslocoService } from '@ngneat/transloco';
@Injectable({
  providedIn: 'root',
})
export class EcrHuginService implements EcrBaseService {
  socketId: string;
  ipAddress: string;
  port: number;
  startIndex = 1;

  constructor(
    private glb: GlobalService,
    private folioStore: FolioStore,
    private idGen: IdGeneratorService,
    private transloco: TranslocoService
  ) { }

  connect() {
    this.glb.showLoading('Ecr Hugin Service Loading');
    return HuginProtocol.sendTerminalInfo({ resultType: 'string' }).then(
      (response) => {
        setTimeout(() => {
          this.glb.closeLoading();
        }, 5000);
        console.log('HuginProtocol.Connect', JSON.stringify(response));
        if (response.errorCode != 0 || response.ResponseCode != 0) {
          this.glb.toast(
            'Orwi Ecr Service',
            "Could not connect to cash register. Please check the settings.",
            'bottom',
            'danger'
          );
          return false;
        } else {
          this.glb.toast(
            'Orwi Ecr Service',
            'Cash register connection process is completed.',
            'bottom',
            'success'
          );
          return response;
        }
      },
      (err) => {
        setTimeout(() => {
          this.glb.closeLoading();
          let errorMessage = this.transloco.translate("Could not connect to cash register. Please check the settings.");
          this.glb.toast('', errorMessage, 'bottom', 'danger');
        }, 5000);
        this.glb.toast(
          'Orwi Ecr Service',
          err,
          'bottom',
          'danger'
        );
        return false;
      }
    );
  }

  init(ipAddress: string, port: number): EcrBaseService {
    this.ipAddress = ipAddress;
    this.port = port;
    return this;
  }

  sale(folio: Folio, grandTotal?: any) {
    if (grandTotal == 0) {
      this.printEInvoice(folio);
      return Promise.resolve(true);
    }
    this.glb.showLoading();
    return HuginProtocol.sendSaleRequest({
      paymentRequest: `{"amount": '${grandTotal}'}`,
    }).then(
      (response) => {
        setTimeout(async () => {
          this.glb.closeAllLoading();
          await this.glb.closeLoading();
        }, 10 * 1000);
        console.log('response', response);
        if (response.errorCode != 0) {
          if (response.errorCode == 80) {
            this.glb.toast(
              'Orwi Ecr Service',
              'Payment Canceled.',
              'bottom',
              'warning'
            );
          }
          else {
            this.glb.toast(
              'Orwi Ecr Service',
              'There was a problem sending it to the cash register.',
              'bottom',
              'danger'
            );
          }

          return false;
        } else {
          this.glb.toast(
            'Orwi Ecr Service',
            'Bill Sent to Cash Register.',
            'bottom',
            'success'
          );
          this.printEInvoice(folio);
          return true;
        }
      },
      async (err) => {
        this.glb.toast(
          'Orwi Ecr Service',
          err,
          'bottom',
          'danger'
        );
        setTimeout(async () => {
          this.glb.closeAllLoading();
          await this.glb.closeLoading();
        }, 10 * 1000);
        return false;
      }
    );
  }

  async printEInvoice(
    folio: Folio = this.folioStore.getValue().openFolios?.[0]
  ) {
    let formatted = this.formatInvoice(folio);
    this.glb.showLoading('Yazdırılıyor...');
    return HuginProtocol.sendPrintRequest({
      printRequest: JSON.stringify(formatted),
    }).then(
      (res) => {
        res//reserved
        this.glb.closeAllLoading();
        this.glb.toast("Successful", '', 'middle', 'success');
      },
      (err) => {
        console.log(JSON.stringify(err));
        this.glb.closeAllLoading();
      }
    );
  }
  formatInvoice(folio: Folio) {
    let formatted = [...this.INVOICE_TEMPLATE];
    let formattedRows = folio.rows
      // .filter((elm) => elm.rowType == 'product' || elm.rowType == 'modifier')
      .filter((el) => el.unitPrice !== 0)
      .map(
        (el) =>
        ({
          type: 'TEXT',
          rowType: el.rowType,
          value:
            el.name +
            ' '.repeat(
              32 - (el.name.length + (Math.abs(el.unitPrice)).toFixed(2).length + 2)
            ) +
            (Math.abs(el.unitPrice)).toFixed(2) +
            'TL',
          attr: {
            align: 'left',
            font: 'normal',
            lineFeed: true,
            style: 'normal',
          },
        } as HuginEInvoice)
      );

    let products = formattedRows.filter(
      (elm) => elm.rowType == 'product' || elm.rowType == 'modifier'
    );

    let payments = formattedRows.filter((el) => el.rowType == 'payment');
    let productStartIndex = formatted.findIndex(
      (el) => el.id == 'productStart'
    );

    if (productStartIndex >= 0) {
      formatted.splice(productStartIndex + 1, 0, ...products);
    }

    let paymentStartIndex = formatted.findIndex(
      (el) => el.id == 'paymentStart'
    );

    if (paymentStartIndex >= 0) {
      formatted.splice(paymentStartIndex + 1, 0, ...payments);
    }

    let grandTotalRow = formatted.find((el) => el.id == 'grandTotal');
    if (grandTotalRow) {
      grandTotalRow.value = folio.grandTotal + 'TL';
    }

    let ettnField = formatted.find((el) => el.id == 'ettnField');

    if (ettnField) {
      ettnField.value = 'ETTN:' + this.idGen.generateUuid().toString().toUpperCase();
    }

    let faturaField = formatted.find((el) => el.id == 'faturaField');

    if (faturaField) {
      faturaField.value =
        'F.NO: ORW' +
        moment().format('YYYY') +
        ('000000000' + this.startIndex++).slice(-9) +
        '';
    }

    console.log('formatted Hugin', JSON.stringify(formatted));

    return formatted;
  }

  printXReport = () => Promise.resolve(true);
  printZReport = () => Promise.resolve(true);
  syncDepartments = () => Promise.resolve(true);

  INVOICE_TEMPLATE: HuginEInvoice[] = [
    {
      attr: {
        align: 'center',
        height: 150,
        width: 150,
        offset: 0,
      },
      type: 'IMAGE',
      value:
        '/storage/emulated/0/Android/data/hugin.droid.techpos/files/earsiv.png',
      // value: '/storage/emulated/0/Android/data/earsiv.png',
    },
    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
        offset: 1,
      },
      type: 'TEXT',
      value: '     E-ARŞİV',
    },
    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: 'Big Chefs',
    },
    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'Şenlikköy Mahallesi YeşilKöy Halkalı Cad.',
    },
    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'No:93 Florya - İstanbul',
    },
    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'İSTANBUL',
    },
    {
      type: 'PAPERSKIP',
      value: '1',
    },
    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'Test Bankası',
    },
    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value:
        'Tarih: ' +
        moment().format('DD/MM/YYYY') +
        ' '.repeat(32 - 22) +
        moment().format('HH:mm'),
    },
    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'Sayın: Muhtelif Müşteri',
    },
    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'VKN/TC: 11111111110',
    },
    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'Adres: AYAZAĞ MAH AZERBAYCAN CAD',
    },
    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'İL/İLÇE: İSTANBUL / SARIYER',
    },
    {
      attr: {
        align: 'left',
        font: 'small',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: 'ETTN:',
      id: 'ettnField',
    },
    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: 'F.NO:',
      id: 'faturaField',
    },
    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: '__'.repeat(16),
    },
    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: 'E-ARŞİV FATURA',
    },
    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: '__'.repeat(16),
      id: 'productStart',
    },

    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: '__'.repeat(16),
    },

    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: 'SATIŞ',
      id: 'paymentStart',
    },

    // {
    //   attr: {
    //     align: 'left',
    //     font: 'normal',
    //     lineFeed: true,
    //     style: 'normal',
    //   },
    //   type: 'TEXT',
    //   value:
    //     'Kredi Kartı' + ' '.repeat(48 - ('Kredi Kartı'.length + 4)) + '90TL',
    // },

    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: 'İŞLEM TUTARI',
    },

    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: '',
      id: 'grandTotal',
    },

    {
      attr: {
        align: 'left',
        font: 'small',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'AID: A0000000031010 REF: 04262F68EF22F93E',
    },

    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'ACQUIRER ID:0001',
    },

    {
      attr: {
        align: 'center',
        font: 'small',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'TUTAR KARŞILIĞINDA MAL VEYA HİZMET ALDIM',
    },

    {
      type: 'PAPERSKIP',
      value: '2',
    },

    {
      attr: {
        align: 'center',
        font: 'small',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'İRSALİYE YERİNE GEÇER E-ARŞİV FATURA İZNİ',
    },

    {
      attr: {
        align: 'center',
        font: 'small',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'KAPSAMINDA ELEKTRONİK OLARAK OLUŞTURULMUŞTUR.',
    },

    {
      attr: {
        align: 'center',
        font: 'small',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'E-FATURAYA ULAŞMAK İÇİN',
    },

    {
      attr: {
        align: 'center',
        font: 'small',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'HTTPS://EBELGE.GİB.GOV.TR/EARSİVSORGULA.HTML',
    },

    {
      attr: {
        align: 'center',
        font: 'small',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'ADRESİNİ KULLANABİLİRSİNİZ.',
    },

    {
      type: 'PAPERSKIP',
      value: '2',
    },

    {
      attr: {
        align: 'left',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: '__'.repeat(16),
    },

    {
      attr: {
        align: 'center',
        font: 'normal',
        lineFeed: true,
        style: 'bold',
      },
      type: 'TEXT',
      value: 'İMZA',
    },

    {
      attr: {
        align: 'center',
        font: 'small',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'İŞYERİ NÜSHASI',
    },

    {
      attr: {
        align: 'center',
        font: 'small',
        lineFeed: true,
        style: 'normal',
      },
      type: 'TEXT',
      value: 'BU BELGEYİ SAKLAYINIZ',
    },

    {
      type: 'PAPERSKIP',
      value: '6',
    },
  ];
}
/**
 * @constant value değeri font small ise 48 , normal ise 32 karakter alır
 */
class HuginEInvoice {
  attr?: Partial<Attribute>;
  type: 'TEXT' | 'IMAGE' | 'PAPERSKIP';
  id?:
    | 'productStart'
    | 'paymentStart'
    | 'grandTotal'
    | 'ettnField'
    | 'faturaField';
  value: string;

  rowType?: rowType; // Folio özelinde var. huginde yok
}

class Attribute {
  align: 'center' | 'left' | 'right';
  font: 'small' | 'normal';
  lineFeed: boolean; // Görünmesi için true
  style: 'bold' | 'normal';
  height: number;
  width: number;
  offset: number;
}
