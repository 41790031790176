<ion-content *ngIf="activeFolio">
  <!-- <div>
    <ion-icon
      icon="close-outline"
      (click)="globalService.closeModal()"
    ></ion-icon>
  </div> -->
  <div *transloco="let t"
    style="display: flex; flex-direction: column; width: 100dvw; height: 100dvh"
  >
    <div style="text-align: center" #tableName>
      <h3>{{ activeFolio.table.name | uppercase }}</h3>
    </div>
    <div
      class="product-table-container"
      style="flex: 1; overflow: scroll"
      [ngStyle]="{ 'max-height': calculateMaxHeight(divFooter, tableName) }"
    >
      <dx-data-grid
        [dataSource]="productRows"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [rowAlternationEnabled]="true"
        [paging]="{ enabled: false }"
      >
        <dxi-column
          [caption]="t('Product Name')"
          cellTemplate="productTemplate"
        ></dxi-column>
        <dxi-column
          dataField="qty"
          [caption]="t('Quantity')"
          cellTemplate="qtyCellTemplate"
        ></dxi-column>
        <dxi-column
          dataField="unitPrice"
          cellTemplate="unitPriceTemplate"
          [caption]="t('Unit Price')"
          [format]="{ type: 'currency', currency: 'TRY' }"
        ></dxi-column>
        <dxi-column
          dataField="price"
          cellTemplate="priceTemplate"
          [caption]="t('Price')"
          [format]="{ type: 'currency', currency: 'TRY' }"
        ></dxi-column>
        <!-- <dxi-column dataField="currencyTotal" caption="Döviz Tutarı" [format]="{ type: 'currency', currency: 'TRY' }"></dxi-column> -->

        <div *dxTemplate="let data of 'productTemplate'">
          <div>
            <div>
              <ion-icon
                *ngIf="data.data.isGift"
                name="gift-outline"
                style="margin-right: 5px; color: lime; font-size: 18px"
              ></ion-icon>
              {{ data.data.name }}
            </div>

            <div
              *ngFor="let modif of data.data.virtualModifiers"
              style="font-size: 12px; color: gray"
            >
              <div
                *ngIf="
                  modif.rowType == 'modifier' ||
                  modif.rowType == 'customModifiers'
                "
              >
                <ion-icon
                  [ngStyle]="{ color: modif.isMustModifier ? 'red' : 'unset' }"
                  name="return-down-forward-outline"
                >
                </ion-icon>
                {{ modif.name }}
              </div>
              <div *ngIf="modif.rowType == 'note'">
                <ion-icon
                  style="color: rgb(28, 167, 1); font-weight: 600"
                  name="document-text-outline"
                ></ion-icon>
                {{ modif.name }}
              </div>
            </div>
          </div>
        </div>
        <div *dxTemplate="let data of 'qtyCellTemplate'">
          <div>
            <div>{{ data.data.qty }}</div>

            <div
              *ngFor="let modif of data.data.virtualModifiers"
              style="font-size: 12px; color: gray"
            >
              {{ modif.qty }}
            </div>
          </div>
        </div>

        <div *dxTemplate="let data of 'priceTemplate'">
          <div>
            <div>
              {{ (data.data.isGift ? 0 : data.data.price) | orwiCurrency }}
            </div>

            <div
              *ngFor="let modif of data.data.virtualModifiers"
              style="font-size: 12px; color: gray"
            >
              {{ modif.price | orwiCurrency }}
            </div>
          </div>
        </div>

        <div *dxTemplate="let data of 'unitPriceTemplate'">
          <div>
            <div>{{ data.data.unitPrice | orwiCurrency }}</div>

            <div
              *ngFor="let modif of data.data.virtualModifiers"
              style="font-size: 12px; color: gray"
            >
              {{ modif.unitPrice | orwiCurrency }}
            </div>
          </div>
        </div>
      </dx-data-grid>
    </div>

    <div class="footer" #divFooter>
      <div class="quantity-container">
        <div class="quantity-header">
          <span>{{'Total' | transloco}}</span>
          <span class="total-amount">
            {{ activeFolio | orwiFoliosTotalPrice | orwiCurrency }}
          </span>
        </div>
        <div class="payment-row-item" *ngFor="let paymentRow of paymentRows">
          <span>{{ paymentRow.name | titlecase }}</span>
          <span class="total-amount">
            {{ paymentRow.price | orwiCurrency }}
          </span>
        </div>
        <div class="quantity-header" *ngIf="paymentRows.length > 0">
          <span>{{'Remainder' | transloco}}</span>
          <span class="total-amount">
            {{ activeFolio | orwiFoliosTotalPrice: paymentRows | orwiCurrency }}
          </span>
        </div>
        <div class="currencies">
          <!-- <p>Dolar <span>₺ 0.00</span></p>
          <p>Sterlin <span>₺ 0.00</span></p>
          <p>Euro <span>₺ 0.00</span></p> -->
        </div>
      </div>

      <div class="exchange-container">
        <div class="pos-title">
          <ion-icon src="/assets/icon/cloud.svg"></ion-icon>
          <span>{{ "Orwi POS" | transloco }}</span>
        </div>
        <div class="exchange-group">
          <!-- <div class="currency flex items-center mr-4">
                <img src="assets/images/dolar.svg"/>
                <p class="text-xs">1$ (USD) = 35.00₺</p>
              </div>
              <div class="currency flex items-center mr-4">
                <img src="assets/images/euro.svg"/>
                <p>1€ (EUR) = 38.00₺</p>
              </div>
              <div class="currency flex items-center mr-4">
                <img src="assets/images/sterlin.svg"/>
                <p>1£ (GBP) = 48.00₺</p>
              </div> -->
        </div>
        <img width="200" [src]="activeFolio.brand" alt="" />
      </div>
    </div>
  </div>
</ion-content>
