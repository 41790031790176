import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IonCheckbox, ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { FoodAppsIntegrationQuery } from 'src/app/modules/food-apps-integration/state/food-apps-integration.query';
import {
  DeliveryStatus,
  Folio,
  FolioRow,
  ServiceType,
} from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { CountOfSelectedRowsPipe } from '../count-of-selected-rows.pipe';
import { KitchenDisplayLog, OrderStatus, OrderStatusArray } from '../state/dto';
import { KitchenDisplayQuery } from '../state/kitchen-display.query';
import { KitchenDisplayService } from '../state/kitchen-display.service';
import { folioLogService } from 'src/app/modules/folio/state/folio-logs/folio-logs-service';
import { PrintCoverService } from 'src/app/services/printer/print-cover.service';
import { FoodAppsIntegrationService } from 'src/app/modules/food-apps-integration/state/food-apps-integration.service';
import { arrayUpsert } from '@datorama/akita';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { Device } from '@capacitor/device';
import { ReportsStore } from 'src/app/modules/reports/state/reports.store';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { FormBuilder } from '@angular/forms';
import { SessionQuery } from 'src/app/modules/session/state/session.query';

@Component({
  selector: 'kitchen-item',
  templateUrl: './kitchen-item.component.html',
  styleUrls: ['./kitchen-item.component.scss'],
})
export class KitchenItemComponent implements OnInit {
  @ViewChild('popover') popover;
  popoverToggle = false
  folioSettings = this.fb.group({
    orderNumber: true,
    waiter: true,
    table: true,
    warningColor: '#f4f5f8',
    warningTime: 15,
  });

  presentPopover(e: Event) {
    this.popover.event = e;
    this.popoverToggle = true;
  }
  openNote() {
    this.glb.openAlert({
      header: this.item.table.name,
      message: this.item.note,
      buttons: [
        {
          text: this.transloco.translate('Close'),
          role: 'destructive',
        },
      ],
    });
  }
  @Input('settings')
  set settings(item: any) {
    this.folioSettings.patchValue({
      orderNumber: item.orderNumber,
      waiter: item.waiter,
      table: item.table,
      warningColor: item.warningColor,
      warningTime: item.warningTime,
    });
  }

  @Input('kitchenItem')
  set setItem(item: any) {
    this.item = item;
    this.formatRows();
  }

  @Input()
  set kitchenLogs(e) {
    this.kitchenDisplayLogs = e;
    // this.formatRows();
  }

  @Output()
  onUpdateKitchenLogs = new EventEmitter<any>();

  @Output()
  handler = new EventEmitter<any>();

  constructor(
    private kitchenDisplayService: KitchenDisplayService,
    public kitchenDisplayQuery: KitchenDisplayQuery,
    private folioLogService: folioLogService,
    public foodAppQuery: FoodAppsIntegrationQuery,
    private countOfSelectionPipe: CountOfSelectedRowsPipe,
    private glb: GlobalService,
    private fs: FolioStore,
    private foodAppService: FoodAppsIntegrationService,
    private cdRef: ChangeDetectorRef,
    private transloco: TranslocoService,
    private printCoverS: PrintCoverService,
    private reportStore: ReportsStore,
    private modalController: ModalController,
    private fb: FormBuilder,
    public sessionQuery: SessionQuery
  ) {}
  ngOnInit(): void {}
  moment: any = moment;

  selectedProductsType: 'start' | 'Complete' = 'start';
  formattedRows = [
    {
      name: OrderStatus.waiting,
      rows: [],
    },
    {
      name: OrderStatus.prepering,
      rows: [],
    },
    {
      name: OrderStatus.prepered,
      rows: [],
    },
    {
      name: OrderStatus.cancelled,
      rows: [],
    },
  ];

  formatRows() {
    this.getSettings();
    this.kitchenDisplayQuery.mainRows(this.item.id).map((it) => {
      it.customStatus = this.getProductStatus(this.item.id, it.itemID);
      let kitchenRow = this.kitchenDisplayLogs.find(
        (x) => x.folioId == this.item.id && x.itemId == it.itemID
      );
      it.customCreated = kitchenRow?.creationDate;
      if (
        it.customStatus != OrderStatus.prepered &&
        it.customStatus != OrderStatus.cancelled
      )
        it.customChecked = true;
      return it;
    });
    this.formattedRows.map((elm) => {
      let foundedRows = this.kitchenDisplayQuery
        .mainRows(this.item.id)
        .map((el) => ({
          ...el,
          customChecked:
            el.customStatus != OrderStatus.prepered &&
            el.customStatus != OrderStatus.cancelled,
        }))
        .filter((row) => row.customStatus == elm.name)
        .filter((x) => this.kitchenDisplayService.kitchenDisplaySettings.products.includes(x.itemID));

      elm.rows = foundedRows;
      return elm;
    });

    if (
      this.countOfSelectionPipe
        .transform(this.item)
        .some((row) => row.customStatus == OrderStatus.prepering)
    ) {
      this.selectedProductsType = 'Complete';
      return;
    }
    this.cdRef.detectChanges();
  }

  getSettings() {
    Device.getId().then(async x => {
      this.kitchenDisplayService.getSettings(x.identifier).subscribe(x => {
        x.length > 0 ? this.kitchenDisplayService.kitchenDisplaySettings = x[0] : undefined;
      })
    })
  }

  orderStatusStyle(item) {
    if (this.calcOrderTime(item) < this.folioSettings.value.warningTime) {
      return '#f4f5f8';
    } else if (this.calcOrderTime(item) > this.folioSettings.value.warningTime) {
      return this.folioSettings.value.warningColor;
    } 
    // else if (this.calcOrderTime(item) > 30) {
    //   return 'danger';
    // } 
    else {
      return '';
    }
  }

  item: Folio;
  kitchenDisplayLogs: KitchenDisplayLog[] = [];

  calcOrderTime(item: Folio) {
    return this.calcDiffTime(item.creation);
  }

  calcDiffTime(item: Date) {
    return moment(new Date()).diff(item, 'minute');
  }

  getOrderTypeImage(orderType: ServiceType) {
    switch (orderType) {
      case 'delivery':
        return '/assets/images/delivery.png';

      case 'self':
        return '/assets/images/coffee-bag.png';

      case 'table':
        return '/assets/images/table.png';

      default:
        return '/assets/images/table.png';
    }
  }

  checkItem(event: IonCheckbox, item: FolioRow, folio: Folio) {
    item.customChecked = event.checked;
    let rowItem = folio.rows.findIndex((row) => row.id === item.id);
    if (rowItem >= 0) folio.rows[rowItem].customChecked = event.checked;
    let selectedRows = this.countOfSelectionPipe.transform(folio);
    if (selectedRows.length > 0) {
      if (
        selectedRows.some((row) => row.customStatus == OrderStatus.prepering)
      ) {
        this.selectedProductsType = 'Complete';
        return;
      }
    }

    if (item.customChecked && selectedRows.length == 0) {
      this.glb.toast(
        '',
        "Different order statuses are available.",
        'middle',
        'warning'
      );
    }

    this.selectedProductsType = 'start';
  }

  print() {
    this.printCoverS.printFolio(this.item);
  }

  getItemNextStatus(itemStatus: OrderStatus) {
    if (
      itemStatus == OrderStatus.cancelled ||
      itemStatus == OrderStatus.prepered
    )
      return itemStatus;
    return OrderStatusArray[
      OrderStatusArray.findIndex((el) => el == itemStatus) + 1
    ];
  }

  async cancelOrder(folio: Folio) {
    this.foodAppService.cancelFolio(folio);
  }

  checkWaiting(folio: Folio) {
    let status = [];
    folio.rows.map((elm) =>
      status.push(this.getProductStatus(folio.id, elm.itemID))
    );
    status = [...new Set(status)];
    if (status.length === 1 && status[0] === 'waiting') {
      return true;
    } else {
      return false;
    }
  }

  async startOrder(folio: Folio) {
    let folios: string[] = [folio.id];

    if (
      (await (
        await this.folioLogService.getFolioLogs(folios, 'preparing-start')
      ).length) == 0
    ) {
      await this.startOrderLog(folio.id);
    }
    let rows: FolioRow[] = this.countOfSelectionPipe.transform(folio);
    this.glb.showLoading();
    Promise.all([
      ...rows.map((row) => {
        let rowStatus = this.getProductStatus(folio.id, row.itemID);
        let kitchenRow = this.kitchenDisplayLogs.find((x) => {
          return x.folioId == folio.id && x.itemId == row.itemID;
        });
        let data = {
          itemId: row.itemID,
          folioId: folio.id,
          id: kitchenRow?.id ?? '',
          status: this.getItemNextStatus(rowStatus ?? OrderStatus.waiting),
        };
        this.kitchenDisplayService.upsertKitchenDisplayLog(
          data as KitchenDisplayLog
        );
        return data;
      }),
    ]).then(
      async (res) => {
        this.onUpdateKitchenLogs.emit(true);
        let allPrepered = false;
        let kitchenRows = [];

        kitchenRows = this.kitchenDisplayLogs.filter(
          (x) => x.folioId == folio.id
        );
        if (kitchenRows.length == res.length) {
          let isPrepering = res.filter((x) => x.status == 'prepering');
          isPrepering.length > 0 ? (allPrepered = false) : (allPrepered = true);
          allPrepered == true ? await this.endOrderLog(folio) : null;
        } else {
          console.log('NOT ALL DONE');
        }
        //this.kitchenDisplayService.fetchData(new Date());
        this.handler.emit(true);
        this.selectedProductsType == 'start'
          ? (this.selectedProductsType = 'Complete')
          : null;
        this.glb.closeLoading();
      },
      (err) => {
        console.log(err);
        this.glb.closeLoading();
      }
    );
  }

  async startOrderLog(folioId: string) {
    await this.folioLogService.insertFolioLog({
      folioId: folioId,
      logType: 'preparing-start',
      data: {},
    });
  }

  async endOrderLog(folio: Folio) {
    await this.folioLogService.insertFolioLog({
      folioId: folio.id,
      logType: 'preparing-end',
      data: {},
    });
    if(folio.source === 'food-app')
    {
      await this.updateOrderStatus(folio.id, 'prepared');
    }
   
  }

  updateOrderStatus(folioId, status: DeliveryStatus) {
    this.foodAppService.updateOrderStatus(folioId, status).then(
      (res) => {
        console.log('Response from FoodApp', res);
        this.item.deliveryStatus = status;
        this.fs.update(({ openFolios }) => ({
          openFolios: arrayUpsert(openFolios, folioId, this.item),
        }));
        this.glb.toast('', 'Action Successful', 'middle', 'success');
      },
      (err) => {
        this.glb.toast('An error occured', err, 'middle', 'danger');
      }
    );
  }

  getProductStatus(folioId: string, itemId: string) {
    let kdlog = this.kitchenDisplayLogs.find(
      (x) => x.folioId == folioId && x.itemId == itemId
    );
    if (!kdlog) return OrderStatus.waiting;
    else return kdlog.status;
    // if (
    //   this.kitchenDisplayLogs.filter(
    //     (x) => x.folioId == folioId && x.itemId == itemId
    //   ).length == 0
    // ) {
    //   return OrderStatus.waiting;
    // } else {
    //   return this.kitchenDisplayLogs.find(
    //     (x) => x.folioId == folioId && x.itemId == itemId
    //   ).status;
    // }
  }

  async select(f: Folio) {
    this.reportStore.update({ folioListActiveFolio: f });
    const infoModal = await this.modalController.create({
      component: OrderDetailsComponent,
      showBackdrop: true,
      backdropDismiss: true,
      canDismiss: true,
      cssClass: 'product-transfer',
      componentProps: {},
    });
    await infoModal.present();
  }
}
