import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { EcrDepartment } from 'src/app/services/dto/orwi-definitions';
import { EcrService } from 'src/app/services/ecr/ecr.service';
import { GlobalService } from 'src/app/services/global.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';

@Component({
  selector: 'department-definitions',
  templateUrl: './department-definitions.component.html',
  styleUrls: ['./department-definitions.component.scss'],
})
export class DepartmentDefinitionsComponent implements OnInit {
  constructor(
    private ecrService: EcrService,
    private glb: GlobalService,
    public modalController: ModalController,
    private idGen: IdGeneratorService,
    private transloco: TranslocoService
  ) {
    this.getDepartments();
    console.log('departments legth', this.departmentList);
    this.getTaxes();
  }

  ngOnInit(): void {}

  departmentForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    rate: new UntypedFormControl(null, Validators.required),
    sequence: new UntypedFormControl(0, [
      Validators.required,
      Validators.min(0),
    ]),
    kerzzID: new UntypedFormControl(''),
  });
  rates = [{ rate: '10' }];
  taxList = [];
  departmentList;
  content: 'form' | 'list' = 'list';
  selectedDepartment: EcrDepartment;

  getDepartments() {
    this.ecrService.getErcDepartment().then((res) => {
      this.departmentList = res;
    });
  }

  getTaxes() {
    this.ecrService.getTaxDefinitions().then((res) => {
      let list;
      this.taxList = [];
      list = res;
      list.map((tax) => {
        let rate = { rate: tax.rate };
        this.taxList.push(rate);
      });
      console.log(this.taxList);
    });
  }

  save() {
    this.departmentForm.markAllAsTouched();
    if (this.departmentForm.valid) {
      this.departmentForm.markAllAsTouched();

      if (this.departmentForm.valid) {
        this.ecrService
          .saveErcDepartment({
            id: this.idGen.generate(),
            ...this.selectedDepartment,
            ...this.departmentForm.value,
          })
          .then(
            (o: any) => {
              o; //reserved
              this.glb.toast('', 'Successfully Added', 'bottom', 'success');
              this.content = 'list';
              this.getDepartments();
              this.getTaxes();
              this.modalController.dismiss();
            },
            (err) => {
              this.glb.toast('An error occured', err, 'bottom', 'danger');
            }
          );
      }
    }
  }
  deleteDepartment(e) {
    this.glb.openAlert({
      header: this.transloco.translate('Department Deletion'),
      subHeader: this.transloco.translate(
        'Are you sure you would like to delete?'
      ),
      backdropDismiss: false,
      buttons: [
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            this.glb.showLoading();
            this.ecrService.deleteErcDepartment(e).then(
              (o: any) => {
                o; //reserved
                this.glb.toast(
                  'Successfully Deleted!',
                  '',
                  'bottom',
                  'success'
                );
                this.glb.closeLoading();
                this.departmentList = this.departmentList.filter(
                  (item) => item.id !== e
                );
              },
              (err) => {
                this.glb.toast('An error occured', err, 'bottom', 'danger');
                this.glb.closeLoading();
              }
            );
          },
        },
        {
          text: this.transloco.translate('No'),
        },
      ],
    });
  }
  editDepartment(e) {
    console.log(e);
    this.selectedDepartment = e;
    this.departmentForm.patchValue(e);
    this.content = 'form';
  }

  openForm() {
    this.selectedDepartment = undefined;
    this.departmentForm.reset();
    this.content = 'form';
    this.departmentForm.patchValue({
      sequence: (this.departmentList.length + 1) * 1,
    });
    console.log(this.departmentForm.value.sequence);
    // setTimeout(() => {
    //   this.departmentForm.value.sequence = this.departmentList.length + 1;
    // }, 50);
  }
}
