import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Folio } from '../dto/orwi-folio';
import { GlobalService } from '../global.service';
import { EcrBaseService } from './ecr.base.service';
import { MenuStore } from 'src/app/modules/menu/state/menu.store';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class IngenicoWinService implements EcrBaseService {
  terminalIP: string = null;
  constructor(
    private http: HttpClient,
    private glb: GlobalService,
    private menuStore: MenuStore,
    private transloco: TranslocoService
  ) {}

  connect(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.glb.showLoading();
      this.glb.toast('', 'Yazarkasaya bağlanılıyor...', 'bottom', 'success');
      try {
        let httpOptions = {
          headers: new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            'x-api-key': '1453',
          }),
        };
        let body = JSON.stringify({
          ipAddress: this.ipAddress,
          deviceType: 'ingenico',
        });
        let windowsApiServiceAddress =
          this.terminalIP ?? localStorage.getItem('terminalIp');
        await this.http
          .post(
            'http://' + windowsApiServiceAddress + ':1400/api/ecr/connect',
            body,
            httpOptions
          )
          .subscribe(
            (data: any) => {
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              this.glb.consolelog('Connect Ecr Result:', data);
              if (data.Status == 0) {
                this.glb.toast(
                  'Orwi Pos',
                  'Connection to Cash Register Provided',
                  'bottom',
                  'success'
                );
                resolve(true);
              } else {
                this.glb.toast(
                  '',
                  data.Result.ResultMessage,
                  'bottom',
                  'success'
                );
                resolve(false);
              }
            },
            (error) => {
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              let errorMessage;
              if (error.status === 0) {
                errorMessage = this.transloco.translate(
                  'Could not connect to cash register. Please check the settings.'
                );
              }
              this.glb.toast('', errorMessage, 'bottom', 'danger');

              // this.glb.toast('', JSON.stringify(error), 'bottom', 'danger');
              this.glb.consolelog('Connect Ecr Error:', error);
              reject(false);
            }
          );
      } catch (error) {
        this.glb.consolelog('Connect Ecr Catch Error:', error);
        reject(false);
      }
    });
  }
  sale(folio: Folio): Promise<any> {
    return new Promise(async (resolve) => {
      this.glb.toast(
        '',
        'The check is sent to the cash register.',
        'bottom',
        'success'
      );
      folio.rows.forEach((elm) => {
        if (elm.rowType == 'modifier') {
          elm.rowType = 'product';
        }
        if (elm.rowType == 'product') {
          elm.tax =
            this.menuStore.getValue().products.find((x) => x.id == elm.itemID)
              ?.vat || 10;
        }
      });
      let bankRows = folio.rows.filter(
        (el) => el.rowType == 'payment' && el.paymentType == 'bank'
      );
      let otherPaymentRows = folio.rows.filter(
        (el) => el.rowType == 'payment' && el.paymentType != 'bank'
      );
      let formatted: Folio = {
        ...folio,
        rows: [
          ...folio.rows.filter(
            (elm) =>
              elm.rowType != 'payment' &&
              elm.recordStatus != 'deleted' &&
              !elm.isGift
          ),
          ...bankRows,
          ...otherPaymentRows,
        ],
      };
      this.glb.showLoading();
      try {
        let httpOptions = {
          headers: new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            'x-api-key': '1453',
          }),
        };
        console.log('formatted', JSON.stringify(formatted));
        let body = JSON.stringify({ ...formatted });
        let windowsApiServiceAddress =
          this.terminalIP ?? localStorage.getItem('terminalIp');
        this.http
          .post(
            'http://' + windowsApiServiceAddress + ':1400/api/ecr/sale',
            body,
            httpOptions
          )
          .subscribe(
            (data: any) => {
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              console.log('sale Ecr Result:', data);

              if (data.Result.Status === 0) {
                this.glb.toast(
                  'Sales Transaction Successful',
                  '',
                  'bottom',
                  'success'
                );
                resolve(data);
              }
              {
                this.glb.toast(
                  '',
                  data.Result.ResultMessage,
                  'bottom',
                  'success'
                );
                this.glb.overRideClosing = true;
                this.glb.closeAllLoading();
                resolve(false);
              }
            },
            (error) => {
              this.glb.consolelog('sale Ecr Error:', error);
              this.glb.toast('', JSON.stringify(error), 'bottom', 'danger');
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              resolve(false);
            }
          );
      } catch (error) {
        this.glb.consolelog('sale Ecr Catch Error:', error);
        resolve(false);
      }
    });
  }
  printXReport(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.glb.showLoading();
        let httpOptions = {
          headers: new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            'x-api-key': '1453',
          }),
        };
        let body = JSON.stringify({});
        let windowsApiServiceAddress =
          this.terminalIP ?? localStorage.getItem('terminalIp');
        await this.http
          .post(
            'http://' + windowsApiServiceAddress + ':1400/api/ecr/printXReport',
            body,
            httpOptions
          )
          .subscribe(
            (data) => {
              this.glb.consolelog('PrintZReport  Result:', data);
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              resolve(true);
            },
            (error) => {
              this.glb.consolelog('PrintZReport Error:', error);
              this.glb.toast('', JSON.stringify(error), 'bottom', 'danger');
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              reject(false);
            }
          );
      } catch (error) {
        this.glb.consolelog('PrintZReport Catch Error:', error);
        this.glb.toast('', JSON.stringify(error), 'bottom', 'danger');
        reject(false);
      }
    });
  }
  printZReport(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.glb.showLoading();
      try {
        let httpOptions = {
          headers: new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            'x-api-key': '1453',
          }),
        };
        let body = JSON.stringify({});
        let windowsApiServiceAddress =
          this.terminalIP ?? localStorage.getItem('terminalIp');
        await this.http
          .post(
            'http://' + windowsApiServiceAddress + ':1400/api/ecr/printZReport',
            body,
            httpOptions
          )
          .subscribe(
            (data) => {
              this.glb.consolelog('printXReport  Result:', data);
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              resolve(true);
            },
            (error) => {
              this.glb.consolelog('printXReport Error:', error);
              this.glb.toast('', JSON.stringify(error), 'bottom', 'danger');
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              reject(false);
            }
          );
      } catch (error) {
        this.glb.consolelog('printXReport Catch Error:', error);
        this.glb.toast('', JSON.stringify(error), 'bottom', 'danger');
        reject(false);
      }
    });
  }

  syncDepartments(departments?: any): Promise<any> {
    this.glb.toast(
      '',
      'Departments are sent to the cash register',
      'bottom',
      'success'
    );
    return new Promise(async (resolve, reject) => {
      this.glb.showLoading();
      try {
        let httpOptions = {
          headers: new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            'x-api-key': '1453',
          }),
        };
        let body = JSON.stringify({ departments: departments });
        let windowsApiServiceAddress =
          this.terminalIP ?? localStorage.getItem('terminalIp');
        await this.http
          .post(
            'http://' +
              windowsApiServiceAddress +
              ':1400/api/ecr/setDepartments',
            body,
            httpOptions
          )
          .subscribe(
            (data: any) => {
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              if (data.Status == 0) {
                this.glb.toast('', 'Departments Sent', 'bottom', 'success');
                resolve(true);
              } else {
                this.glb.toast('', data.ResultMessage, 'bottom', 'danger');
                resolve(false);
              }
            },
            (error) => {
              this.glb.overRideClosing = true;
              this.glb.closeAllLoading();
              this.glb.toast('', JSON.stringify(error), 'bottom', 'danger');
              this.glb.consolelog('setDepartments Error:', error);
              reject(false);
            }
          );
      } catch (error) {
        this.glb.consolelog('setDepartments Catch Error:', error);
        this.glb.toast('', JSON.stringify(error), 'bottom', 'danger');
        reject(false);
      }
    });
  }
  socketId: string;
  ipAddress: string;
  port: number;

  init(ipAddress: string, port: number, terminalIp: string): EcrBaseService {
    this.ipAddress = ipAddress;
    this.port = port;
    this.terminalIP = terminalIp ?? localStorage.getItem('terminalIp');
    console.log(port, ipAddress, terminalIp);
    return this;
  }
}
