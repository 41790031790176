import { CommonModule, registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ResizableModule } from 'angular-resizable-element';
import { DeferLoadDirective } from 'src/app/helpers/directives/defer-load.directive';
import { HighligthDirective } from 'src/app/helpers/directives/highlight.directive';
import { PhoneMaskDirective } from 'src/app/helpers/directives/phone-mask.directive';
import { LocalizedDatePipe } from 'src/app/helpers/pipes/localized-date.pipe';
import { OpenFolioButtonComponent } from '../modules/folio/components/open-folio-button/open-folio-button.component';
import { OpenFolioComponent } from '../modules/folio/components/open-folio/open-folio.component';
import { OpenFoliosComponent } from '../modules/folio/components/open-folios/open-folios.component';
import { FoliRowListComponent } from '../modules/folio/components/product-transfer/components/foli-row-list/foli-row-list.component';
import { FolioListComponent } from '../modules/folio/components/product-transfer/components/folio-list/folio-list.component';
import { ProductTransferComponent } from '../modules/folio/components/product-transfer/product-transfer.component';
import { DraggableDirective } from '../helpers/directives/draggable.directive';
import { SharedModule } from '../shared/shared.module';
import { TableButtonComponent } from '../modules/tables/componets/buttons/table-button/table-button.component';
import { TableGroupButtonComponent } from '../modules/tables/componets/buttons/table-group-button/table-group-button.component';
import { CircularProgressComponent } from '../modules/tables/componets/circular-progress/circular-progress.component';
import { OpenTableFoliosListComponent } from '../modules/tables/componets/open-table-folios-list/open-table-folios-list.component';
import { OpenTableFoliosSelectComponent } from '../modules/tables/componets/open-table-folios-select/open-table-folios-select';
import { OrwiTableListComponent } from '../modules/tables/componets/orwi-table-list/orwi-table-list.component';
import { SelectTableFolioComponent } from '../modules/tables/componets/select-table-folio/select-table-folio.component';
import { TableCombineComponent } from '../modules/tables/componets/table-change-combine/table-combine/table-combine.component';
import { TableContainerComponent } from '../modules/tables/componets/table-container/table-container.component';
import { TableChangeCombineComponent } from '../modules/tables/componets/table-change-combine/table-change-combine.component';
import { TableGroupContainerComponent } from '../modules/tables/componets/table-group-container/table-group-container.component';
import { TableHelpersComponent } from '../modules/tables/componets/table-helpers/table-helpers.component';
import { CloudMenuComponent } from './pos/cloud-menu/cloud-menu.component';
import { NumpadComponent } from './ui/numpad/numpad.component';
import { OrwiBackComponent } from './ui/orwi-back/orwi-back.component';
import { OrwiModalComponent } from './ui/orwi-modal/orwi-modal.component';
import { OrwiPromptComponent } from './ui/orwi-prompt/orwi-prompt.component';
import { ResizeableDivDirective } from '../helpers/directives/resizeablediv.directive';
import { TurnableDrictive } from '../helpers/directives/turnable.directive';
import { TableDesignerToolboxComponent } from '../modules/tables/componets/table-designer-toolbox/table-designer-toolbox.component';
import { FreeDragableDirective } from '../helpers/directives/free-dragable.directive';
import { PaxComponent } from '../modules/folio/components/pax/pax.component';
import { ZReportFormComponent } from '../modules/folio/components/z-report-form/z-report-form.component';
import { DraggableFolioDirective } from '../helpers/directives/dragable-folio.directive';
import { DesignTabeDirective } from '../helpers/directives/design-table.directive';
import { OrwiLockModalComponent } from './ui/orwi-lock-modal/orwi-lock-modal.component';
import { EcrSettingsComponent } from './ui/ecr-settings/ecr-settings.component';
import { OrwiHeaderComponent } from './ui/orwi-header/orwi-header.component';
import { AddBulkTableModalComponent } from '../modules/tables/componets/add-bulk-table-modal/add-bulk-table-modal.component';
import { TableGroupListModalComponent } from '../modules/tables/componets/table-group-list-modal/table-group-list-modal.component';
import { TgListItemComponent } from '../modules/tables/componets/table-group-list-modal/tg-list-item/tg-list-item.component';
import { EftPosListComponent } from '../modules/folio/components/eft-pos-list/eft-pos-list.component';
import { FoodAppStoreStatusButtonComponent } from './ui/food-app-store-status-button/food-app-store-status-button.component';
import { GitReasonsComponent } from '../modules/folio/components/git-reasons/git-reasons.component';
import { DeleteProductFolioComponent } from '../modules/folio/components/delete-product-folio/delete-product-folio.component';
import { FolioInfoComponent } from '../modules/folio/components/folio-info/folio-info.component';
import { AttributeListModalComponent } from './ui/attribute-list-modal/attribute-list-modal.component';
import { FolioRowSyncComponent } from '../modules/folio/components/folio-row-sync/folio-row-sync.component';
import { RowSyncProductSelectComponent } from '../modules/folio/components/row-sync-product-select/row-sync-product-select.component';
import { DepartmentDefinitionsComponent } from './ui/ecr-settings/department-definitions/department-definitions.component';
import { TaxDefinitionsComponent } from './ui/ecr-settings/tax-definitions/tax-definitions.component';
import { ComponentsRoutingModule } from './components-routing.module';
import { InvoiceHistoryModalComponent } from '../modules/backOffice/invoice/components/inbound-invoice/components/invoice-history-modal/invoice-history-modal.component';
import { TransferInfoModalComponent } from '../modules/backOffice/warehouse-transfer/components/transfer-info-modal/transfer-info-modal.component';
import { SelectSearchComponent } from './ui/select-search/select-search.component';
import { SelectAllDirective } from '../helpers/directives/select-all.directive';
import { SelectSearchDirective } from '../helpers/directives/select-search.directive';
import { ChangeInvoiceModalComponent } from '../modules/backOffice/invoice/components/inbound-invoice/components/change-invoice-modal copy/change-invoice-modal.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AccountListModalComponent } from './ui/account-list-modal/account-list-modal.component';
import { AccountAddModalComponent } from './ui/account-add-modal/account-add-modal.component';
import { NotificationPopoverComponent } from './ui/notification-popover/notification-popover.component';
import { NotificationToastrComponent } from './ui/notification-toastr/notification-toastr.component';
import { UserInfoComponent } from '../modules/folio/components/user-info/user-info.component';
import { FolioLogComponent } from '../modules/folio/components/folio-log/folio-log.component';

registerLocaleData(localeTr);

@NgModule({
  imports: [
    ScrollingModule,
    ResizableModule,
    SharedModule,
    CommonModule,
    ComponentsRoutingModule,
    IonicModule.forRoot({
      backButtonText: 'Geri',
      innerHTMLTemplatesEnabled: true,
    }),
  ],
  declarations: [
    OrwiPromptComponent,
    OrwiModalComponent,
    OrwiBackComponent,
    CloudMenuComponent,
    NumpadComponent,
    FoliRowListComponent,
    FolioListComponent,
    OrwiLockModalComponent,
    //ProfileButtonComponent,
    //ProfilePopoverComponent,
    //ProfileModalComponent,
    EftPosListComponent,
    //Directives
    PhoneMaskDirective,
    HighligthDirective,
    DeferLoadDirective,
    //ParallaxHeaderDirective,
    DraggableDirective,
    DraggableFolioDirective,
    ResizeableDivDirective,
    TurnableDrictive,
    FreeDragableDirective,
    DesignTabeDirective,
    SelectAllDirective,
    SelectSearchDirective,
    //PIPES
    LocalizedDatePipe,
    //TABLES
    TableButtonComponent,
    TableGroupButtonComponent,
    TableGroupContainerComponent,
    TableContainerComponent,
    ProductTransferComponent,
    TableCombineComponent,
    TableHelpersComponent,
    CircularProgressComponent,
    TableChangeCombineComponent,
    OpenTableFoliosListComponent,
    OpenTableFoliosSelectComponent,
    OrwiTableListComponent,
    SelectTableFolioComponent,
    TableDesignerToolboxComponent,
    AddBulkTableModalComponent,
    ChangeInvoiceModalComponent,
    InvoiceHistoryModalComponent,
    TransferInfoModalComponent,
    TableGroupListModalComponent,
    TgListItemComponent,
    //FOLIO
    //FolioComponent,
    // FolioRowsComponent,
    // FolioHeaderComponent,
    OpenFoliosComponent,
    OpenFolioComponent,
    OpenFolioButtonComponent,
    // ModifiersFormComponent,
    //MENU
    // ProductButtonComponent,
    // ProductGroupButtonComponent,
    // ProductContainerComponent,
    // ProductGroupComponent,
    //PAYMENT
    //DELIVERY
    //DeliveryListComponent,
    // PaxComponent,
    ZReportFormComponent,
    EcrSettingsComponent,
    OrwiHeaderComponent,
    //DeliveryStatusComponent,
    FoodAppStoreStatusButtonComponent,

    GitReasonsComponent,
    DeleteProductFolioComponent,

    PaxComponent,
    FolioRowSyncComponent,
    RowSyncProductSelectComponent,
    FolioInfoComponent,
    FolioLogComponent,
    AttributeListModalComponent,

    DepartmentDefinitionsComponent,
    TaxDefinitionsComponent,
    SelectSearchComponent,

    AccountListModalComponent,
    AccountAddModalComponent,
    NotificationPopoverComponent,
    NotificationToastrComponent,
    UserInfoComponent,
  ],
  exports: [
    ResizableModule,
    OrwiPromptComponent,
    OrwiModalComponent,
    OrwiBackComponent,
    CloudMenuComponent,
    NumpadComponent,
    FoliRowListComponent,
    FolioListComponent,
    OrwiLockModalComponent,
    //ProfileButtonComponent,
    EftPosListComponent,
    //Directives
    PhoneMaskDirective,
    HighligthDirective,
    DeferLoadDirective,
    //ParallaxHeaderDirective,
    DraggableDirective,
    DraggableFolioDirective,
    ResizeableDivDirective,
    TurnableDrictive,
    FreeDragableDirective,
    DesignTabeDirective,
    //PIPES
    LocalizedDatePipe,
    //TABLES
    TableButtonComponent,
    TableGroupButtonComponent,
    TableGroupContainerComponent,
    TableContainerComponent,
    ProductTransferComponent,
    TableCombineComponent,
    TableHelpersComponent,
    CircularProgressComponent,
    TableChangeCombineComponent,
    OpenTableFoliosListComponent,
    OpenTableFoliosSelectComponent,
    OrwiTableListComponent,
    SelectTableFolioComponent,
    TableDesignerToolboxComponent,
    AddBulkTableModalComponent,
    TableGroupListModalComponent,
    TgListItemComponent,
    SelectAllDirective,
    SelectSearchDirective,
    //FolioComponent,
    // FolioRowsComponent,
    // FolioHeaderComponent,
    OpenFoliosComponent,
    OpenFolioComponent,
    OpenFolioButtonComponent,
    // ModifiersFormComponent,
    //MENU
    // ProductButtonComponent,
    // ProductGroupButtonComponent,
    // ProductContainerComponent,
    // ProductGroupComponent,
    //PAYMENT
    // PaxComponent,
    ZReportFormComponent,
    //DELIVERY
    //DeliveryListComponent,
    EcrSettingsComponent,
    OrwiHeaderComponent,
    //DeliveryStatusComponent,
    FoodAppStoreStatusButtonComponent,

    GitReasonsComponent,
    DeleteProductFolioComponent,

    PaxComponent,
    FolioInfoComponent,

    AttributeListModalComponent,

    DepartmentDefinitionsComponent,
    TaxDefinitionsComponent,
    AccountListModalComponent,
    AccountAddModalComponent,
    NotificationPopoverComponent,
    IonicModule,
  ],
})
export class ComponentsModule {}
