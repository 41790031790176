import { Injectable, inject } from '@angular/core';
import {
  InventorySelectItem,
  Invoice,
  InvoiceInventoryHistory,
  InvoiceListItem,
} from 'kerzz-base';
import { GlobalService } from 'src/app/services/global.service';
import { BackOfficeService } from '../../shared/services/back-office.service';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { InvoiceStore } from './invoice.store';
import { RxDBService } from 'src/app/services/rxDB.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { Capacitor } from '@capacitor/core';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  invoices: InvoiceListItem[] = [];
  selectedInvoiceType: 'in' | 'out' = 'in';
  glb = inject(GlobalService);
  constructor(
    private backOfficeService: BackOfficeService,
    private storeQuery: OrwiStoreQuery,
    private invoiceStore: InvoiceStore,
    private rxDBService: RxDBService,
    private idGenerator: IdGeneratorService
  ) {
    console.log('globalservice', this.glb);
  }

  getInvoicesByDate(startDate, endDate, listType): Promise<InvoiceListItem[]> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/invoice/getInvoicesByDate', {
          storeId: this.storeQuery._orwiStore.id,
          startDate: startDate,
          endDate: endDate,
          listType: listType,
        })
        .then((o: any) => {
          this.invoices = o;
          this.invoiceStore.update({ invoices: this.invoices });
          resolve(this.invoices);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No invoices can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  deleteInvoice(invoiceNumber, accountCode, invoiceType) {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/invoice/deleteInvoice', {
          storeId: this.storeQuery._orwiStore.id,
          invoiceNumber: invoiceNumber,
          accountCode: accountCode,
          invoiceType: invoiceType,
        })
        .then((o: any) => {
          o;
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Error',
              'Invoice cannot be deleted',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  changeInvoiceNo(
    invoiceNumber,
    accountCode,
    invoiceType,
    newInvoiceNumber,
    newAccountCode
  ) {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/invoice/changeInvoiceNumber', {
          storeId: this.storeQuery._orwiStore.id,
          invoiceNumber: invoiceNumber,
          accountCode: accountCode,
          invoiceType: invoiceType,
          newInvoiceNumber: newInvoiceNumber,
          newAccountCode: newAccountCode,
        })
        .then((o: any) => {
          o;
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  async fetchAllInventorySelectListForBarcode() {
    return new Promise(async (resolve, reject) => {
      const isDBSeeded = await this.rxDBService.countOfData('invoicelist');
      if (isDBSeeded > 0) return resolve([]);
      return this.backOfficeService
        .serviceRequestPromise('/inventory/getInventorySelectList', {
          storeId: this.storeQuery._orwiStore.id,
          filter: '',
          listType: 6,
        })
        .then(async (o: any) => {
          o;
          await this.rxDBService.bulkInsertDataToCollection(
            'invoicelist',
            o
              .filter((el) => el.barcode.length > 0)
              .map((el) => ({
                ...el,
                inventoryCode: el.inventoryCode.trim(),
                id: this.idGenerator.generate(),
              }))
          );
          return resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Error',
              'No stocks can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast(
                'Error',
                this.glb.removeHtmlTags(e),
                'middle',
                'warning'
              );
            }
            reject(e);
          }
        });
    });
  }

  getInventorySelectListForBarcode(
    filter,
    filterType: 'like' | 'exact' = 'exact'
  ): Promise<InventorySelectItem[]> {
    return new Promise(async (resolve) => {
      const isDBSeeded = await this.rxDBService.countOfData('invoicelist');
      console.log('rxdb', isDBSeeded);
      if (isDBSeeded > 0) {
        if (filter.length == 0) {
          const data = await this.rxDBService.getDataFromCollection(
            'invoicelist'
          );
          return resolve(data);
        }
        if (Capacitor.isNativePlatform() || filterType == 'exact') {
          let data = await this.rxDBService.getDataFromCollection<InventorySelectItem>(
            'invoicelist',
            {
              selector: {
                barcode: filter,
              },
            },
            filterType
          );
          console.log('RXDB: ', data);

          return resolve(data);
        }
        if (filterType == 'like') {
          let data = await this.rxDBService.getDataFromCollection<InventorySelectItem>(
            'invoicelist',
            {
              selector: {
                $or: [
                  {
                    barcode: { $options: 'i', $regex: '.*' + filter + '.*' },
                  },
                  {
                    inventoryName: {
                      $options: 'i',
                      $regex: '.*' + filter + '.*',
                    },
                  },
                  {
                    inventoryCode: {
                      $options: 'i',
                      $regex: '.*' + filter + '.*',
                    },
                  },
                ],
              },
            }
          );
          console.log('RXDB: ', data);
          return resolve(data);
        }
      }
    });
  }

  getInventorySelectList(filter, fiterType): Promise<InventorySelectItem[]> {
    return new Promise(async (resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/inventory/getInventorySelectList', {
          storeId: this.storeQuery._orwiStore.id,
          filter: filter,
          listType: fiterType,
        })
        .then(async (o: any) => {
          o;

          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Error',
              'No stocks can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast(
                'Error',
                this.glb.removeHtmlTags(e),
                'middle',
                'warning'
              );
            }
            reject(e);
          }
        });
    });
  }

  saveInventoryBarcode(formData: any) {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/inventory/saveInventoryBarcode', {
          storeId: this.storeQuery._orwiStore.id,
          ...formData,
        })
        .then((o: any) => {
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Error',
              'No stocks can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            reject(e);
          }
        });
    });
  }

  saveInvoice(invoice: Invoice) {
    invoice.storeId = this.storeQuery._orwiStore.id;
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/invoice/saveInvoice', {
          storeId: this.storeQuery._orwiStore.id,
          invoice: invoice,
        })
        .then((o: any) => {
          o;
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            if (e?.error?.message && e?.error?.message != '') {
              this.glb.toast(
                'Invoice cannot be saved',
                e?.error.message,
                'middle',
                'warning',
                5000
              );
            } else {
              this.glb.toast(
                'Error',
                'Invoice cannot be saved',
                'middle',
                'warning'
              );
            }
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast(
                'Error',
                this.glb.removeHtmlTags(e),
                'middle',
                'warning'
              );
            }
            reject(e);
          }
          this.glb.closeLoading();
        });
    });
  }

  getInvoice(invoiceNumber, accountCode, invoiceType): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/invoice/getInvoice', {
          storeId: this.storeQuery._orwiStore.id,
          invoiceNumber: invoiceNumber,
          accountCode: accountCode,
          invoiceType: invoiceType,
        })
        .then((o: Invoice) => {
          if (o.invoiceType === 0 || o.invoiceType === 2) {
            this.selectedInvoiceType = 'in';
          } else {
            this.selectedInvoiceType = 'out';
          }
          o;
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No invoices can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  getInvoiceHistory(
    invoiceNumber,
    accountCode,
    invoiceType,
    inventoryCode
  ): Promise<InvoiceInventoryHistory> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/invoice/getInvoiceHistory', {
          storeId: this.storeQuery._orwiStore.id,
          invoiceNumber: invoiceNumber,
          accountCode: accountCode,
          invoiceType: invoiceType,
          inventoryCode: inventoryCode,
        })
        .then((o: InvoiceInventoryHistory) => {
          o;
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No invoices can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }
}
