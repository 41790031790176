import { Injectable } from '@angular/core';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { FolioRow, ServiceType } from 'src/app/services/dto/orwi-folio';
import {
  Modifier,
  ModifierCover,
  ModifierGroup,
  ModifierProduct,
} from 'src/app/services/dto/orwi-product';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { MenuStore } from '../../state/menu.store';
import { NumpadService } from 'src/app/components/ui/numpad/state/numpad.service';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class ModifiersService {
  constructor(
    private idGen: IdGeneratorService,
    private menuStore: MenuStore,
    private folioStore: FolioStore,
    private numpadService: NumpadService,
    private folioService: FolioService,
    private sessionQuery: SessionQuery,
    private transloco: TranslocoService
  ) {}

  modifiers(must: boolean) {
    let { groups, groupSub, products } = this.menuStore.getValue();
    let row: FolioRow = this.folioStore.getValue().activeFolioRow;
    let product = products.find((pr) => pr.id == row.itemID);
    let group =
      groups.find((o) => o.id == product.group) ||
      groupSub.find((o) => o.id == product.group);
    if (!row.modifierGroups) {
      row.modifierGroups = [];
    }

    if (must && row.mustModifiers?.length > 0) {
      this.folioStore.update({
        showModifiers: true,
        showModifiersGroup: 'must',
      });

      return;
    } else if (!must && row.modifiers?.length > 0) {
      this.folioStore.update({
        showModifiers: true,
        showModifiersGroup: 'std',
      });
      return;
    }

    let modifiers = this.getModifiers(product?.id, group?.id, 'table', must);
    let stringModifier = JSON.stringify(modifiers);
    if (modifiers.length > 0) {
      let mg: ModifierGroup = new ModifierGroup();
      mg.order = must ? 1 : 0;
      mg.type = must ? 'must' : 'std';
      mg.modifierGroup = modifiers;

      if (must) {
        row.mustModifiers = JSON.parse(stringModifier);
        this.folioStore.update({ activeFolioRow: null });
        setTimeout(() => {
          this.folioStore.update({ activeFolioRow: row });
        }, 100);
      } else {
        this.folioStore.update(
          (x) => (x.activeFolioRow.modifiers = JSON.parse(stringModifier))
        );
      }

      // this.folioStore.update((p) => {
      //   p//reserved
      //   //p.activeFolioRow.modifierGroups.push(mg)
      // });

      this.folioStore.update({
        showModifiers: true,
        showModifiersGroup: must ? 'must' : 'std',
      });
    }
  }

  hasModifiers(productID) {
    let { products, groups, groupSub } = this.menuStore.getValue();
    let product = products.find((pr) => pr.id == productID);
    let group =
      groups.find((o) => o.id == product?.group) ||
      groupSub.find((o) => o.id == product?.group);

    return this.getModifiers(product?.id, group?.id, 'table', false).length > 0;
  }
  getModifiers(
    productID,
    productGroupID,
    _st: ServiceType = 'table',
    must: boolean = true
  ): Modifier[] {
    let groups: ModifierCover[];
    let products: ModifierCover[];
    let combine: Modifier[] = [];
    let {
      productModifiers,
      groupModifiers,
      products: prodStore,
    } = this.menuStore.getValue();

    products = productModifiers?.filter((o) => o.productID == productID) || [];

    // if (products.length == 0)
    // {
    //   products =
    //   this.menuStore
    //     .getValue()
    //     .productModifiers?.filter((o) => o.productGroupID == productGroupID) || [];
    // }
    // await Object.assign(this.cs.readDocumentFromMongo('bigchefs/menu-product_modifiers', { productID: productID }).toPromise())
    groups =
      groupModifiers?.filter((o) => o.productGroupID == productGroupID) || []; //await Object.assign(this.cs.readDocumentFromMongo('bigchefs/menu-menu-product_group_modifiers', { productGroupID: productGroupID }).toPromise())

    for (const iterator of products) {
      for (const it of iterator.modif) {
        combine.push(it);
      }
    }
    for (const iterator of groups) {
      for (const it of iterator.modif) {
        combine.push(it);
      }
    }

    let custom = combine.filter((o) => o.type == 'custom');
    let product = combine.filter((o) => o.type == 'product');
    let group = combine.filter((o) => o.type == 'group');

    for (const iterator of product) {
      for (const it of iterator.products) {
        let prd = prodStore.find((o) => o.id == it.productID);
        if (prd) {
          it.productName = prd.name;
        }
      }
    }

    for (const iterator of group) {
      iterator.products = [];

      let prd = prodStore.filter((o) => o.group == iterator.productGroup);

      for (const it of prd) {
        let mp: ModifierProduct = new ModifierProduct();
        mp.productName = it.name;
        mp.productID = it.id;
        mp.canBeAdded = iterator.canBeAdded;
        mp.detachable = iterator.detachable;
        mp.price = iterator.priceless ? 0 : it.price; //! implement service type?
        mp.priceless = iterator.priceless;
        mp.selected = false;
        iterator.products.push(mp);
      }
    }

    combine = [];
    for (const iterator of custom) {
      iterator.products.map((o) => {
        o.selected = false;
        o.temp_rowID = '';
        o.subModifiers = [];
      });
      combine.push(iterator);
    }
    for (const iterator of product) {
      iterator.products.map((o) => {
        o.selected = false;
        o.temp_rowID = '';
        o.subModifiers = [];
      });
      combine.push(iterator);
    }
    for (const iterator of group) {
      iterator.products.map((o) => {
        o.selected = false;
        o.temp_rowID = '';
        o.subModifiers = [];
      });
      combine.push(iterator);
    }

    if (this.folioStore.getValue().activeFolioRow.recordStatus != 'new') {
      //öndecen gönderilmiş ürüne varyasyon giremez
      if (must) {
        combine = combine.filter((o) => o.minSelect > 0);
      } else {
        combine = combine.filter((o) => o.minSelect == 0);
      }
    }

    for (const iterator of combine) {
      iterator.id = this.idGen.generate();
    }

    return combine;
  }

  oldgetModifiers(productID, productGroupID, _st: ServiceType = 'table') {
    let groups: ModifierCover[];
    let products: ModifierCover[];
    let combine: Modifier[] = [];

    products =
      this.menuStore
        .getValue()
        .productModifiers?.filter((o) => o.productID == productID) || [];
    groups =
      this.menuStore
        .getValue()
        .groupModifiers?.filter((o) => o.productGroupID == productGroupID) ||
      [];
    for (const iterator of products) {
      for (const it of iterator.modif) {
        combine.push(it);
      }
    }
    for (const iterator of groups) {
      for (const it of iterator.modif) {
        combine.push(it);
      }
    }

    combine.map((x) => {
      x.products.map((c) => (c.selected = false));
    });

    return combine;
  }

  async addRemoveModifier(
    modifierProduct: ModifierProduct,
    modifier: Modifier,
    folioRowParentID,
    isDetach = false,
    qtyID = 1
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // let parentRow = this.folioStore
      //   .getValue()
      //   .activeFolio.rows.find((o) => o.id == folioRowParentID);
      let selLenght = modifier.products.filter((p) => p.selected).length;
      if (selLenght > modifier.maxSelect && modifier.maxSelect != 0) {
        modifierProduct.selected = false;
        reject({
          error: 'exceeded selectin',
          min: modifier.minSelect,
          max: modifier.maxSelect,
          group: modifier.group,
        });
      }

      if (modifierProduct.selected) {
        let row = this.createFolioRow(
          modifierProduct.productID,
          modifierProduct.productName,
          this.numpadService.getValue('qty'),
          folioRowParentID,
          modifierProduct.price
        );
        row.qtyID = qtyID;
        row.rowType = 'modifier';
            let logObject = {
      id: this.idGen.generate(),
      storeId: this.sessionQuery.activeLicense.orwiStore.id,
      folioId: this.folioStore
      .getValue()
      .activeFolio.id,
      createDate: new Date(),
      logLevel: 'debug',
      actionType: 'add-modifier',
      userId: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      data: '',
      infoType: 'information',
      description: this.transloco.translate('Modifier Add'),
    };
    logObject
    // await this.folioService.saveFolioLog(logObject)
        // row.printer = parentRow.printer;
        row.isModifier = modifier.minSelect == 0;
        row.isMustModifier = modifier.minSelect > 0;
        // if (localStorage.getItem('activeStore') == '050d-57752172364-f961') {
        row.itemID = row.id;
        // }
        if (isDetach) {
          row.isDetachableModifier = true;
          row.unitPrice =
            row.unitPrice == 0 ? 0 : row.unitPrice - row.unitPrice * 2;
        }

        if (modifierProduct.productID != '') {
          modifierProduct.subModifiers = this.getModifiers(
            modifierProduct.productID,
            ''
          );
        }
        modifierProduct.temp_rowID = row.id;
        modifierProduct.selected = true;
        row.modifiers = modifierProduct.subModifiers;
        row.tax = 10;
        this.folioStore.update((p) => {
          p.activeFolio.rows.push(row);
        });
      } else {
        this.removeRow(modifierProduct.temp_rowID);
            let logObject = {
      id: this.idGen.generate(),
      storeId: this.sessionQuery.activeLicense.orwiStore.id,
      folioId: this.folioStore
      .getValue()
      .activeFolio.id,
      createDate: new Date(),
      logLevel: 'debug',
      actionType: 'delete-modifier',
      userId: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      data: '',
      infoType: 'information',
      description: this.transloco.translate('Modifier Delete'),
    };
    await this.folioService.saveFolioLog(logObject)
        modifierProduct.temp_rowID = '';
        modifierProduct.subModifiers = [];
      }
      resolve(true);
    });
  }

  removeRow(folioRowID: string) {
    // let subRows = this.folioStore
    //   .getValue()
    //   .activeFolio.rows.filter((p) => p.parentID == folioRowID);
    // subRows.forEach((element) => {
    //   let subFoliIndex = this.folioStore
    //     .getValue()
    //     .activeFolio.rows.findIndex((sf) => sf.id == element.id);
    //   this._removeRow(subFoliIndex);
    // });
    let folioIndex = this.folioStore
      .getValue()
      .activeFolio.rows.findIndex((p) => p.id == folioRowID);
    this._removeRow(folioIndex);
  }

  private _removeRow(index: number) {
    let { activeFolio } = this.folioStore.getValue();
    if (activeFolio.rows[index].recordStatus == 'new') {
      activeFolio.rows.splice(index, 1);
    } else {
      activeFolio.rows[index].recordStatus = 'deleted';
    }
  }

  createFolioRow(
    product_id,
    product_name,
    qty,
    parent_id,
    price,
    image?,
    isGift?: boolean
  ): FolioRow {
    isGift = isGift == undefined ? false : isGift == false ? false : true;
    let row: FolioRow = new FolioRow();
    row.id = this.idGen.generate();
    row.name = product_name;
    row.qty = qty;
    row.unitPrice = price;
    row.price = qty * price;
    row.itemID = product_id;
    row.itemImage = image;
    row.parentID = parent_id;
    row.recordStatus = 'new';
    row.isGift = isGift;

    //this.pushRow(row)
    return row;
  }

  //check modifiers selection
  modifierCheck = [];
  private _checkModifier(m: Modifier, qtyID) {
    let selected = m.products.filter((x) => x.selected).length;
    let ok =
      (selected >= m.minSelect && selected <= m.maxSelect) ||
      (m.minSelect === 0 && m.maxSelect === 0);
    if (!ok) {
      this.modifierCheck.push({
        id: m.id,
        name: m.group,
        min: m.minSelect,
        max: m.maxSelect,
        qtyID: qtyID,
      });
    }
    for (const iterator of m.products) {
      for (const it of iterator.subModifiers) {
        this._checkModifier(it, 1);
      }
    }
  }

  checkModifiers() {
    this.modifierCheck = [];
    if (this.folioStore.getValue().activeFolioRow?.mustModifiers?.length > 0) {
      for (const _modifs of this.folioStore.getValue().activeFolioRow
        .mustModifiers) {
        if (_modifs.must == true) {
          this._checkModifier(_modifs, 1);
        }
      }
    }
    if (this.folioStore.getValue().activeFolioRow.modifiers?.length > 0) {
      for (const _modifs of this.folioStore.getValue().activeFolioRow
        .modifiers) {
        if (_modifs.must == true) {
          this._checkModifier(_modifs, 1);
        }
      }
    }
    return this.modifierCheck;
  }
}
