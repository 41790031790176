<div (tap)="deneme($event)" class="table-group-btn ripple-parent ion-activatable"
  [ngClass]="{selected: tableGroup.selected}" (click)="selectItem()">
  <div style="    display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  align-items: center;">

    <!-- <div (click)="info($event,tableGroup)" class="table-group-btn-info">
      <ion-icon color="secondary" name="information-circle-outline"></ion-icon>
    </div> -->
    <div class="name">
      {{tableGroup.name}}
    </div>
  </div>
  <div>
    <div class="info">
      {{tableGroup.info.open}}/{{tableGroup.info.tables}} {{'Table' | transloco}}
      <div class="table-group-btn-progress">
        <orwi-circular-progress [id]="this.tableGroup.id" width="20" height="20"
          [value]="this.tableGroup.info.occupancyRatio"></orwi-circular-progress>
        <div style="margin-left: 5px; font-size: 12px; color:#6AEADB; font-family: 'Roboto';">
          {{tableGroup.info.occupancyRatio}} %</div>
      </div>
    </div>
  </div>
  <ion-ripple-effect></ion-ripple-effect>
</div>