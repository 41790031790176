import { Component, OnInit, ViewChild } from '@angular/core';
import { IonItemSliding, ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { GlobalService } from 'src/app/services/global.service';
import { FoodAppsSyncService } from '../../../service/food-apps-sync.service';
import { FoodAppAccount, FoodAppProduct } from '../../../state/dto';
import { FoodAppsIntegrationQuery } from '../../../state/food-apps-integration.query';
import { FoodAppsIntegrationService } from '../../../state/food-apps-integration.service';
import { FoodAppsIntegrationStore } from '../../../state/food-apps-integration.store';
import { SyncStoreListComponent } from '../../sync-store-list/sync-store-list.component';
import { ProductSelectComponent } from '../product-select/product-select.component';
import * as XLSX from 'xlsx';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { ProgressModalComponent } from 'src/app/shared/components/progress-modal/progress-modal.component';
import { arrayRemove } from '@datorama/akita';

@Component({
  selector: 'app-product-sync',
  templateUrl: './product-sync.component.html',
  styleUrls: ['./product-sync.component.scss'],
})
export class ProductSyncComponent implements OnInit {
  targetFoodAppProducts: FoodAppProduct[] = [];
  @ViewChild('slidingCase') dialog: IonItemSliding;
  progress: boolean = false;
  constructor(
    private transloco: TranslocoService,
    private integrationService: FoodAppsIntegrationService,
    private modalService: ModalController,
    private sessionQuery: SessionQuery,
    private glb: GlobalService,
    private foodAppsSyncService: FoodAppsSyncService,
    private integrationStore: FoodAppsIntegrationStore,
    public integrationQuery: FoodAppsIntegrationQuery
  ) {}

  ngOnInit() {
    //this.integrationService.syncProduct()
    setTimeout(() => {
      this.showSlider();
    }, 500);
  }

  async selectAccount() {
    const printerModal = await this.glb.openModal({
      component: SyncStoreListComponent,
      cssClass: 'list-modal',
    });
    printerModal.onDidDismiss().then(async (res) => {
      let msg = this.transloco.translate(
        `Products of {{store}} will get synchronized with the selected stores. Do you accept?`,
        {
          store: this.sessionQuery.activeLicense.orwiStore.name,
        }
      );
      if (res.data.length > 0) {
        await this.glb.openAlert({
          header: this.transloco.translate(
            'Variation Synchronization (WARNING!!)'
          ),
          message: msg,
          buttons: [
            this.transloco.translate('No'),
            {
              text: this.transloco.translate('Yes'),
              handler: () => {
                res.data.forEach((element) => {
                  this.foodAppsSyncService
                    .getFoodAppProducts(
                      element.cloudId,
                      element.id,
                      element.foodApp
                    )
                    .then(async (x) => {
                      this.targetFoodAppProducts = x;
                      this.confirmSync(element);
                    });
                });
              },
            },
          ],
        });
      }
    });
  }

  async confirmSync(foodAppAccount: FoodAppAccount) {
    console.log(
      this.targetFoodAppProducts,
      this.integrationQuery.foodAppProducts
    );

    this.targetFoodAppProducts.map((x) => {
      x.changed = false;
    });
    this.targetFoodAppProducts.map((x) => {
      if (x.source == 'fuudy') {
        if (
          this.integrationQuery.foodAppProducts.filter(
            (sp) => sp.name == x.name
          ).length > 0
        ) {
          x.internalId =
            this.integrationQuery.foodAppProducts.find(
              (f) => f.name == x.name && f.source == x.source
            ).internalId || '';
          x.changed = true;
        }
      } else {
        if (
          this.integrationQuery.foodAppProducts.filter((sp) => sp.id == x.id)
            .length > 0
        ) {
          x.internalId =
            this.integrationQuery.foodAppProducts.find(
              (f) => f.id == x.id && f.source == x.source
            ).internalId || '';
          x.changed = true;
        }
      }
    });
    this.progress = true;
    this.foodAppsSyncService
      .syncProducts(this.targetFoodAppProducts, foodAppAccount.cloudId)
      .then((x) => {
        x; //reserved
        this.progress = false;
      });
  }

  exportexcel(): void {
    const newArray = this.integrationQuery.foodAppProducts.map(
      ({ accountId, id, price, group, changed, auto, ...keepAttrs }) =>
        keepAttrs
    );
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newArray);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'modifiers.xlsx');
  }

  getName(id) {
    if (id == '') {
      return '';
    } else {
      let nd = this.integrationQuery.restaurantProducts.find((o) => o.id == id);
      if (nd) {
        return nd.name;
      } else {
        return '';
      }
    }
  }

  showSlider() {
    if (!this.dialog) return;
    //if (this.glb.listItemSlidingHelper) return;
    setTimeout(() => {
      this.dialog.open('start');
      setTimeout(() => {
        this.dialog.open('end');
      }, 1000);
      setTimeout(() => {
        this.dialog.close();
      }, 1500);
    }, 1);
    //this.glb.listItemSlidingHelper = true
  }

  getProducts(group) {
    return this.integrationQuery.foodAppProducts.filter(
      (o) => o.group == group
    );
  }

  syncSelect(item: FoodAppProduct) {
    this.integrationStore.update({ activeFoodAppProduct: item });
    this.openProductSelect();
  }

  deleteMatch(slidingItem: any, product: FoodAppProduct) {
    slidingItem.close();
    this.integrationQuery.foodAppProducts.find(
      (x) => x.id == product.id
    ).changed = true;
    this.integrationQuery.foodAppProducts.find(
      (x) => x.id == product.id
    ).internalId = '';
  }

  deleteRow(slidingItem: any, product: FoodAppProduct) {
    slidingItem.close();
    this.glb.openAlert({
      header: this.transloco.translate('Delete'),
      subHeader:
        product.name + ' isimli ürün eşleşme listesinden silinecektir?',
      backdropDismiss: false,
      buttons: [
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            this.integrationService.deleteProduct(product).then((x) => {
              x
              this.integrationStore.update(({ foodAppProducts }) => ({
                foodAppProducts: arrayRemove(foodAppProducts, product.id),
              }));
              this.glb.toast(
                'Successfully deleted',
                '',
                'bottom',
                'success'
              );
            });
          },
        },
        {
          text: this.transloco.translate('No'),
        },
      ],
    });
    console.log(slidingItem, product);
    // this.foodAppsSyncService.d
  }

  async syncBestMatch() {
    await this.integrationService.syncProduct();
  }

  async save() {
    const productsToSave = this.integrationQuery.foodAppProducts.filter(
      (o) => o.changed == true
    );
    if (productsToSave.length === 0) {
      this.glb.toast(
        'Error',
        'There are no products to match',
        'bottom',
        'warning'
      );
      return;
    } else {
      const progressModal = await this.modalService.create({
        component: ProgressModalComponent,
        backdropDismiss: false,
        cssClass: 'progress-modal',
      });
      await progressModal.present();
      this.integrationService.saveProducts();
    }
  }

  async openProductSelect() {
    const infoModal = await this.modalService.create({
      component: ProductSelectComponent,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
    });
    await infoModal.present();

    infoModal.onDidDismiss().then((o) => {
      o; //reserved
      //this.folioStore.update({ show: false })
    });
  }
}
