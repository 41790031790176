<ion-header>
  <ion-toolbar>
    <ion-title>{{'Detail' | transloco}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>

    <div *ngFor="let item of mainRows">

      <div>
        <ion-item>

            <ion-label [color]="item.price < 0 ? 'danger':''"
              [ngStyle]="{'text-decoration': item.recordStatus === 'deleted' ? 'line-through':''}">
              {{item.qty}} x {{item.name}}
            </ion-label>
            <ion-text [color]="item.price < 0 ? 'danger':''" style="text-align: right;">
              {{item.price | currency : '₺'}}
              <div style="font-size: 10px; text-align: right; color: rgb(26, 25, 25)">
                {{ item.creation | date: "HH:mm:ss" }} /
                {{ item.updaters[0]?.userName }}
              </div>
              <div *ngIf="item.recordStatus === 'deleted'" >
                <div *ngFor="let deleter of item.deleters" style="font-size: 10px; text-align: right; color: rgb(26, 25, 25)">
                  {{deleter.reason | transloco}}
                  {{ deleter.time | date: "HH:mm:ss" }} /
                  {{ deleter?.userName }}
                </div>
              </div>

            </ion-text>




        </ion-item>

        <!-- <ion-item *ngIf="item.recordStatus === 'deleted'">

                    <ion-note *ngFor="let deleter of item.deleters">
                        <div>
                            {{deleter.reason}}
                        </div>
                        <div>
                            {{deleter.userName}} {{deleter.time | date:'MM/dd/yyyy HH:mm'}}
                        </div>
                    </ion-note>

                </ion-item> -->
      </div>

      <div *ngFor="let subItem of subRows(item)">
        <ion-item>
          <ion-icon slot="start" name="return-down-forward-outline"></ion-icon>
          <ion-label [color]="subItem.price < 0 ? 'danger':''">
            <p style="white-space: normal;">
              {{subItem.qty}} x {{subItem.name}}
            </p>

          </ion-label>
          <ion-text [color]="item.price < 0 ? 'danger':''" style="text-align: right;">
            {{subItem.price | currency : '₺'}}
            <div style="font-size: 10px; text-align: right; color: rgb(26, 25, 25)">
              {{ item.creation | date: "HH:mm:ss" }} /
              {{ item.updaters[0]?.userName }}
            </div>
          </ion-text>
        </ion-item>
      </div>
    </div>

    <!-- <ion-item-divider></ion-item-divider> -->
    <ion-item *ngFor="let item of (reportsQuery.activeFolioPaymentRows | async)">
      <ion-label color="primary">
        {{item.name}}
      </ion-label>
      <ion-text color="primary" style="text-align: right;">
        {{item.price | currency : '₺'}}
        <div style="font-size: 10px; text-align: right; color: rgb(26, 25, 25)">
          {{ item.creation | date: "HH:mm:ss" }} /
          {{ item.updaters[0]?.userName }}
        </div>
      </ion-text>
    </ion-item>
    <!-- <ion-item-divider></ion-item-divider> -->
    <ion-accordion-group value="first" *ngIf="item.deliveryAddress" style="margin-bottom: 10px;">
      <ion-accordion value="first" style="border-radius: 5px">
        <ion-item slot="header" color="primary">
          <ion-label>{{'Address Info' | transloco}}</ion-label>
        </ion-item>
        <div class="ion-padding" slot="content">
          <div style="padding-top: 5px; padding-bottom: 5px; font-size: 13px; text-align: justify;"
            *ngIf="item.deliveryAddress !== ''">
            {{item.deliveryAddress}}</div>

          <ion-chip color="primary" (click)="openMap(item)" class="contact-item" *ngIf="isIOS && item.location">
            <ion-avatar>
              <img src="/assets/images/location.png" />
            </ion-avatar>

            <div class="contact-text">
              {{'Open Map' | transloco}} {{range(item)}}
            </div>
          </ion-chip>

          <ion-chip color="primary" (click)="openMap(item)" class="contact-item" *ngIf="!isIOS && item.location">
            <ion-avatar>
              <img src="/assets/images/location.png" />
            </ion-avatar>
            <div class="contact-text">
              {{range(item)}} - {{'Open Map' | transloco}}
            </div>
          </ion-chip>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-list>
</ion-content>


<!-- <div style="padding: 10px;color:black;">
    <ion-row class="header">
        <ion-col size="4"> {{"Item Name" | transloco}} </ion-col>
        <ion-col size="2"> {{"Qty" | transloco}}</ion-col>
        <ion-col size="3"> {{"Unit Price" | transloco}}</ion-col>
        <ion-col size="3"> {{"Total Price" | transloco}}</ion-col>
    </ion-row>

    <ion-row *ngFor="let item of (reportsQuery.folioListActiveFolio$ | async).rows; let isEven = even;let last = last"
        [ngClass]="{'gray': isEven,'last-item': last}">
        <ion-col size="4"> {{item.name}} </ion-col>
        <ion-col size="2">{{item.qty}}</ion-col>
        <ion-col size="2" style="text-align: right;"> {{item.unitPrice | currency: '₺'}}</ion-col>
        <ion-col size="3" style="text-align: right;"> {{item.price | currency: '₺'}}</ion-col>
    </ion-row>

</div> -->


<ion-button (click)="close()">{{'Close' | transloco}}</ion-button>
