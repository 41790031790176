import { Pipe, PipeTransform } from '@angular/core';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { FolioRow } from 'src/app/services/dto/orwi-folio';

@Pipe({
    name: 'folioRowTotalWithModifiers'
})
export class FolioRowTotalWithModifiersPipe implements PipeTransform {
    constructor(private folioStore: FolioStore) { }

    transform(item: FolioRow): number {
        if (item.isGift) return 0;

        const modifiers = this.folioStore
            .getValue()
            .activeFolio.rows.filter(row => row.parentID === item.id);

        const modifierTotal = modifiers.reduce((acc, modifier) => acc + modifier.unitPrice, 0);
        const total = (item.unitPrice + modifierTotal) * item.qty;

        return total;
    }
}
