<ion-app>
  <ion-menu class="menu" #mainmenu menuId="first" contentId="main">
    <ion-list lines="none" style="overflow-y: auto">
      <div style="display: flex; justify-content: flex-end">
        <div class="user">
          <ion-avatar>
            <img src="https://ionicframework.com/docs/img/demos/avatar.svg" />
          </ion-avatar>
          {{userName}}
        </div>
        <ion-icon
          (click)="mainmenu.close()"
          style="
            font-size: x-large;
            color: #184eae;
            padding: 15px;
            cursor: pointer;
          "
          name="close-outline"
        ></ion-icon>
      </div>
      <ion-item
        style="--background: #184eae; color: white"
        (click)="mainmenu.close(); openStoreModal()"
      >
        <ion-icon
          style="color: #fff"
          slot="start"
          name="business-outline"
        ></ion-icon>
        <ion-text
          >{{ "Store:" | transloco }}
          {{ (orwiStoreQuery.store$ | async)?.name }}</ion-text
        >
        <ion-icon
          style="color: #fff"
          slot="end"
          name="chevron-forward-outline"
        ></ion-icon>
      </ion-item>

      <div *ngIf="rootMenu">
        <ng-container
          [ngTemplateOutlet]="shireMenuTpl"
          [ngTemplateOutletContext]="{ menu: rootMenu }"
        ></ng-container>
      </div>

      <!-- <ion-item (click)="mainmenu.close(); navigate(item)" *ngFor="let item of rootMenu">
                <ion-icon slot="start" [name]="item.icon"></ion-icon>
                <ion-label>{{item.title}}</ion-label>
            </ion-item> -->
    </ion-list>
    <div>
      <ion-item lines="none" (click)="mainmenu.close(); logOut()">
        <ion-icon
          style="color: #184eae"
          slot="start"
          icon="log-out-outline"
        ></ion-icon>
        <ion-label style="color: #171e4e">
          {{ "Logout" | transloco }}
        </ion-label>
      </ion-item>
      <ion-item
        style="margin-bottom: 10px"
        lines="none"
        (click)="openWinServicePage()"
      >
        <ion-icon
          style="color: #184eae"
          slot="start"
          icon="download-outline"
        ></ion-icon>
        <ion-label style="color: #171e4e">
          {{ "download win service" | transloco }}
        </ion-label>
      </ion-item>
      <ion-item
        style="margin-bottom: -13px"
        class="footer-items"
        lines="none"
        (click)="reload()"
      >
        <ion-label>{{ "Version" | transloco }} {{ version }}</ion-label>
      </ion-item>
      <ion-item
        class="footer-items"
        style="margin-bottom: 0px"
        lines="none"
        (click)="copy()"
      >
        <ion-label style="font-size: 12px">{{ uuid }}</ion-label>
      </ion-item>
    </div>
  </ion-menu>

  <ion-router-outlet id="main"></ion-router-outlet>
  <div id="promptContainer" #promptContainer></div>

  <ng-template #shireMenuTpl let-menu="menu">
    <ion-accordion-group
      #accordionGroup
      value="f062-c1f0"
      class="ion-no-padding"
    >
      <ion-accordion
        [ngClass]="{ 'hide-icon': item.children.length === 0 }"
        toggleIcon="chevron-down-outline"
        [value]="item.id"
        *ngFor="let item of menu"
        [disabled]="checkPerm(item)"
      >
        <ion-item
          slot="header"
          style="--background: #f0f2fe; color: #171e4e"
          (click)="navigate(item, mainmenu)"
        >
          <ion-icon
            *ngIf="item.icon"
            slot="start"
            [name]="item.icon"
          ></ion-icon>
          <ion-label>{{ item.title | transloco }}</ion-label>
        </ion-item>
        <div style="padding-left: 10px" slot="content">
          <div *ngIf="item.children.length > 0">
            <ng-container
              [ngTemplateOutlet]="shireMenuTpl"
              [ngTemplateOutletContext]="{ menu: item.children }"
            ></ng-container>
          </div>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ng-template>
</ion-app>
