import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Folio } from '../dto/orwi-folio';
import { GlobalService } from '../global.service';
import { EcrBaseService } from './ecr.base.service';

import {
  CompleteSale,
  CustomerParty,
  IPaymentMediators,
  PaymentInformations,
  Sale,
  SaleItem,
  TransactionHandle,
} from './dto/pavo.model';
import { Injectable } from '@angular/core';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { EcrSettingService } from './ecr-setting.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class PavoService implements EcrBaseService {
  _TransactionSequence = 1;
  _TransactionHandle: TransactionHandle;
  _PaymentMediators: IPaymentMediators[] = [];

  constructor(
    private http: HttpClient,
    private glb: GlobalService,
    private folioQuery: FolioQuery,
    private ecrSetting: EcrSettingService,
    private transloco: TranslocoService
  ) {}
  async connect(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.glb.showLoading();
      // this.glb.toast('', 'Yazarkasaya bağlanılıyor...', 'bottom', 'success');
      try {
        const ecrSettings = this.ecrSetting.ecrSetting;
        const TransactionHandle = {} as TransactionHandle;
        TransactionHandle.Fingerprint = 'test_fingerprint';
        TransactionHandle.SerialNumber = ecrSettings
          ? ecrSettings.ecrSerialNo
          : localStorage.getItem('ecrSerialNo');
        TransactionHandle.TransactionSequence = this._TransactionSequence;
        TransactionHandle.TransactionDate = new Date().toISOString();

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // rejectUnauthorized: 'false',
          }),
        };

        let body = JSON.stringify({ TransactionHandle: TransactionHandle });
        console.log('body', body);
        this.http
          .post(
            `${this.httpType}://${this.ipAddress}:${this.port}/Pairing`,
            body,
            httpOptions
          )
          .subscribe(
            (data: any) => {
              this.glb.closeAllLoading();
              console.log('Pavo Connect Ecr Result:', data);
              if (data) {
                if (data.HasError === false) {
                  // this.glb.toast(
                  //   '',
                  //   'Yazarkasaya Bağlantı sağlandı',
                  //   'bottom',
                  //   'success'
                  // );
                  this._TransactionHandle = TransactionHandle;
                  this.glb.closeLoading();
                  resolve(true);
                } else {
                  console.log(data.Message);
                  // this.glb.toast('', data.Message, 'bottom', 'danger', 5000);
                  this.glb.closeLoading();
                  resolve(false);
                }
              }
            },
            (error) => {
              this.glb.closeLoading();
              let errorMessage = this.transloco.translate(
                'Could not connect to cash register. Please check the settings.'
              );
              this.glb.toast('', errorMessage, 'bottom', 'danger');

              console.log('Pavo Connect Ecr Result:', error);
              // this.glb.toast(
              //   '',
              //   JSON.stringify(error),
              //   'bottom',
              //   'danger',
              //   10000
              // );
              console.log('Pavo Connect Ecr Error:', error);
              reject(false);
            }
          );
      } catch (error) {
        alert(error);
        console.log('Connect Ecr Catch Error:', error);
        this.glb.closeLoading();
        reject(false);
      }
    });
  }

  async sale(folio: Folio, grandBankTotal?: any) {
    // this.connect()
    grandBankTotal; //reserved
    const folioTotal = this.folioQuery.total;
    const formattedSale = formatSale(
      folio,
      folioTotal,
      this._TransactionHandle
    );
    console.log(formattedSale);
    this._TransactionHandle = formattedSale.TransactionHandle;
    return new Promise((resolve, reject) => {
      this.glb.showLoading('');
      this.glb.toast('', 'Sales are in progress...', 'bottom', 'success');
      try {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // rejectUnauthorized: 'false',
          }),
        };

        let body = JSON.stringify(formattedSale);
        console.log('body', body);

        // let windowsApiServiceAddress = localStorage.getItem('terminalIp');

        this.http
          .post(
            `${this.httpType}://${this.ipAddress}:${this.port}/CompleteSale`,
            body,
            httpOptions
          )
          .subscribe(
            (data: any) => {
              this.glb.closeLoading();
              console.log('PavoSaleResult:', data);
              if (data) {
                if (data.HasError === false) {
                  if (data.Data.StatusId === 6 || data.Data.StatusId === 4) {
                    this.glb.toast(
                      '',
                      'Sales Transaction Completed',
                      'bottom',
                      'success'
                    );
                    resolve(data);
                    return;
                  } else {
                    this.glb.toast(
                      '',
                      'Sales Transaction Failed.',
                      'bottom',
                      'danger',
                      5000
                    );
                    resolve(false);
                  }
                } else {
                  this.glb.toast('', data.Message, 'bottom', 'danger', 5000);
                  resolve(false);
                }
              }
            },
            (error) => {
              this.glb.closeLoading();
              console.log('Pavo Ecr Sale Result:', error);
              this.glb.toast(
                '',
                JSON.stringify(error),
                'bottom',
                'danger',
                10000
              );
              console.log('Pavo Ecr Sale Error:', error);
              reject(false);
            }
          );
      } catch (error) {
        // alert(error);
        console.log('Ecr Sale Catch Error:', error);
        this.glb.closeLoading();
        reject(false);
      }
    });
  }

  getPaymentMediators() {
    return new Promise((resolve, reject) => {
      try {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // rejectUnauthorized: 'false',
          }),
        };

        this.http
          .post(
            `${this.httpType}://${this.ipAddress}:${this.port}/PaymentMediators`,
            {},
            httpOptions
          )
          .subscribe((res: any) => {
            this._TransactionSequence += 1;

            if (res.DATA) {
              debugger;
              console.log('getPaymentMediators', res);
              const {
                PaymentMediators,
              }: { PaymentMediators: IPaymentMediators[] } = res.DATA;
              console.log('Pavo Payment Mediators:', PaymentMediators);
              this._PaymentMediators = PaymentMediators;
              resolve(res.DATA);
            }
          });
      } catch (error: any) {
        reject(error);
      }
    });
  }
  printXReport(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // rejectUnauthorized: 'false',
          }),
        };
        this._TransactionHandle.TransactionSequence += 1;
        let body = JSON.stringify({
          ReportType: 'XReport',
          TransactionHandle: this._TransactionHandle,
        });
        this.http
          .post(
            `${this.httpType}://${this.ipAddress}:${this.port}/CreateReport`,
            body,
            httpOptions
          )
          .subscribe(
            (data: any) => {
              this.glb.closeLoading();
              console.log('Pavo XReport Result:', data);
              if (data) {
                if (data.HasError === false) {
                  this.glb.toast('', 'X Report Received', 'bottom', 'success');
                  resolve(true);
                } else {
                  this.glb.toast('', data.Message, 'bottom', 'danger', 5000);
                  resolve(false);
                }
              }
            },
            (error) => {
              this.glb.closeLoading();
              this.glb.toast(
                '',
                JSON.stringify(error),
                'bottom',
                'danger',
                10000
              );
              console.log('XReport Printing Error:', error);
              reject(false);
            }
          );
      } catch (error) {
        // alert(error);
        console.log('XReport Catch Error:', error);
        this.glb.closeLoading();
        reject(false);
      }
    });
  }
  printZReport(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // rejectUnauthorized: 'false',
          }),
        };
        this._TransactionHandle.TransactionSequence += 1;
        let body = JSON.stringify({
          ReportType: 'ZReport',
          TransactionHandle: this._TransactionHandle,
        });
        this.http
          .post(
            `${this.httpType}://${this.ipAddress}:${this.port}/CreateReport`,
            body,
            httpOptions
          )
          .subscribe(
            (data: any) => {
              this.glb.closeLoading();
              console.log('Pavo XReport Result:', data);
              if (data) {
                if (data.HasError === false) {
                  this.glb.toast('', 'X Report Received', 'bottom', 'success');
                  resolve(true);
                } else {
                  this.glb.toast('', data.Message, 'bottom', 'danger', 5000);
                  resolve(false);
                }
              }
            },
            (error) => {
              this.glb.closeLoading();
              this.glb.toast(
                '',
                JSON.stringify(error),
                'bottom',
                'danger',
                10000
              );
              console.log('XReport Printing Error:', error);
              reject(false);
            }
          );
      } catch (error) {
        // alert(error);
        console.log('XReport Catch Error:', error);
        this.glb.closeLoading();
        reject(false);
      }
    });
  }
  syncDepartments: (departments?: any) => Promise<any>;
  socketId: string;
  ipAddress: string = '';
  port: number = 0;
  httpType = 'http';

  init(ipAddress: string, port: number, _?, ssl = false): EcrBaseService {
    debugger;
    this.ipAddress = ipAddress;
    this.port = port;
    this.httpType = ssl ? 'https' : 'http';
    console.log(port, ipAddress);
    return this;
  }

  findMediatorsIdByPaymentType(localPaymentType: string): number {
    localPaymentType; //reserved
    // debugger
    // const paymentType =
    //   PavoPaymentTypesArray.find((pavoElm) => pavoElm.type == localPaymentType)
    //     ?.id ?? 1;
    // return this._PaymentMediators.find(
    //   (paymentMediator) => paymentMediator.PaymentTypeId == paymentType
    // )?.id;
    return 1;
  }
}

export function formatSale(folio: Folio, grandTotal, _TransactionHandle) {
  const completeSale = new CompleteSale();
  const sale = new Sale();
  const customerParty = new CustomerParty();
  console.log('formatSale', formatSale);
  /**
   * Sale Information
   */

  if (_TransactionHandle) _TransactionHandle.TransactionSequence += 1;
  folio.rows
    .filter((x) => x.recordStatus !== 'deleted')
    .filter((x) => x.isGift == false)
    .filter((x) => x.qty > 0)
    .filter((x) => x.price > 0)
    .filter((x) => ['product', 'modifier'].includes(x.rowType))
    .forEach((element) => {
      console.log(element);
      const item = {} as SaleItem;
      item.Name = element.name;
      item.IsGeneric = true;
      item.UnitCode = 'KGM';
      item.GrossPriceAmount = element.unitPrice * element.qty;
      item.ItemQuantity = element.qty;
      if (element.tax === 10) {
        item.TaxGroupCode = 'KDV8';
      } else if (element.tax === 1) {
        item.TaxGroupCode = 'KDV1';
      } else if (element.tax === 20) {
        item.TaxGroupCode = 'KDV18';
      } else {
        item.TaxGroupCode = 'KDV8';
      }
      item.TotalPriceAmount = element.unitPrice * element.qty;
      item.UnitPriceAmount = element.unitPrice;
      sale.AddedSaleItems.push(item);
    });

  sale.AddedSaleItems = sale.AddedSaleItems.filter((el) => el.ItemQuantity > 0);
  //INDIRIM ORAN OLARAK HESAPLANIR
  let saleTotal = parseFloat(
    grandTotal +
      folio.rows
        .filter((item) => item.rowType === 'discount')
        .reduce((sum, current) => sum + current.unitPrice, 0) *
        -1
  );
  if (folio.rows.filter((x) => x.rowType === 'discount').length > 0) {
    sale.PriceEffect.Type = 2; //SATIŞ BAZLI INDIRIM
    sale.PriceEffect.Rate = parseFloat(
      (((saleTotal - grandTotal) / saleTotal) * 100).toFixed(2)
    );
  }

  let cashPaymentTotal = folio.rows
    .filter((row) => row.rowType == 'payment' && row.paymentType == 'cash')
    .reduce((pr, acc) => pr + acc.price * -1, 0);

  if (cashPaymentTotal > 0) {
    let cashPaymentItem = {} as PaymentInformations;

    cashPaymentItem.Mediator = 1;
    cashPaymentItem.Amount = cashPaymentTotal;

    sale.PaymentInformations.push(cashPaymentItem);
  }

  folio.rows
    .filter(
      (x) =>
        x.rowType === 'payment' &&
        x.paymentType !== 'cash' &&
        x.name !== 'Change'
    )
    .forEach((element) => {
      const paymentItem = {} as PaymentInformations;

      if (element.paymentType === 'bank') {
        paymentItem.Amount = element.price * -1;
        paymentItem.Mediator = 2;
      } else if (element.paymentType === 'meal-voucher') {
        paymentItem.Amount = element.price * -1;
        paymentItem.Mediator = 3;
      } else if (element.paymentType === 'account') {
        paymentItem.Amount = element.price * -1;
        paymentItem.Mediator = 29;
      } else if (element.paymentType === 'expense-slip') {
        paymentItem.Amount = element.price * -1;
        paymentItem.Mediator = 1;
      }
      if (Object.keys(paymentItem).length > 0) {
        sale.PaymentInformations.push(paymentItem);
      }
    });

  /**
   * Customer Information
   */
  customerParty.CustomerType = 1;
  customerParty.Country = 'Türkiye';
  customerParty.City = 'İzmir';
  customerParty.District = 'Bornova';
  customerParty.FirstName = folio.customerName;
  customerParty.CompanyName = folio.customerName;
  completeSale.TransactionHandle = _TransactionHandle;
  if (folio.isRefund) {
    sale.MainDocumentType = 9;
  }
  // sale.MainDocumentType = 9;
  /**
   * Sale Information
   */
  // sale.AddedSaleItems = eItems;
  sale.GrossPrice = sale.AddedSaleItems.reduce(
    (sum, current) => sum + current.TotalPriceAmount,
    0
  );
  sale.TotalPrice = grandTotal;
  // sale.CustomerParty = customerParty;
  // sale.PaymentInformations = paymentInformations;
  completeSale.Sale = sale;

  console.log('Pavo Complete Sale: ', completeSale);
  console.log('Folio', folio);

  return completeSale;
}

/*
const SAMPLE_DATA = {
  TransactionHandle: {
    SerialNumber: 'N500Z000028',
    Fingerprint: 'test_fingerprint',
    TransactionSequence: 24,
    TransactionDate: '2022-12-16T18:26:30',
  },
  Sale: {
    RefererApp: 'Intent Harici Uygulama',
    RefererAppVersion: '1',
    MainDocumentType: 1,
    GrossPrice: 4.0,
    TotalPrice: 4.0,
    SendPhoneNotification: false,
    SendEMailNotification: true,
    NotificationPhone: '',
    NotificationEMail: 'meinfo@myinfo.dev',
    AddedSaleItems: [
      {
        Name: 'Gofret',
        IsGeneric: false,
        UnitCode: 'KGM',
        TaxGroupCode: 'KDV1',
        ItemQuantity: 2.0,
        UnitPriceAmount: 2.0,
        GrossPriceAmount: 4.0,
        TotalPriceAmount: 4.0,
      },
    ],
    CustomerParty: {
      CustomerType: 2,
      FirstName: null,
      MiddleName: null,
      FamilyName: null,
      CompanyName: 'ABC A.Ş.',
      TaxOfficeCode: '001103',
      TaxNumber: '9220036550',
      Phone: null,
      EMail: null,
      Country: 'Türkiye',
      City: 'İstanbul',
      District: 'Başakşehir',
      Neighborhood: null,
      Address: null,
    },
    ExternalPayments: [
      {
        Type: 2,
        Mediator: 9,
        Brand: 6,
        Amount: 2.0,
        CardNo: '1234567890123456',
        AuthorizationCode: '123',
      },
    ],
  },
};
*/
