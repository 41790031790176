<ion-header>
    <div class="container-header">
        <div class="left-header">
            <ion-buttons slot="start">
                <ion-button routerLink="/otp-request">
                    <ion-icon name="arrow-back"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title class="ion-text-left">{{'Orwi POS' | transloco}}</ion-title>
        </div>
        <div class="right-header">
            <div></div>
        </div>
    </div>
</ion-header>

<ion-content>
    <div class="container">
        <div class="left">
            <img src="/assets/images/login.png" alt="">
            <div style="padding: 30px;">
                <ion-button>
                    <ion-icon style="color: #184EAE;" name="help-circle-sharp"></ion-icon>
                    <p style="color: #184EAE; margin-left: 10px;">{{'Help' | transloco}}</p>
                </ion-button>
            </div>
        </div>
        <div class="right">

            <div class="form-cover">
                <form #f="ngForm" (ngSubmit)="confirmOtp()" class="login-form" style="width: 100%;">
                    <div class="form-header">
                        <span>
                            {{this.ssoService.userGsm}}
                            {{"Please enter your one-time password on your mobile phone" | transloco}}
                        </span>
                    </div>
                    <div style="padding: 10px; font-size: 14px">
                        <b>
                  {{"A one-time password has been sent to your mobile via SMS."
                  | transloco}}
                </b>
                    </div>
                    <div style="width: 100%; display: flex; justify-content: center">
                        <div style="
                    width: 100%;
                    display: flex;
                    padding: 10px;
                  ">
                            <!-- <div class="ion-activatable ripple-parent" style="
                      display: flex;
                      align-items: center;
                      padding-right: 25px;
                      padding-left: 25px;
                      color: #171E4E;
                      /* margin-right: 10px; */
                      border-right: 1px solid #184EAE66;
                      border-radius: 12px 0px 0px 12px;
                      background: white;
                      /* font-size: 12px; */
                      justify-content: center;
                      width: 40%;
                    ">
                            </div> -->
                            <div class="btn-otp">
                                <input style="height: 100%;
                                margin: 0px;
                                border-radius: 12px;
                                font-size: 32px;
                                font-weight: 500;
                                color: #171E4E;
                                width: 100%;
                                flex: 1;" [spMask]="otpMask" [spKeepMask]="false" required fixed type="tel" minlength="6" [(ngModel)]="otp" name="otp" clearInput/>
                                                        <ion-button color="secondary" style="--border-radius: 12px;
                                                        padding: 0px !important;
                                                        margin: 0;
                                                        margin-left: auto;
                                                        height: 100%;
                                                        width: 100%;
                                                        flex: 1.5;" [disabled]="resendSeconds != 0" (click)="resendOtp()">
                                                            <span *ngIf="resendSeconds != 0">{{resendSeconds}}s</span>&nbsp;{{'Resend SMS' | transloco}}
                                                            <ion-icon [size]="5" slot="start" name="refresh-circle"></ion-icon>
                                                        </ion-button>
                            </div>
                        </div>
                        <div *ngIf="error != ''" class="error-box">
                            {{error}}
                        </div>
                    </div>
                    
                    <div style="width: 97%; margin-top: 20px; margin:auto">
                        <ion-button class="btn-done" [disabled]="!f.valid" (click)="confirmOtp()" expand="block">
                            <ion-spinner *ngIf="false" slot="end" name="bubbles"></ion-spinner>
                            {{'Done' | transloco}}
                        </ion-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ion-content>



<!-- <ion-footer>
  <ion-toolbar>
   
  </ion-toolbar>
</ion-footer> -->