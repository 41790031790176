import { Component, OnInit } from '@angular/core';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { GlobalService } from 'src/app/services/global.service';
import { FoodAppCancelReason } from '../../state/dto';
import { FoodAppsIntegrationOrderService } from '../../state/food-apps-integration-order.service';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { KitchenDisplayService } from 'src/app/modules/kitchen-display/components/state/kitchen-display.service';
import { KitchenDisplayLog } from 'src/app/modules/kitchen-display/components/state/dto';

@Component({
  selector: 'food-apps-cancel-reason',
  templateUrl: './food-apps-cancel-reason.component.html',
  styleUrls: ['./food-apps-cancel-reason.component.scss']
})
export class FoodAppsCancelReasonComponent implements OnInit {
  folio: Folio
  foodAppCancelReason: FoodAppCancelReason[] = []
  selectedReason: FoodAppCancelReason = undefined
  cancelReason : string  =""
  
  constructor(
    private glb: GlobalService,
    private foodAppOrderService: FoodAppsIntegrationOrderService,
    public sQ: OrwiStoreQuery,private kitchenDisplayService : KitchenDisplayService) { }


  close() {
    this.glb.closeModal()
  }

  ngOnInit(): void {
    if (this.folio.type === 'delivery')
    {
      this.getDeleteReason()
    }   
  }

  getDeleteReason() {    
    this.foodAppOrderService.getCancelOrderReason(this.folio.id).then((x => {
      this.foodAppCancelReason = x
    }))
  }

  selectReason(item: FoodAppCancelReason) {
    this.selectedReason = item
  }

  cancelAllProducts()
  {
    this.folio.rows.forEach(element => {
       let data = {
      itemId:element.itemID,
      folioId: this.folio.id,
      id:  '',
      status: 'cancelled',}
      this.kitchenDisplayService.upsertKitchenDisplayLog( data as KitchenDisplayLog);
    });
   
  }

  async save() {
   await this.cancelAllProducts()
    if (this.selectReason == undefined) {
      this.glb.toast("Warning", "Please Select Reason for Cancellation.", "bottom", "warning")
      return
    }
    if (this.folio.type !== 'delivery')
    {
      this.selectedReason = new FoodAppCancelReason()
    }
    this.selectedReason.description = this.cancelReason
    this.glb.closeModal({
      reason: this.selectedReason
    });
  }

  calcel() {

  }




}
