import { Injectable } from '@angular/core';
import { Product, ProductGroup } from 'src/app/services/dto/orwi-product';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { AppService } from 'src/app/services/utils/app.service';
import { OrwiStoreStore } from 'src/app/modules/store/state/store.store';
import { MenuStore, SubGroups } from './menu.store';
import * as localforage from 'localforage'; // this works!!!
import { GlobalService } from 'src/app/services/global.service';
import { SessionQuery } from '../../session/state/session.query';
import { Attributes } from 'src/app/services/dto/orwi-store';
import { MenuQuery } from './menu.query';
import { arrayRemove, arrayUpdate, arrayUpsert } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import { ModifierCover } from 'kerzz-base';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { StoreService } from 'src/app/services/store/store.service';

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(
    private orwiStore: OrwiStoreStore,
    private orwiService: OrwiService,
    private appService: AppService,
    private menuStore: MenuStore,
    private glb: GlobalService,
    private sessionQuery: SessionQuery,
    private mq: MenuQuery,
    private transloco: TranslocoService,
    private publicApiService: PublicApiService,
    private storeService: StoreService
  ) {}

  async initialize() {
    await this.fetchGroups();
    await this.fetchProducts();
    await this.fetchMenu(this.orwiStore.getValue().id).then(
      (o: any) => {
        this.menuStore.update({
          products: o.products,
          allGroups: o.productGroups,
          groups: o.productGroups.filter((o) => o.parentID === '0'),
          groupSub: o.productGroups.filter((o) => o.parentID !== '0'),
          productModifiers: o.productModifiers,
          groupModifiers: o.productGroupModifiers,
        });
      },

      (err) => {
        console.error(err);
        this.menuStore.reset();
      }
    );
  }

  async selectGroup(group: ProductGroup) {
    debugger;
    for await (let elm of this.mq.allGroups) {
      elm.selected = false;
    }

    group.selected = true;
    let groupProducts;
    if (group.id === 'most-prefered') {
      groupProducts = this.menuStore
        .getValue()
        .products.filter((p) => p.isMostPreferred === true);
    } else {
      groupProducts = this.menuStore
        .getValue()
        .products.filter((p) => p.group == group.id);
    }

    if (group.parentID == '0') {
      this.menuStore.update({
        selectedGroup: group,
        activeProducts: groupProducts,
        subGroups: this.initSubGroups(group),
      });
    } else {
      this.menuStore.update({
        selectedSubGroup: group,
        activeProducts: groupProducts,
        subGroups: this.initSubGroups(group),
      });
    }

    if (group.parentID == '0') {
      this.menuStore.update((ms) => {
        ms.groups
          .filter((grp) => grp.selected && grp.id !== group.id)
          .map((o) => (o.selected = false));
      });
    }

    this.menuStore.update((ms) => {
      ms.groupSub
        .filter((grp) => grp.selected && grp.id !== group.id)
        .map((o) => (o.selected = false));
    });
  }

  serachProduct(name: string): Product[] {
    const filtired: Product[] = this.menuStore
      .getValue()
      .products.filter((p) =>
        p.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
      );
    if (name.length > 0) {
      this.menuStore.update({ activeProducts: filtired });
    } else {
      if (this.menuStore.getValue().selectedGroup) {
        this.selectGroup(this.menuStore.getValue().selectedGroup);
      } else {
        this.menuStore.update({ activeProducts: null });
      }
    }

    return filtired;
  }

  initSubGroups(group: ProductGroup) {
    let sg: SubGroups[] = [];
    const subGroups = this.menuStore
      .getValue()
      .groupSub.filter((p) => p.parentID == group.id);
    if (subGroups.length > 0) {
      if (group.parentID == '0') {
        sg.push({ parentId: '0', group: subGroups, name: group.name });
      } else {
        sg = this.menuStore.getValue().subGroups;
        sg.push({
          parentId: group.parentID,
          group: subGroups,
          name: group.name,
        });
      }
    } else {
      return group.parentID !== '0' ? this.menuStore.getValue().subGroups : sg;
    }
    return sg;
  }

  clearSub() {
    let sg: SubGroups[] = [];
    this.menuStore.update({ subGroups: sg });
  }

  async fetchMenu(id, _reload = false) {
    return new Promise(async (resolve, reject) => {
      console.log('fetchMenu');
      if (this.appService.connection == 'offline') {
        let menu: any = await localforage.getItem('menu');
        resolve(menu);
        return;
      }

      this.orwiService
        .serviceRequest(
          '/api/store/storeMenu',
          { id: id },
          this.sessionQuery.token
        )
        .subscribe((o: any) => {
          if (o.response) {
            console.log(o.response);
            let menu: any = Object.assign(o?.response);
            if (
              menu.products.filter((x) => x.isMostPreferred === true).length > 0
            ) {
              menu.productGroups.push({
                hide: false,
                id: 'most-prefered',
                kerzzID: '',
                menuId: 'default',
                order: 99,
                name: 'Favoriler',
                parentID: '0',
                soldOut: false,
              });
            }
            console.log('asdasd', menu);
            this.saveToLocalStorage(menu);
            this.storeService.fetchGroupImages(id);
            return resolve(menu);
          }

          reject(o?.error?.code);
        });
    });
  }

  fetchGroups() {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/getGroups/' + this.orwiStore.getValue().id,
          {},
          this.sessionQuery.token
        )
        .subscribe((o: any) => {
          if (o.response) {
            let menu: any = Object.assign(o?.response);
            this.menuStore.update({ storeProductGroups: menu });
            return resolve(menu);
          }

          reject(o?.error?.code);
        });
    });
  }

  fetchProducts() {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/getProducts/' + this.orwiStore.getValue().id,
          {},
          this.sessionQuery.token
        )
        .subscribe((o: any) => {
          if (o.response) {
            let products: any = Object.assign(o?.response);
            this.menuStore.update({ storeProducts: products });
            return resolve(products);
          }

          reject(o?.error?.code);
        });
    });
  }

  saveToLocalStorage(menu) {
    localforage.setItem('menu', menu);
  }

  saveProducts(product: Product, bulkUpdate = false) {
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/saveProduct/' + this.orwiStore.getValue().id,
          product,
          this.sessionQuery.token
        )
        .subscribe(async (o: any) => {
          if (o.response) {
            if (!bulkUpdate) {
              await this.updateMenu();
            }
            this.menuStore.update(({ products }) => ({
              products: arrayUpdate(products, o.response.id, o.response),
            }));
            return resolve(o.response);
          }

          reject(o?.error?.code);
        });
    });
  }

  async updateMenu() {
    // await this.syncStore();
    // this.initialize();
  }

  saveProductGroup(productGroup: ProductGroup) {
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/saveGroup/' + this.orwiStore.getValue().id,
          productGroup,
          this.sessionQuery.token
        )
        .subscribe(async (o: any) => {
          if (o.response) {
            // await this.syncStore();
            this.fetchGroups();
            this.storeService.fetchGroupImages(this.orwiStore.getValue().id);
            return resolve(o.response);
          }

          reject(o?.error?.code);
        });
    });
  }

  deleteProduct(productId: string) {
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/deleteProduct/' + this.orwiStore.getValue().id,
          {
            id: productId,
          },
          this.sessionQuery.token
        )
        .subscribe(async (o: any) => {
          if (o.response) {
            // await this.syncStore();
            this.initialize();
            return resolve(o.response);
          }

          reject(o?.error?.code);
        });
    });
  }

  deleteGroup(productGroupId: string) {
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/deleteGroup/' + this.orwiStore.getValue().id,
          {
            id: productGroupId,
          },
          this.sessionQuery.token
        )
        .subscribe(async (o: any) => {
          if (o.response) {
            // await this.syncStore();
            this.initialize();
            return resolve(o.response);
          }

          reject(o?.error?.code);
        });
    });
  }

  syncWithKerzz() {
    this.glb.showLoading();
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/menu/syncMenuFromKerzz', {
          storeId: this.orwiStore.getValue().id,
        })
        .then(
          (o: any) => {
            this.glb.closeAllLoading();
            if (o.response) {
              if (o.response.statusCode === '200') {
                this.glb.toast(
                  '',
                  this.transloco.translate('Process completed'),
                  'bottom',
                  'success'
                );
                this.glb.openAlert({
                  header: this.transloco.translate('Warning'),
                  subHeader: this.transloco.translate(
                    'Dont Forget Sync Menu With Orwi Pos'
                  ),
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: this.transloco.translate('Done'),
                    },
                  ],
                });
              } else {
                this.glb.toast('', o.response.message, 'bottom', 'danger');
              }
              return resolve(Object.assign(o).response);
            }
            this.glb.toast('', o?.error?.desc, 'bottom', 'danger');
            return reject(o?.error?.code);
          },
          (err) => {
            this.glb.toast('', err?.error?.desc, 'bottom', 'danger');
            console.error(err);
            this.glb.closeAllLoading();
          }
        );
    });
  }

  syncStore() {
    this.glb.showLoading();
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/store/syncStoreMenu', {
          id: this.orwiStore.getValue().id,
        })
        .then(
          (o: any) => {
            this.glb.closeAllLoading();
            if (o.response) {
              window.location.reload();
              return resolve(Object.assign(o).response);
            }

            return reject(o?.error?.code);
          },
          (err) => {
            console.error(err);
            this.glb.closeAllLoading();
          }
        );
    });
  }

  async getAttributes(): Promise<Attributes[]> {
    return await new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/category/getAttributes', {})
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o.response);
              // this.menuStore.update({
              //   attributes: result,
              // });
              return resolve(result);
            }

            return reject(o?.error?.code);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  addMenuTranslate(data: any) {
    let store = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/translate/saveMenuItemTranslate', {
          ...data,
          storeId: store,
        })
        .then((o: any) => {
          if (o?.response) {
            return resolve(o?.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  getMenuTranslate(data, bulkUpdate = false) {
    let store = localStorage.getItem('activeStore');
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest('/api/translate/getMenuItemTranslate', {
          ...data,
          storeId: store,
        })
        .subscribe(async (o: any) => {
          if (o?.response) {
            if (!bulkUpdate) {
              await this.updateMenu();
            }
            return resolve(o?.response);
          }

          if (Object.keys(o).length == 0) {
            return resolve({});
          }

          return reject(o?.error?.code);
        });
    });
  }

  deleteMenuTranslate(data: any): Promise<any> {
    let store = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/translate/deleteMenuItemTranslate', {
          ...data,
          storeId: store,
        })
        .then((o: any) => {
          if (o?.response) {
            return resolve(o?.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  async getModifiers(): Promise<ModifierCover[]> {
    const customModifiers = await this.getCustomModifiers();
    console.log(customModifiers);
    return await new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/menu/getModifiers/' + this.orwiStore.getValue().id,
          {}
        )
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o.response);
              // this.menuStore.update({
              //   allModifiers: result,
              // });

              return resolve([...result, ...customModifiers]);
            }

            return reject(o?.error?.code);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  saveModifiers(modifier: ModifierCover) {
    modifier.modif.forEach((elm) => {
      modifier.maxSelect = elm.maxSelect;
      modifier.minSelect = elm.minSelect;
    });
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/menu/saveModifier/' + this.orwiStore.getValue().id,
          modifier
        )
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o.response);
              if (modifier.productGroupID?.length > 0) {
                this.menuStore.update(({ groupModifiers }) => ({
                  groupModifiers: arrayUpsert(
                    groupModifiers,
                    o.response.id,
                    o.response
                  ),
                }));
              }

              if (modifier.productID?.length > 0) {
                this.menuStore.update(({ productModifiers }) => ({
                  productModifiers: arrayUpsert(
                    productModifiers,
                    o.response.id,
                    o.response
                  ),
                }));
              }

              return resolve(result);
            }

            return reject(o?.error?.code);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  async getCustomModifiers(): Promise<any[]> {
    return new Promise((resolve) => {
      this.publicApiService
        .getCollection({
          _db: this.orwiStore.getValue().store.cloudId,
          col: 'menu-product_group_modifiers',
        })
        .toPromise()
        .then((res) => {
          console.log(res);
          resolve(res);
        });
    });
  }

  saveDataActionModifier(modifier: ModifierCover) {
    modifier.modif.forEach((elm) => {
      elm.maxSelect = modifier.maxSelect;
      elm.minSelect = modifier.minSelect;
      elm.products.forEach((pr) => {
        pr.canBeAdded = elm.canBeAdded;
        pr.detachable = elm.detachable;
        pr.priceless = elm.priceless;
      });
    });
    return new Promise((resolve) => {
      this.publicApiService
        .upsert({
          _db: this.orwiStore.getValue().store.cloudId,
          col: 'menu-product_group_modifiers',
          data: modifier,
        })
        .then((res) => {
          console.log(res);
          resolve(res);
        });
    });
  }

  saveSpecialModifiers(modifier: ModifierCover) {
    let store = localStorage.getItem('activeStore');
    modifier.modif.forEach((elm) => {
      elm.maxSelect = modifier.maxSelect;
      elm.minSelect = modifier.minSelect;
      elm.products.forEach((pr) => {
        pr.canBeAdded = elm.canBeAdded;
        pr.detachable = elm.detachable;
        pr.priceless = elm.priceless;
      });
    });
    // let formatted: ModifierCover = {
    //   id: modifier.id,
    //   modif: [modifier],
    // };
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/menu/saveModifier/' + store, modifier)
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o.response);
              if (modifier.productGroupID?.length > 0) {
                this.menuStore.update(({ groupModifiers }) => ({
                  groupModifiers: arrayUpsert(
                    groupModifiers,
                    o.response.id,
                    o.response
                  ),
                }));
              }

              if (modifier.productID?.length > 0) {
                this.menuStore.update(({ productModifiers }) => ({
                  productModifiers: arrayUpsert(
                    productModifiers,
                    o.response.id,
                    o.response
                  ),
                }));
              }

              return resolve(result);
            }

            return reject(o?.error?.code);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  deleteModifiers(id: string) {
    let store = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/menu/deleteModifier/' + store, {
          id,
        })
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o.response);

              this.menuStore.update(({ groupModifiers, productModifiers }) => ({
                groupModifiers: arrayRemove(groupModifiers, id),
                productModifiers: arrayRemove(productModifiers, id),
              }));

              return resolve(result);
            }

            return reject(o?.error?.code);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  uploadImage(uploadImage: UploadImage) {
    let storeId = this.orwiStore.getValue().id;
    if (uploadImage.itemId == undefined || uploadImage.itemId == null) {
      uploadImage.itemId = storeId;
    }
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/image/saveImage/' + storeId, {
          ...uploadImage,
          storeId: storeId,
        })
        .then(
          (o: any) => {
            if (o.response) {
              this.storeService.fetchGroupImages(storeId);
              return resolve(o.response);
            }

            return reject(o.error.code);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}

export class UploadImage {
  itemType: ImageTypes;
  itemId?: string;
  imageSize?: string;
  base64: string;
  storeId?: string;
  sequence?: number;
  rootLink?: string;
}

export type ImageTypes =
  | 'product'
  | 'productGroup'
  | 'news'
  | 'store-banner'
  | 'menu-banner'
  | 'store'
  | 'address-banner'
  | 'popup';
