import { Component, OnInit, ViewChild } from '@angular/core';
import { FoodAppsIntegrationQuery } from '../../../state/food-apps-integration.query';
import { FoodAppsIntegrationService } from '../../../state/food-apps-integration.service';
import { FoodAppsIntegrationStore } from '../../../state/food-apps-integration.store';
import { arrayRemove, DirtyCheckPlugin } from '@datorama/akita';
import { FoodAppAccount, FoodAppModifier } from '../../../state/dto';
import { IonItemSliding, ModalController } from '@ionic/angular';
import * as XLSX from 'xlsx';
import { ModiferSelectComponent } from '../modifer-select/modifer-select.component';
import { SyncStoreListComponent } from '../../sync-store-list/sync-store-list.component';
import { FoodAppsSyncService } from '../../../service/food-apps-sync.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslocoService } from '@ngneat/transloco';
import { SessionQuery } from 'src/app/modules/session/state/session.query';

@Component({
  selector: 'app-modifer-sync',
  templateUrl: './modifer-sync.component.html',
  styleUrls: ['./modifer-sync.component.scss'],
})
export class ModiferSyncComponent implements OnInit {
  dirtyCheck: DirtyCheckPlugin;
  @ViewChild('slidingCase') dialog: IonItemSliding
  targetFoodAppAModifier: FoodAppModifier[] = []
  progress : boolean = false;
  constructor(
    private modalController: ModalController,
    private glb: GlobalService,
    private transloco: TranslocoService,
    private foodAppsSyncService: FoodAppsSyncService,
    private integrationService: FoodAppsIntegrationService,
    private integrationStore: FoodAppsIntegrationStore,
    public integrationQuery: FoodAppsIntegrationQuery,
    private sessionQuery : SessionQuery) {

  }

  ngOnInit() {
    this.dirtyCheck = new DirtyCheckPlugin(this.integrationQuery, { watchProperty: 'foodAppModifiers' })
    // this.integrationService.syncProduct()
    setTimeout(() => {
      this.showSlider()
    }, 500);
  }

  getName(id) {
    if (id == '') {
      return ""
    } else {
      let nd = this.integrationQuery.restaurantModifiers.find(o => o.id == id)
      if (nd) {
        return nd.name
      } else {
        return ''
      }

    }

  }

  async syncBestMatch()
  {
    await this.integrationService.syncModifiers()
  }


  deleteRow(slidingItem: any, modifier: FoodAppModifier) {
    slidingItem.close();
    this.glb.openAlert({
      header: this.transloco.translate('Delete'),
      subHeader:
      modifier.name + ' isimli varyasyon eşleşme listesinden silinecektir?',
      backdropDismiss: false,
      buttons: [
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            this.integrationService.deleteModifier(modifier).then((x) => {
              x
              this.integrationStore.update(({ foodAppModifiers }) => ({
                foodAppModifiers: arrayRemove(foodAppModifiers, modifier.id),
              }));
              this.glb.toast(
                'Successfully deleted',
                '',
                'bottom',
                'success'
              );
            });
          },
        },
        {
          text: this.transloco.translate('No'),
        },
      ],
    });
  }



  showSlider() {

    if (!this.dialog) return;
    //if (this.glb.listItemSlidingHelper) return;
    setTimeout(() => {
      this.dialog.open("start")
      setTimeout(() => {
        this.dialog.open("end")
      }, 1000);
      setTimeout(() => {
        this.dialog.close()
      }, 1500);

    }, 1);
    //this.glb.listItemSlidingHelper = true
  }


  syncSelect(item: FoodAppModifier) {
    this.integrationStore.update({ activeFoodAppModifier: item })
    this.openModifierSelect()
  }
  exportexcel(): void {
    const newArray = this.integrationQuery.foodAppModifiers.map(({ accountId, id, price, type, changed, auto, productId, ...keepAttrs }) => keepAttrs)
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newArray);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'modifiers.xlsx');
  }

  async selectAccount() {
    const printerModal = await this.glb.openModal({
      component: SyncStoreListComponent,
      cssClass: 'list-modal',
    });
    printerModal.onDidDismiss().then(async (res) => {
      let msg = this.transloco.translate(`Products of {{store}} will get synchronized with the selected stores. Do you accept?`,
      {
        store: this.sessionQuery.activeLicense.orwiStore.name,
      })
      console.log(res)
      await this.glb.openAlert({
        header: this.transloco.translate("Variation Synchronization (WARNING!!)"),
        message: msg,
        buttons: [
          this.transloco.translate('No'),
          {
            text: this.transloco.translate('Yes'),
            handler: () => {
              if (res.data.length > 0) {
                res.data.forEach(element => {
                  this.foodAppsSyncService.getFoodAppModifier(element.cloudId, element.id, element.foodApp).then(x => {
                    this.targetFoodAppAModifier = x
                    this.confirmSync(element)
                  })
                });
              }
            },
          },
        ],
      })

    })
  }

  async confirmSync(foodAppAccount: FoodAppAccount) {
    console.log(this.targetFoodAppAModifier, this.integrationQuery.foodAppModifiers)

    this.targetFoodAppAModifier.map(x => { x.changed = false; })
    this.targetFoodAppAModifier.map(x => {
      if (this.integrationQuery.foodAppModifiers.filter(sp => sp.id == x.id).length > 0) {
        x.internalId = this.integrationQuery.foodAppModifiers.find(f => f.id == x.id && f.source == x.source).internalId || ''
        x.changed = true
      }
    })
    this.progress = true
   this.foodAppsSyncService.syncModifiers(this.targetFoodAppAModifier,foodAppAccount.cloudId).then(x=> {
    x//reserved
    this.progress = false;
   })
  }

  deleteMatch(slidingItem: any,modifier : FoodAppModifier)
  {
    slidingItem.close()
    this.integrationQuery.foodAppModifiers.find(x=> x.id == modifier.id).changed = true
    this.integrationQuery.foodAppModifiers.find(x=> x.id == modifier.id).internalId = ""
  }

  save() {
    this.integrationService.saveModifiers()
  }

  async openModifierSelect() {
    const infoModal = await this.modalController.create({
      component: ModiferSelectComponent,
      initialBreakpoint: 1,
      breakpoints: [0, 1]
    });
    await infoModal.present();

    infoModal.onDidDismiss().then(o => {
      o//reserved
    })
  }
}
