<orwi-modal-header
  [title]="
    (modalType == ChangeCombineModalType.Change
      ? 'Table Change'
      : 'Table Combine'
    ) | transloco
  "
  (onClose)="close()"
></orwi-modal-header>

<ion-content [scrollEvents]="true">
  <div class="container">
    <div class="left">
      <open-table-folios-select
        (onSelectTables)="onSelectTables($event)"
        style="color: #171e4e"
      ></open-table-folios-select>
    </div>
    <div class="right">
      <orwi-table-list
        (onSelect)="selectTargetTable($event)"
        [ShowType]="modalType"
        [movedTable]="selectedTable"
      ></orwi-table-list>
    </div>
  </div>
</ion-content>
<ion-footer>
  <div class="buttons">
    <ion-button
      class="button"
      [disabled]="ifSelectedTables()"
      (click)="moveTablesToTargetTable()"
      style="--background: #4cd9ed; margin: 10px"
      >{{
        (modalType == ChangeCombineModalType.Change ? "Change" : "Combine")
          | transloco
      }}</ion-button
    >
  </div>
</ion-footer>
