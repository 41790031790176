import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SocketKerzz, SocketOrwi } from 'src/app/app.module';
import { BehaviorSubject } from 'rxjs';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { GlobalService } from '../global.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class SocketService {


  changedCollection$ = new BehaviorSubject<any>(undefined)
  message = '';
  messages = [];
  currentUser = '';
  connected = false

  constructor(
    private sessionQuery: SessionQuery,
    public socket: SocketOrwi,
    public kerzzSocket: SocketKerzz,
    private toastCtrl: ToastController,
    private glb: GlobalService
  ) {

    this.kerzzSocket.on("wellcome", () => {
      console.log("kerzz-socket-welcome")

      this.sessionQuery.user$.subscribe(o => {
        if (o) {
          this.login()
        }
      })

    })

    this.checkSocketStatus()





    // this.kerzzSocket.on("pong", (e) => {
    //   console.log("pingListener", e)
    // })



    // this.kerzzSocket.emit('test', {})




    this.socket.on("test", (e) => {
      if (e === 'POS TEST OK') {
        this.glb.socketStatus = 'connected'
      }
      else {
        this.glb.socketStatus = 'offline'
      }
    })

    this.kerzzSocket.on("login", (o) => {
      console.log("kerzz-socket-login", o)


      this.kerzzSocket.emit("subscribe-collection", { dbName: this.sessionQuery.activeLicense.orwiStore.cloudId, collection: 'pos-parameters' })
      this.kerzzSocket.emit("subscribe-collection", { dbName: this.sessionQuery.activeLicense.orwiStore.cloudId, collection: 'geo-locations' })
      this.kerzzSocket.emit("subscribe-collection", { dbName: this.sessionQuery.activeLicense.orwiStore.cloudId, collection: 'geo-locations-live' })
      this.kerzzSocket.emit("subscribe-collection", { dbName: this.sessionQuery.activeLicense.orwiStore.cloudId, collection: 'pos-folios' })
      this.kerzzSocket.emit("subscribe-collection", { dbName: this.sessionQuery.activeLicense.orwiStore.cloudId, collection: 'food-apps-stores' })
      this.kerzzSocket.emit("subscribe-collection", { dbName: this.sessionQuery.activeLicense.orwiStore.cloudId, collection: 'food-apps-notifications' })




      this.kerzzSocket.on("changed-collection", c => {
        let log = "***service-changed-collection " +
          " SC:" + this.kerzzSocket.ioSocket.id +
          " OP:" + c.operationType +
          " DB:" + c.ns.db +
          " CL:" + c.ns.coll
        if (c.fullDocument) {
          if (c.fullDocument.storeId) {
            log += " ST:" + c.fullDocument.storeId
          }
          if (c.fullDocument.id) {
            log += " ID:" + c.fullDocument.id
          }
        }
        this.changedCollection$.next(c)
      })
    })




    // this.kerzzSocket.fromEvent("wellcome").subscribe(o => {
    //   debugger
    //   console.log("kerzz-socket-wellcome", o)


    //   this.login()

    //   setTimeout(() => {



    //     this.kerzzSocket.on("*",function(event,data) {
    //       console.log(event);
    //       console.log(data);

    //     this.kerzzSocket.on("*", (a,b) => {
    //       console.log(a,b)
    //     })

    //   }, 5000);

    // })

    this.kerzzSocket.on("error", (e) => {
      console.log("kerzz-socket-error", e)
    })

    this.kerzzSocket.on("reconnect_attempt", () => {
      console.log("kerzz-socket-reconnect_attempt")
    })


    this.kerzzSocket.connect()




    /*   this.appService.connection$.subscribe(o => {
        if (o == "offline") {
          this.socket.disconnect()
        } else {
          this.socket.connect()
        }
       }) */

  }




  login() {
    let idt = {
      alias: "ali-yimaz",
      secretKey: "14531453",

    }
    this.kerzzSocket.emit("login", JSON.stringify(idt))
  }

  checkSocketStatus() {
    this.glb.socketStatus = 'connecting'
    this.socket.emit('test', { uuid: this.glb.DeviceUUID, storeId: localStorage.getItem('activeStore') || '' })
    setTimeout(() => {
      if (this.glb.socketStatus === 'connecting') {
        this.glb.socketStatus = 'offline'
      }

      if (Capacitor.isNativePlatform()) {
        if (this.glb.socketStatus === 'offline') {
          this.glb.toast('Something went wrong', 'Please refresh the page or check your internet connection. If the problem is not resolved, contact us.', 'bottom', 'danger')
          this.glb.playSocketError()
        }
      }



    }, 10 * 1000);
  }


  async showToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }
}


export interface CollectionChangedResponse {
  ns: { db: string, coll: string }
  fullDocument: any
  updateDescription: { updatedFields: any[], truncatedArrays: any[], removedFields: any[] }
  operationType: "update" | 'insert' | 'delete'
  clusterTime: any
  documentKey: any
  wallTime: Date
}
