<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button icon="/assets/icons/back-btn.svg"></ion-back-button>
    </ion-buttons>
    <div class="header-center">
      <ion-title>{{ "Orwi Integration" | transloco }}</ion-title>

      <!-- <ng-container *ngIf="storeQuery.store$ | async; let store" style="margin-top: 15px; margin-bottom: 20px">
        <ion-item lines="none" style="width: 330px">
          <ion-label> {{ "Current Store" | transloco }}: </ion-label>
          <ion-text>
            {{ store.name }}
          </ion-text>
          <ion-button size="small" fill="clear" (click)="select()" style="width: unset !important">
            <ion-icon slot="icon-only" name="list-outline"></ion-icon>
          </ion-button>
        </ion-item>
      </ng-container> -->
      <ion-buttons slot="end">
        <ion-button style="width: 233px!important;" (click)="foodAppsLogs()">
          <p>{{ "Food App Logs" | transloco }}</p>
          <ion-icon slot="icon-only" name="document-text-outline"></ion-icon>
        </ion-button>
        <ion-button style="width: 233px!important;" (click)="bulkSyncProduct()">
          <p>{{ "Bulk Product Sync" | transloco }}</p>
          <ion-icon slot="icon-only" name="sync-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="add()">
          <p>{{ "add new" | transloco }}</p>
          <ion-icon slot="icon-only" name="add-outline"></ion-icon>
        </ion-button>
        <!-- <ion-button style="width: 233px!important;" (click)="bulkModifierSync()">
          <p>{{ "Bulk Modifier Sync" | transloco }}</p>
          <ion-icon slot="icon-only" name="sync-outline"></ion-icon>
        </ion-button> -->
      </ion-buttons>

    </div>
<!--
    <ion-buttons slot="end">
      <ion-button (click)="add()">
        <p>{{ "add new" | transloco }}</p>
        <ion-icon slot="icon-only" name="add-outline"></ion-icon>
      </ion-button>
    </ion-buttons> -->
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-list-header *ngIf="(integrationQuery.foodAppAccount$ | async).length > 0">
      {{ "Integration List" | transloco }}
    </ion-list-header>

    <ion-item *ngIf="(integrationQuery.foodAppAccount$ | async).length === 0">
      {{ "No assigned integrations yet!" | transloco }}
    </ion-item>

    <div style="margin-top: 15px;">
      <ion-card *ngFor="let item of integrationQuery.foodAppAccount$ | async">
        <ion-card-content>
          <ion-card-title style="display: flex; align-items: center; color: #171e4e">
            <ion-button fill="clear" size="small" (click)="editAccount(item)">
              <ion-icon slot="icon-only" name="create-outline" style="color: #184eae"></ion-icon>
            </ion-button>
            {{ item.foodApp }} ({{ getBrand(item) }})</ion-card-title>
          <ion-card-subtitle style="padding-left: 50px">{{
            item.description
            }}</ion-card-subtitle>

          <div style="width: 100%; padding-left: 45px">
            <!-- <div style="width: 100%; display: flex; justify-content:space-between">
                                <ion-label>
                                    <h3>

                                    </h3>
                                    <p style="color: red">


                                    </p>
                                </ion-label>

                            </div> -->
            <div style="display: flex; margin-top: 10px">
              <ion-button style="color: #171e4e; text-transform: none;" fill="clear" size="small"
                (click)="syncProducts(item)">
                <ion-icon style="color: #184eae; margin-bottom: 3px;" slot="start" name="fast-food-outline"></ion-icon>
                {{ "Product" | transloco }}</ion-button>
              <ion-button style="color: #171e4e; text-transform: none;" fill="clear" size="small"
                (click)="syncModifiers(item)">
                <ion-icon style="color: #184eae; margin-bottom: 3px;" slot="start" name="git-merge-outline"></ion-icon>
                {{ "Variation" | transloco }}</ion-button>

              <ion-toggle [disabled]="!ssoService.checkPermission('integrations-edit')" (ionChange)="changeStatus($event, item)" [checked]="item.isActive"></ion-toggle>

            </div>
            <div  style="display: flex;align-content: space-between;">
              <div style="cursor: pointer;margin-right: 15px " (click)="showFoodAppMessage(item,'syncOrderStatus')">
                {{'orderSync' | transloco}} : <img style="width:20px" *ngIf="item.syncOrderStatus !== 'success'"
                  src="assets/icon/error.gif"> <img style="width:20px" *ngIf="item.syncOrderStatus === 'success'"
                  src="assets/icon/success.gif">
              </div>
              <div  style="cursor: pointer;margin-right: 15px " (click)="showFoodAppMessage(item,'syncStoreStatus')">
                {{'storeSync' | transloco}} : <img style="width:20px" *ngIf="item.syncStoreStatus !== 'success'"
                  src="assets/icon/error.gif"> <img style="width:20px" *ngIf="item.syncStoreStatus === 'success'"
                  src="assets/icon/success.gif">
              </div>
              <div  style="cursor: pointer;" (click)="showFoodAppMessage(item,'syncMenuStatus')">
                {{'menuSync' | transloco}} : <img style="width:20px" *ngIf="item.syncMenuStatus !== 'success'"
                  src="assets/icon/error.gif"> <img style="width:20px" *ngIf="item.syncMenuStatus === 'success'"
                  src="assets/icon/success.gif">
              </div>
            </div>

          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </ion-list>
</ion-content>
