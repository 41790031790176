import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { EftPosListComponent } from 'src/app/modules/folio/components/eft-pos-list/eft-pos-list.component';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { Folio } from '../dto/orwi-folio';
import { GlobalService } from '../global.service';
@Injectable({
  providedIn: 'root',
})
export class EftPosService {
  constructor(private glb: GlobalService,
    private transloco: TranslocoService,
    private sessionQuery: SessionQuery,
    private folioService: FolioService,
    private modalController: ModalController,
    private FolioQuery: FolioQuery
  ) {
  }

  async sendFolioToEftPos(folio: Folio, navigate?: boolean) {
    await this.glb.openAlert({
      header: this.transloco.translate('TSM'),
      message: this.transloco.translate('Do you confirm the EFT transaction?'),
      buttons: [
        {
          text: this.transloco.translate('No'),
          role: 'cancel',
        },
        {
          text: this.transloco.translate('Yes'),
          handler: async () => {
            folio.eftPos = true;
            folio.lock.status = 'unlocked';
            folio.lock.time = moment().toDate();
            folio.lock.userID = this.sessionQuery.user.id;
            folio.lock.userName = this.sessionQuery.user.name;
            this.folioService._posSaveFolio(folio).then(async (x: Folio) => {
              await this.glb.openAlert({
                header: this.transloco.translate('TSM'),
                message: this.transloco.translate('eftSentMessage', { sequenceNo: x.sequenceNo }),
                buttons: [
                  {
                    text: this.transloco.translate('Ok'),
                    handler: async () => {
                      if (navigate) {
                        this.glb.navigateHomeForm();
                      }
                    },
                  },
                ],
              });
            });
          },
        },
      ],
    });
  }


  async backFolioFromEftPos(folio: Folio) {
    //TODO backend tarafı bekleniyor.
    folio.eftPos = false;
    folio.lock.status = "unlocked";
    await this.folioService._posSaveFolio(
      folio,
      'high'
    );
  }

  async openEftPosList() {
    const modal = await this.modalController.create({
      component: EftPosListComponent,
      backdropDismiss: true,
      componentProps: { eftPosList: this.FolioQuery.getValue().openFolios.filter(x => x.eftPos == true) },
    });
    await modal.present();
    return modal;
  }
}
