import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BrandsStore, BrandsState } from './brands.store';
import { Brand } from 'kerzz-base';
import { map } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class BrandsQuery extends Query<BrandsState> {

  brands : Brand[] = []
  brand$ = this.select("brands")
  activeBrand: Brand
  constructor(protected store: BrandsStore) {
    super(store);

    this.select("brands").subscribe(o => {
      if (o) this.brands = o;
    })

    this.select("activeBrand").subscribe(o => {
      if (o) this.activeBrand= o
    })


  }

  activeBrand$ = this.select("brands").pipe(
    map(o => o.find(brand => brand.isDefault)),
  )

  getBlank() {
    let brand: Brand = {
      id: '',
      name: '',
      image: '',
      isDefault : false,
      createUser : '',
      creationDate : new Date(),
      lastEditDate : new Date(),
      lastEditUser : '',
      storeId : ''

    }
    return brand
  }

}
