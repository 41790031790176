import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { MyMaskUtil } from 'src/app/helpers/directives/mask-util';
import { CountryCodesService } from 'src/app/services/location/country-codes.service';
import { GlobalService } from 'src/app/services/global.service';
import { SSOSessionService } from '../../state/sso-session.service';
import { PosCountry } from '../../state/dto/sso-dto';

@Component({
  selector: 'app-otp-request',
  templateUrl: './otp-request.page.html',
  styleUrls: ['./otp-request.page.scss'],
})
export class OtpRequestPage implements OnInit {

  id = "login"

  gsm
  country: PosCountry


  public phoneValue01: string = '1231234567';
  public phoneValue02: string;
  public phoneMask01 = MyMaskUtil.PHONE_MASK_GENERATOR;
  public phoneMask02 = MyMaskUtil.DYNAMIC_PHONE_MASK_GENERATOR;
  public crediCardMask = MyMaskUtil.CREDIT_CARD_GENERATOR;


  constructor(
    private ssoService: SSOSessionService,
    public countryService: CountryCodesService,
    private nav: NavController,
    private plt: Platform,
    private glb: GlobalService, private translate: TranslocoService) { }



  ngOnInit() {
    this.glb.showLoading("")
    if (this.plt.ready) {

      this.countryService.getCountries().then((o: any) => {
        this.countryService.activeCountry = o.find(c => c.code == this.ssoService.userRegion)
        if (!this.countryService.activeCountry) {
          this.countryService.activeCountry = o.find(c => c.code == "TR")
        }
        this.glb.closeLoading()
        setTimeout(() => {
          this.glb.closeAllLoading()
        }, 500);
      })

    }


  }

  ionViewDidEnter() {
    setTimeout(() => {
      this.glb.closeAllLoading()
    }, 2000);
  }


  countryList() {
    this.nav.navigateForward('select-country')
  }

  requestOtp() {

    let gsm: string = this.gsm.replace(" ", "")
    gsm = gsm.replace("(", "")
    gsm = gsm.replace(")", "")
    gsm = gsm.replace("-", "")

    if (gsm.substr(0, 1) == "0") {
      let msg = this.translate.translate("Invalid phone number")
      this.glb.toast(msg, "", "bottom", "danger", 2000)
      return
    }

    this.ssoService.userGsm = this.countryService.activeCountry.dialCode + gsm

    this.glb.showLoading("")

    this.ssoService.requestOtp(this.ssoService.userGsm).then(o => {
      console.log(o)

      this.glb.closeLoading()
      this.nav.navigateForward("otp-verify")
    }, e => {
      this.glb.closeAllLoading()
      if (e.error.message) {
        this.glb.toast("Error", e.error.message, "bottom", "warning")
      } else if (e.message) {
        this.glb.toast("Error", e.message, "bottom", "warning")
      } else {
        this.glb.toast("Error", "An error occured", "bottom", "warning")
      }
    })
  }







}
