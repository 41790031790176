import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { arrayRemove, arrayUpsert } from '@datorama/akita';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { FoodAppsIntegrationService } from 'src/app/modules/food-apps-integration/state/food-apps-integration.service';
import { ParametersQuery } from 'src/app/modules/parameters/state/parameters.query';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { Folio } from '../dto/orwi-folio';
import { GlobalService } from '../global.service';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root',
})
export abstract class SoketPushDeeplinkRoutingService {
  folioChangedEvent = new BehaviorSubject<Folio>(undefined);
  deliveryFolioReceivedEvent = new BehaviorSubject<Folio>(undefined);

  sbscbrFolioChanged: Subscription;
  sbscbrFolioClosed: Subscription;

  constructor(
    private sessionQuery: SessionQuery,
    public socketService: SocketService,
    private folioStore: FolioStore,
    private glb: GlobalService,
    private ro: Router,
    private nav: NavController,
    private parametersQuery: ParametersQuery,
    private foodAppService: FoodAppsIntegrationService
  ) {
    if (this.sbscbrFolioChanged) this.sbscbrFolioChanged.unsubscribe();
    if (this.sbscbrFolioClosed) this.sbscbrFolioClosed.unsubscribe();

    this.socketService.socket.fromEvent('disconnect').subscribe((o) => {
      console.log('disconnect', o);
    });

    this.socketService.socket.fromEvent('error').subscribe((o) => {
      console.log('socket-error', o);
    });

    this.socketService.socket.fromEvent('reconnect').subscribe((o) => {
      console.log('reconnect', o);
    });

    this.socketService.socket.fromEvent('reconnect_error').subscribe((o) => {
      console.log('reconnect_error', o);
    });

    this.socketService.socket.fromEvent('reconnect_failed').subscribe((o) => {
      console.log('reconnect_failed', o);
    });

    this.socketService.socket.fromEvent('ping').subscribe((o) => {
      console.log('ping', o);
    });

    this.socketService.socket.fromEvent('url-redirect').subscribe((o: any) => {
      console.log('socket redirect-url', o);
      let socketMsg: POSClientMessage = JSON.parse(o)
      console.log(socketMsg.data.url)
       window.location.href = socketMsg.data.url
    });

    this.socketService.socket.fromEvent('url-reload').subscribe((o) => {
      console.log('socket url-reload', o);
      window.location.href = window.location.href.replace(/[\?#].*|$/, '#');
      window.location.reload();
    });

    // this.socketService.socket.emit('redirect-url', { uuid: this.glb.DeviceUUID, storeId: localStorage.getItem('activeStore') || '' })


    this.sbscbrFolioChanged = this.socketService.socket
      .fromEvent('folio-changed')
      .subscribe((o: string) => {
        // console.log("folio-changed", o)
        let pcm: POSClientMessage = JSON.parse(o);
        this.route({
          type: 'folio-changed',
          socketData: pcm.data,
          posClientMessage: pcm,
        });
      });

    this.sbscbrFolioClosed = this.socketService.socket
      .fromEvent('folio-closed')
      .subscribe((o: string) => {
        let pcm: POSClientMessage = JSON.parse(o);
        this.route({
          type: 'folio-closed',
          socketData: pcm.data,
          posClientMessage: pcm,
        });
      });

    // this.socketService.socket.fromEvent("folio-received").subscribe(o => {
    //   // console.log("folio-received", o)
    //   this.route({ type: "folio-received", socketData: o })
    // })

    this.socketService.socket
      .fromEvent('delivery-folio-received')
      .subscribe((o: string) => {
        let pcm: POSClientMessage = JSON.parse(o);
        this.route({
          type: 'delivery-folio-received',
          socketData: pcm.data,
          posClientMessage: pcm,
        });
      });

    console.log('delivery-folio-received listening ');

    this.socketService.socket.fromEvent('login').subscribe((o) => {
      o; //reserved
      // console.log('Orwi Socket Login',o,this.socketService.socket.ioSocket.id)
      console.log('orwi socket login', o)
      this.glb.socketId = this.socketService.socket.ioSocket.id;
    });
  }

  initEvents(userToken) {
    console.log('initSocket', userToken, this.socketService)
    this.socketService.socket.fromEvent('connect').subscribe((o) => {
      o; //reserved
      console.log('orwi socket fromEvent connect', o)
      this.socketService.connected = true;
    });
    this.socketService.socket.fromEvent('welcome').subscribe((o) => {
      console.log('orwi socket', o)
      this.socketService.connected = true;
      o; //reserved
      this.socketService.socket.emit('login', userToken);
    });
  }




  route(param: orwiRouteParam) {
    // console.log(param)
    if (
      this.sessionQuery.activeLicense.orwiStore.id == param.socketData.storeId
    ) {
      if (param.type == 'folio-changed') this.applyFolioChanged(param);
      if (param.type == 'folio-closed') this.applyFolioClosed(param);
      // if (param.type == "folio-received") this.applyFolioReceived(param)
      // if (param.type == "store-menu-changed") this.applyStoreMenuChanged(param)
      if (param.type == 'delivery-folio-received')
        this.deliveryFolioReceived(param);
    }
  }

  async applyFolioClosed(param) {
    // console.log(this.folioStore.getValue().openFolios)
    this.folioStore.update(({ openFolios }) => ({
      openFolios: arrayRemove(openFolios, param.socketData.id),
    }));

    this.folioStore.update({ socketClosed: param.socketData });

    if (this.ro.url == '/phone-folio' || this.ro.url == '/pos-payment') {
      let ac = this.folioStore.getValue().activeFolio;
      if (ac.id == param.socketData.id) {
        await this.glb.toast(
          'Warning',
          'This ticket has been closed',
          'bottom',
          'warning',
          3000
        );
        setTimeout(() => {
          this.nav.pop();
        }, 3000);
      }
    }
  }

  // async applyFolioReceived(param: orwiRouteParam) {

  //   this.folioStore.update(({ openFolios }) => ({
  //     openFolios: arrayUpsert(openFolios, param.socketData.id, param.socketData)
  //   }));

  // }

  async deliveryFolioReceived(param: orwiRouteParam) {
    this.folioStore.update(({ openFolios }) => ({
      openFolios: arrayUpsert(
        openFolios,
        param.socketData.id,
        param.socketData
      ),
    }));
    console.log('deliveryFolioReceived', param);
    this.deliveryFolioReceivedEvent.next(param.socketData);
    console.log('deliveryFolioReceived socket response', param);
    // this.foodAppService.printProcess(param.socketData).then((o: any) => {
    //   console.log('PrintProcess', o)

    // })
    if (this.parametersQuery.getValue().posParameters.isMaster) {
      if (
        this.sessionQuery.activeLicense.orwiStore.id ===
        param.socketData.storeId
      ) {
        this.glb.playDeliveryOrderReceived();
        console.log('params', this.parametersQuery);

        let pc: POSClientMessage = {
          senderId: param.posClientMessage.reciverId,
          reciverId: param.posClientMessage.senderId,
          event: 'delivery-folio-received',
          data: { id: param.socketData.id },
        };

        console.log('deliveryFolioRecieved', param.socketData);

        console.log('foodAppService status', this.foodAppService);
        this.foodAppService.printProcess(param.socketData).then((o: any) => {
          console.log('printProcess', o);
          if (o?.error) {
            pc.event = 'error';
            pc.data = o.error;
            this.socketService.socket.emit('send-client', JSON.stringify(pc));
          } else {
            this.socketService.socket.emit('send-client', JSON.stringify(pc));
          }
        });
      }
    }
  }

  applyFolioChanged(param: orwiRouteParam) {
    this.folioStore.update(({ openFolios }) => ({
      openFolios: arrayUpsert(
        openFolios,
        param.socketData.id,
        param.socketData
      ),
    }));

    if (this.folioStore.getValue().activeFolio?.id == param.socketData.id) {
      this.folioStore.update({ activeFolio: param.socketData });
    }

    this.folioChangedEvent.next(param.socketData);
  }

  applyStoreMenuChanged(param: orwiRouteParam) {
    if (param.socketData) {
      /*
            let p = param.socketData.data.product
            let pg = param.socketData.data.product
            let menu = param.socketData.data.product

            if (p) {
              let i = this.ss.store.products.findIndex(xx => xx.id == p.id)
              this.ss.store.products[i] = p
            }

            if (pg) {
              let i = this.ss.store.productGroups.findIndex(xx => xx.id == pg.id)
              this.ss.store.productGroups[i] = pg

            } */
    }
  }
}

//folio-received - kerzz tarafından oluşturalın adisyonun kullanıcıya gönderilmesi.
//hem push & hem socket olmalı
//parametre  push:data.orwi.folioId // socket:[foioId]

//folio-closed - adisyon kerzz tarafında kapandığında yada iptal olduğunda oluşuyor
//hem push & hem socket olmalı
//parametre  push:data.orwi.folioId // socket:[foioId]

//folio-changed - adisyon kerzz'den değiştirilirse oluşuyor
//hem push & hem socket olmalı
//parametre  push:data.orwi.folioId // socket:data:FolioRows (changed)

//user-points-changed - herhangi bir şekilde puan değişirse oluşuyor
//sadece socket
//paremetre socket: points
//user refresj et

//store-menu-changed o storeda değişlik varsa
//sadece socket
//paremetre socket: menu

//push folio-received | folio-closed | folio-changed | user-points-changed

//user-points-changed
//folio-changed
//store-menu-changed
//folio-closed
//folio-received

export interface orwiRouteParam {
  type: routeType;
  socketData?: any;
  pushData?: any;
  posClientMessage: POSClientMessage;
}

export type routeType =
  | 'folio-received'
  | 'folio-changed'
  | 'folio-closed'
  | 'user-points-changed'
  | 'store-menu-changed'
  | 'delivery-folio-received';

export class POSClientMessage {
  senderId = '';
  reciverId = ''; //storeId or socketId
  event = '';
  data: any | undefined;
}
