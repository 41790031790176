import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { ITable } from 'src/app/services/dto/tables';

@Component({
  selector: 'select-table-folio',
  templateUrl: './select-table-folio.component.html',
  styleUrls: ['./select-table-folio.component.scss'],
})
export class SelectTableFolioComponent implements OnInit {
  _includedFolioIds = [];
  @Input() table: ITable;
  @Input() set excludedFolioIds(e: any[]) {
    console.log(e, this.table.folios);
    if (e && Array.isArray(e)) {
      this._includedFolioIds = [
        ...this.table.folios
          .filter((f) => !e.includes(f.id))
          .map((el) => el.id),
      ];
    }
  }
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  dismiss(value: Folio | null = null) {
    this.modalCtrl.dismiss(value);
  }
  onRowClick(e: Folio) {
    this.dismiss(e);
  }
}
