import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IonAccordionGroup,
  IonMenu,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { ZReportFormComponent } from './modules/folio/components/z-report-form/z-report-form.component';
import { GlobalService } from './services/global.service';
import { AppService } from './services/utils/app.service';
import { OrwiStoreQuery } from './modules/store/state/store.query';
import { environment } from 'src/environments/environment';
import { SuppressLongpressGesture } from 'capacitor-suppress-longpress-gesture';
import { EcrService } from './services/ecr/ecr.service';
import { EcrSettingsComponent } from './components/ui/ecr-settings/ecr-settings.component';
import { SSOSessionService } from './modules/session/state/sso-session.service';
import { ModalService } from './services/helpers/modal.service';
import { SessionQuery } from './modules/session/state/session.query';
SuppressLongpressGesture.activateService();
import config from 'devextreme/core/config';
import { OrwiService } from './services/orwi/orwi.service';
import * as trMessages from 'devextreme/localization/messages/tr.json';
import { locale, loadMessages } from 'devextreme/localization';
import { SocketService } from './services/helpers/socket.service';
import { PosDevice } from './services/dto/deviceRegister.model';
import { Capacitor } from '@capacitor/core';
import { Title } from '@angular/platform-browser';
import { CapacitorPresentation } from 'presentation-capacitor';
import { RxDBService } from './services/rxDB.service';

var that;

config({
  defaultCurrency: 'TRY',
});
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  version = '';
  uuid = '';
  languages = this.transloco.getAvailableLangs() as any[];
  selectedLang = this.transloco.getActiveLang();

  shireMenu: ShireMenu[];
  rootMenu: ShireMenu[];
  subMenu = [];
  userName: string = 'unknown user';
  @ViewChild('accordionGroup', { static: true })
  accordionGroup: IonAccordionGroup;

  constructor(
    private modalService: ModalService,
    private ecrService: EcrService,
    public orwiStoreQuery: OrwiStoreQuery,
    private transloco: TranslocoService,
    public glb: GlobalService,
    private nav: NavController,
    private orwiService: OrwiService,
    private sessionService: SSOSessionService,
    private sessionQuery: SessionQuery,
    private appService: AppService,
    private config: NgSelectConfig,
    private toastCtrl: ToastController,
    public socket: SocketService,
    private ssoService: SSOSessionService,
    private plt: Platform,
    private title: Title,
    private rxDBService: RxDBService
  ) {
    window.onbeforeunload = () => {
      if (!Capacitor.isNativePlatform()) CapacitorPresentation.terminate();
    };
    if (!environment.production) {
      this.title;
      // let message = 'Orwi Pos';
      // setInterval(() => {
      //   step();
      // }, 100);
      // function step() {
      //   message = message.substr(1) + message.substr(0, 1);
      //   document.title = message.substr(0, 15);
      // }
      // var link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
      // if (!link) {
      //   link = document.createElement('link');
      //   link.rel = 'icon';
      //   document.head.appendChild(link);
      // }
      // link.href = 'https://stackoverflow.com/favicon.ico';
    }
    that = this;
    loadMessages(trMessages);
    locale(
      this.glb.LOCALES.find((elm) => elm.id == localStorage.getItem('lang'))
        ?.locale ?? 'tr'
    );
    this.appService.init();
    this.config.notFoundText = 'Not found';

    this.transloco.setActiveLang(localStorage.getItem('lang') || 'tr');
    this.glb.ipAddress = localStorage.getItem('ecrIPAddress');
    // if (this.plt.is('capacitor')) {
    //this.initZeroConf()
    //}
    this.version = environment.appVersion;
    this.checkConnections();
    this.sessionQuery.activeLicense$.subscribe(async (lic) => {
      // await this.orwiService.getIP()
      // await this.foodAppIntegrastionService.getFoodAppIntegrations()
      if (lic) {
        this.orwiService
          .serviceRequestPromise(
            '/api/pos/pos-parameter/getApplicationMenus',
            {
              id: this.sessionQuery.activeLicense.orwiStore.id,
              appId: '1575-083e',
            },
            this.sessionQuery.token
          )
          .then((o: any) => {
            console.log('AppMenus', o);
            let menus: ShireMenu[] = o.response;
            this.shireMenu = menus.sort((a, b) => a.index - b.index);
            let ps = [...lic.roles.map((m) => m.id)];
            this.shireMenu.map((p) => {
              p.enabled = false;
            });
            for (const iterator of this.shireMenu) {
              ps.forEach((l) => {
                if (iterator.perms.indexOf(l) > -1) {
                  iterator.enabled = true;
                }
              });
            }
            this.shireMenu = this.shireMenu.filter((o) => o.enabled);
            this.shireMenu.sort((a, b) => a.index - b.index);
            this.rootMenu = this.addChildItems('', this.shireMenu); //this.shireMenu.filter((n) => n.parentId == '');
            setTimeout(async () => {
              await this.registerDevice();
            }, 10000);
            // this.backOfficeGlobalService.getExchanges();
          });
        // this.orwiService.serviceRequestPromise
        //   .getCollection({
        //     _db: 'application-menus',
        //     col: '1575-083e',
        //     _filter: { active: true },
        //   })
        //   .toPromise()
        //   .then((o) => {
        //     console.log('menu', o);

        //     this.shireMenu = o.sort((a, b) => a.index - b.index);
        //     let ps = [...lic.roles.map((m) => m.id)];
        //     this.shireMenu.map((p) => {
        //       p.enabled = false;
        //     });

        //     for (const iterator of this.shireMenu) {
        //       ps.forEach((l) => {
        //         if (iterator.perms.indexOf(l) > -1) {
        //           iterator.enabled = true;
        //         }
        //       });
        //     }

        //     this.shireMenu = this.shireMenu.filter((o) => o.enabled);
        //     this.shireMenu.sort((a, b) => a.index - b.index);
        //     this.rootMenu = this.addChildItems('', this.shireMenu); //this.shireMenu.filter((n) => n.parentId == '');
        //     setTimeout(async () => {
        //       await this.registerDevice()
        //     }, 10000);
        //   });

        // this.backOfficeGlobalService.getExchanges();
        // setTimeout(async () => {
        //  await this.connectEcr();
        // }, 6000);
      }
    });


    this.sessionQuery.user$.subscribe((res) => {
      if (res) this.userName = res.name;
    });
  }

  copy() {
    navigator.clipboard.writeText(this.glb.DeviceUUID).catch(() => {
      console.error('Unable to copy text');
    });
    this.glb.toast('', 'UUID Copied', 'bottom', 'success');
  }

  async checkVersion() {
    if (this.plt.is('capacitor')) return;

    if (localStorage.getItem('app_version')) {
      let version: string = localStorage.getItem('app_version');
      if (version !== this.version) {
        let config: any = {
          header: 'Orwi Pos Versiyon Güncelleme',
          message: 'Yeni Versiyon Mevcut Yüklemek İster misiniz?',
          position: 'top',
          color: 'dark',
          animated: true,
          cssClass: 'toast-custom-class',
        };

        config['buttons'] = [
          {
            text: 'Evet',
            role: 'done',
            handler: () => {
              localStorage.setItem('app_version', this.version);
              window.location.reload();
            },
          },
          {
            text: 'Hayır',
            role: 'cancel',
            handler: () => {
              // this.consolelog('Cancel clicked');
            },
          },
        ];
        const toast = await this.toastCtrl.create(config);
        toast.present();
      }
    } else {
      localStorage.setItem('app_version', this.version);
    }

    // this.publicApi
    //   .getCollection({
    //     _db: this.sessionQuery.activeLicense?.orwiStore?.cloudId,
    //     col: 'application-version',
    //     _filter: { id: this.uuid },
    //   })
    //   .toPromise()
    //   .then(async (o: any) => {
    //     if (o.length === 0) {
    //       this.publicApi.upsert({
    //         _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
    //         col: 'application-version',
    //         data: { id: this.uuid, version: this.version },
    //         _filter: { id: this.uuid },
    //       });
    //     } else {
    //       if (this.version !== o[0].version) {
    //         let config: any = {
    //           header: 'KerzzCloud Versiyon Güncelleme',
    //           message: 'Yeni Versiyon Mevcut Yüklemek İster misiniz?',
    //           position: 'top',
    //           color: 'dark',
    //           animated: true,
    //           cssClass: 'toast-custom-class',
    //         };
    //         config['buttons'] = [
    //           {
    //             text: 'Evet',
    //             role: 'done',
    //             handler: () => {
    //               this.publicApi.upsert({
    //                 _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
    //                 col: 'application-version',
    //                 data: { id: this.uuid, version: this.version },
    //               });
    //               window.location.reload();
    //             },
    //           },
    //           {
    //             text: 'Hayır',
    //             role: 'cancel',
    //             handler: () => {
    //               // this.consolelog('Cancel clicked');
    //             },
    //           },
    //         ];

    //         const toast = await this.toastCtrl.create(config);
    //         toast.present();
    //       }
    //     }
    //   });
  }

  getMenu(id) {
    return this.shireMenu.filter((o) => o.parentId == id);
  }

  ionViewDidEnter() {
    this.openSettingSubMenu = false;

    const nativeEl = this.accordionGroup;
    nativeEl.value = this.shireMenu[0].id;
  }

  async ngOnInit() {
    await this.rxDBService.initDB();
  }

  async connectEcr() {
    // await this.ecrService.getEcrSettingsAndInit();
    this.ecrService.connectEcr().then((x) => {
      x;
      //TODO:x error ekrana bas
      //   this.glb.closeLoading();
    });
  }

  async zReport() {
    await this.glb.openModal({
      component: ZReportFormComponent,
      canDismiss: true,
    });
  }

  list() {
    this.nav.navigateForward('folio-list');
  }

  logOut() {
    this.sessionService.logout();
  }

  goUrl(url: string) {
    this.nav.navigateForward(url);
  }

  openSettingSubMenu = false;

  addChildItems(parentId, items) {
    const children = [];

    for (const item of items) {
      if (item.parentId == parentId) {
        const child = { ...item, children: this.addChildItems(item.id, items) };
        children.push(child);
      }
    }

    return children;
  }

  async ecrSettings() {
    await this.glb.openModal({
      component: EcrSettingsComponent,
      canDismiss: true,
    });
  }

  async openStoreModal() {
    this.modalService.selectLicense();
  }

  selectLang(e) {
    if (e?.detail?.value) {
      this.transloco.setActiveLang(e?.detail?.value);
      localStorage.setItem('lang', e?.detail?.value);
    }
  }

  registerDevice() {
    let posDevice: PosDevice = new PosDevice();
    posDevice.appVersion = this.version;
    posDevice.uuid = this.glb.DeviceUUID;
    posDevice.ipAdress = '';
    posDevice.storeId = this.sessionQuery.activeLicense.orwiStore.id;
    posDevice.userRegion = this.sessionQuery.user.userRegion;
    posDevice.lastUpdate = new Date();
    posDevice.userLanguage = this.selectedLang;
    posDevice.userId = this.sessionQuery.user.id;
    posDevice.userName = this.sessionQuery.user.name;
    posDevice.socketId = this.glb.socketId;
    console.log('posDevice', posDevice);
    this.orwiService
      .serviceRequest('/api/device/registerPosDevice', posDevice)
      .subscribe((x) => {
        console.log(x);
      });
    // posDevice.socketId = this.socket
  }

  checkConnections() {
    var intervalId = setInterval(function () {
      that.socket.checkSocketStatus();
    }, 20000);
    intervalId; //
  }

  reload() {
    window.location.href = window.location.href.replace(/[\?#].*|$/, '#');
    window.location.reload();
  }

  youtubeDisplayHtml = `<!DOCTYPE html>
  <html lang="en">
    <head>
      <title>Home</title>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width" />

      <style>
      .video-container{
        overflow: hidden;
      }
      .video-container iframe {
        border: none;
        top: 0;
        left: 0;
        position: absolute;
        margin: 0;
        padding: 0;
        border: 0;
        width: 100%;
        height: 100%;
      }
     </style>
    </head>
    <body onload="makeFullScreen">
    <div class="video-container">
    <iframe
    src="https://www.youtube.com/embed/Zgvw-p4NS3A?list=PLo44QjJl7BZjFYCSz6mqxjq7dXutTgvRl&autoplay=1&muted=0&loop=1&controls=0"
    title="Orwi!"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
   </div>

      <script>
        function requestFullScreen(element) {
          // Supports most browsers and their versions.
          var requestMethod =
            element.requestFullScreen ||
            element.webkitRequestFullScreen ||
            element.mozRequestFullScreen ||
            element.msRequestFullscreen;

          if (requestMethod) {
            // Native full screen.
            requestMethod.call(element);
          } else if (typeof window.ActiveXObject !== 'undefined') {
            // Older IE.
            var wscript = new ActiveXObject('WScript.Shell');
            if (wscript !== null) {
              wscript.SendKeys('{F11}');
            }
          }
        }
        // FullScreen function
        function makeFullScreen() {
          document.getElementsByTagName('iframe')[0].className = 'fullScreen';
          var elem = document.body;
          requestFullScreen(elem);
        }
      </script>
    </body>
  </html>
  `;

  blackScreenHtml = `
  <!DOCTYPE html>
    <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Document</title>

          <style>
              body, html {
                  background-color: black !important;
              }
          </style>
        </head>
        <body>

        </body>
    </html>`;

  navigate(item: ShireMenu, mainMenu: IonMenu) {
    console.log(item);
    if (item.children.length > 0) return;
    if (item.route.includes('#')) {
      switch (item.route) {
        case '#z-report':
          this.zReport();
          break;
        case '#ecrSettings':
          this.nav.navigateForward('ecr-settings');
          break;
        case '#logout':
          this.logOut();
          break;

        default:
          break;
      }
    } else if (item.id == 'f062-s2f4') {
      this.nav.navigateForward('/reports/product-sales-reports');
    } else if (item.route == 'new-order') {
      if (!this.ssoService.checkPermission('backoffice-centralorder-new')) {
        this.glb.permissionToast();
        return;
      }
      if (
        Capacitor.isNativePlatform() ||
        (this.plt.is('mobile') && window.innerWidth <= 480) ||
        (this.plt.is('desktop') && window.innerWidth <= 480)
      ) {
        this.nav.navigateForward('mobile-new-order');
      } else {
        this.nav.navigateForward('new-order');
      }
    } else {
      if (
        !this.ssoService.checkPermission('backoffice-consumption-production') &&
        item.route === 'production-actions'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('backoffice-consumption-wastage') &&
        item.route === 'waste-loss-actions'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission(
          'backoffice-consumption-stockoutage'
        ) &&
        item.route === 'staff-meal-actions'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('backoffice-count') &&
        item.route === 'inventory'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('backoffice-transfer') &&
        item.route === 'warehouse-transfer'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('backoffice-centralorder-myorders') &&
        item.route === 'my-orders'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('backoffice-centralorder-new') &&
        item.route === 'new-order'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('backoffice-invoice-outbound') &&
        item.route === 'outbound-invoice'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('backoffice-invoice-inbound') &&
        item.route === 'inbound-invoice'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('delete-reason-definitions') &&
        item.route === 'delete-product-reasons'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('gift-definitions') &&
        item.route === 'gift-definitions'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('product-definitions') &&
        item.route === 'product-definitions'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('printer-definitions') &&
        item.route === 'printer-definitions'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('employee') &&
        item.route === 'pos-users-list'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('discount-definitions') &&
        item.route === 'discount-definitions'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('integration-list') &&
        item.route === 'food-app-integration'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('payment-types') &&
        item.route === 'payment-definitions'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('branch-list') &&
        item.route === 'branch-list'
      ) {
        this.glb.permissionToast();
        return;
      }

      if (
        !this.ssoService.checkPermission('branch-list') &&
        item.route === 'branch-list'
      ) {
        this.glb.permissionToast();
        return;
      }

      if (
        !this.ssoService.checkPermission('modules-notifications') &&
        item.route === 'notifications'
      ) {
        this.glb.permissionToast();
        return;
      }

      if (
        !this.ssoService.checkPermission('parameters') &&
        item.route === 'parameters'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('cash-register') &&
        item.route === 'cash-register'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('brand') &&
        item.route === 'brands-list'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('modules-kitchen') &&
        item.route === 'kitchen-display-home'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('table-definitions') &&
        item.route === 'table-definitions'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('modules-pos') &&
        item.route === 'pos-home'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        (!this.ssoService.checkPermission('z-report') ||
          !this.ssoService.checkPermission('modules-z-report')) &&
        item.route === '#z-report'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('integrations') &&
        item.route === 'food-app-integration'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('modules-courier') &&
        item.route === 'courier-home'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('portfolio') &&
        item.route === 'price-management'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        (!this.ssoService.checkPermission('folio-list') ||
          !this.ssoService.checkPermission('modules-folio-list')) &&
        item.route === 'folio-list'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        (!this.ssoService.checkPermission('dashboard') ||
          !this.ssoService.checkPermission('modules-dashboard')) &&
        item.route === 'dashboard'
      ) {
        this.glb.permissionToast();
        return;
      }
      if (
        (!this.ssoService.checkPermission('reports-use') ||
          !this.ssoService.checkPermission('modules-report')) &&
        (item.route === 'store-sync-report' ||
          item.route === 'cancellation-reports' ||
          item.route === 'store-time-report' ||
          item.route === 'product-based-sales-report' ||
          item.route === 'order-time-report' ||
          item.route === 'payment-type-sale-reports' ||
          item.id === 'f062-s2f4')
      ) {
        this.glb.permissionToast();
        return;
      }
      this.nav.navigateForward(item.route);
    }

    mainMenu.close();
  }

  checkPerm(menuItem) {
    if (
      !this.ssoService.checkPermission('backoffice-consumption') &&
      menuItem.title === 'Tüketimler'
    ) {
      return true;
    } else if (
      !this.ssoService.checkPermission('backoffice-centralorder') &&
      menuItem.title === 'Merkezi Sipariş'
    ) {
      return true;
    } else if (
      !this.ssoService.checkPermission('backoffice-invoice') &&
      menuItem.title === 'Fatura Modülü'
    ) {
      return true;
    } else if (
      !this.ssoService.checkPermission('modules-backoffice') &&
      menuItem.title === 'Arka Ofis'
    ) {
      return true;
    } else if (
      !this.ssoService.checkPermission('modules-report') &&
      menuItem.title === 'Raporlar'
    ) {
      return true;
    } else if (
      !this.ssoService.checkPermission('kitchen') &&
      menuItem.title === 'Mutfak'
    ) {
      return true;
    } else if (
      !this.ssoService.checkPermission('modules-definitions') &&
      menuItem.title === 'Tanımlamalar'
    ) {
      return true;
    }
    // else if(!this.ssoService.checkPermission('modules')) {return true}
    else {
      return false;
    }
  }

  openWinServicePage() {
    window.open('http://win-service.kerzz.cloud', '_blank');
  }
}

export interface ShireMenu {
  id: string;
  title: string;
  route?: string;
  icon?: string;
  index: number;
  active?: boolean;
  enabled?: boolean;
  parentId: string;
  status?: 'open' | 'close';
  perms?: string[];
  children: ShireMenu[];
}
