import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITable } from 'src/app/services/dto/tables';
import { TableQuery } from 'src/app/modules/tables/state/table.query';
import { TableStore } from 'src/app/modules/tables/state/table.store';
import { GlobalService } from 'src/app/services/global.service';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { TranslocoService } from '@ngneat/transloco';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { EcrCoverService } from 'src/app/services/ecr/ecr-cover.service';

@Component({
  selector: 'table-button',
  templateUrl: './table-button.component.html',
  styleUrls: ['./table-button.component.scss'],
})
export class TableButtonComponent implements OnInit {
  @Input() state: 'open' | 'close';
  @Input() table: ITable;
  @Output() itemSelected = new EventEmitter<ITable>();
  @Output() onInfoClick: EventEmitter<ITable> = new EventEmitter<ITable>();
  @Input() active: boolean = false;
  constructor(
    private tableStore: TableStore,
    private glb: GlobalService,
    private folioService: FolioService,
    private transloco: TranslocoService,
    public tQ: TableQuery,
    private ssoService: SSOSessionService,
    private sessionQuery: SessionQuery,
    private ecrCoverService: EcrCoverService,
  ) { }

  ngOnInit() { }

  paymentDone() {
    if (this.table.folios.length > 0) {
      if (this.table.folios[0].eftPos) {
        if (this.table.folios[0].rows.filter(fr => fr.rowType == 'payment').reduce((sum, item) => sum + (item.unitPrice * item.qty), 0) || 0 == this.table.folios[0].grandTotal) {
          return true
        }
      }
    }
    return false
  }

  async select() {
    this.glb.showLoading()
    if (this.table.folios.length > 0) {
      const folio: Folio = this.table.folios[0]
      this.sessionQuery

      if (!this.ssoService.checkPermission('folio-show-all-folios')) {
        this.glb.permissionToast();
        this.glb.closeAllLoading()
        return;
      }
      if (folio.isOrwiPay) {
        await this.glb.openAlert({
          header: this.transloco.translate('ORWI'),
          message:
            'Bu çek ORWI APP den Online olarak kapatılmıştır.Adisyon Kapatılacaktır.',
          buttons: [
            this.transloco.translate('No'),
            {
              text: this.transloco.translate('Yes'),
              handler: async () => {
                await this.ecrCoverService.sendToEcr(folio);
              },
            },
          ],
        });
        this.glb.closeAllLoading()
        return;
      }

      const folioResult = await this.folioService.checkFolioEftPos(folio)
      this.glb.closeAllLoading()
      if (folioResult === 'payment-done') {
        await this.glb.openAlert({
          header: this.transloco.translate('Orwi Pos'),
          message:
            'Bu çek ' +
            folio.rows.find((x) => x.rowType == 'payment').name +
            ' olarak kapatılmıştır.Adisyon Kapatılacaktır.',
          buttons: [
            this.transloco.translate('No'),
            {
              text: this.transloco.translate('Yes'),
              handler: () => {
                // popover.dismiss(value);
              
                this.folioService._posCloseFolio(folio);
              },
            },
          ],
        });
        return
      }
      this.glb.closeAllLoading()
      if (folioResult === 'sended-tsm') {
        this.glb.toast(
          '',
          'You cannot make transactions on the ticket sent via EFT Pos.',
          'bottom',
          'danger'
        );
     
        return;
      }
    }

    if (this.tableStore.getValue().designMode) {
      this.tableStore.update({ inDesignTable: this.table })
    } else {
      this.itemSelected.emit(this.table);
    }
    this.glb.closeAllLoading()
  }

  info(e, table) {
    e.preventDefault();
    e.stopPropagation();
    this.onInfoClick.emit(table);
  }

  dragOut() {

  }


}
