import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ITable } from 'src/app/services/dto/tables';
import { TableQuery } from '../../state/table.query';
import { GlobalService } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { TableService } from '../../state/table.service';

@Component({
  selector: 'open-table-folios-select',
  templateUrl: './open-table-folios-select.html',
  styleUrls: ['./open-table-folios-select.scss'],
})
export class OpenTableFoliosSelectComponent implements OnInit, OnDestroy {
  selected: ITable;
  combinedFolios: ITable[] = [];

  private folioSubscription: Subscription;
  constructor(
    public tableQuery: TableQuery,
    private glb: GlobalService,
    private folioQuery: FolioQuery,
    private tableService: TableService
  ) {}

  @Output()
  onSelectTables: EventEmitter<ITable> = new EventEmitter<ITable>();

  ngOnInit() {
    this.folioSubscription = this.folioQuery.openFolios$.subscribe(
      (folios) => {
        folios.forEach(folio => {
          this.combinedFolios.push(this.tableService.convertFolioToTable(folio))
        });
      }
    );
    this.init();
    if (this.folioSubscription) {
      this.folioSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
  }

  filterTables(tables: ITable[], key: string, search: string): ITable[] {
    if (!Array.isArray(tables)) {
      return [];
    }

    if (typeof search === 'string') {
      return tables.filter(
        (table) =>
          (table[key] as string).toLocaleLowerCase() ===
          search.toLocaleLowerCase()
      );
    }

    return tables;
  }

  init() {
    this.selected = undefined;
  }

  onRowClick(e: ITable) {
    console.log('onRowClick', e);
    if (e.folios.length > 0) {
      if (e.folios[0].eftPos) {
        this.glb.toast(
          'Eft Pos',
          "Eft Pos'a gönderilmiş adisyonda  işlem yapamazsınız.",
          'bottom',
          'warning'
        );
        return;
      }
    }

    this.selected = e;
    this.emitToParent();
  }

  isChecked(e: ITable) {
    return this.selected?.id == e.id;
  }

  emitToParent() {
    this.onSelectTables.emit(this.selected);
  }
  clear() {
    this.selected = null;
    this.emitToParent();
  }
}

export interface OpenTableListItem {
  [name: string]: { selected: boolean; item: ITable };
}
