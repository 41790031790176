import { Component, OnInit } from '@angular/core';
import { Device } from '@capacitor/device';
import { MenuQuery } from 'src/app/modules/menu/state/menu.query';
import { MenuStore } from 'src/app/modules/menu/state/menu.store';
import { Product, ProductGroup } from 'src/app/services/dto/orwi-product';
import { GlobalService } from 'src/app/services/global.service';
import { KitchenDisplayService } from '../state/kitchen-display.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'kitchen-display-settings',
  templateUrl: './kitchen-display-settings.component.html',
  styleUrls: ['./kitchen-display-settings.component.scss'],
})
export class KitchenDisplaySettingsComponent implements OnInit {
  progress: boolean = false;

  constructor(
    public menuStore: MenuStore,
    public menuQuery: MenuQuery,
    private kitchenDisplayService: KitchenDisplayService,
    private fb: FormBuilder,
    private glb: GlobalService
  ) {}

  folioSettings = this.fb.group({
    orderNumber: [],
    waiter: [],
    table: [],
    warningColor: [],
    warningTime: []
  });
  ngOnInit(): void {
    this.getSettings();
    let settings = localStorage.getItem('kitchenDisplaySettings');
    if (settings !== null) {
      this.folioSettings.patchValue({
        orderNumber: JSON.parse(settings).orderNumber,
        waiter: JSON.parse(settings).waiter,
        table: JSON.parse(settings).table,
        warningColor: JSON.parse(settings).warningColor,
        warningTime: JSON.parse(settings).warningTime,
      });
    } else {
      this.folioSettings.patchValue({
        orderNumber: true,
        waiter: true,
        table: true,
        warningColor: '#f4f5f8',
        warningTime: 15,
      });
    }
  }

  setFolioSettings(event) {
    event;
    localStorage.setItem(
      'kitchenDisplaySettings',
      JSON.stringify(this.folioSettings.value)
    );
  }

  getProducts(groupId: string) {
    return (
      this.menuStore.getValue().products.filter((p) => p.group == groupId) || []
    );
  }

  selectGroup(e, group: ProductGroup) {
    if (e.detail.checked) {
      //debugger
      this.kitchenDisplayService.kitchenDisplaySettings.groups.push(group.id);
      this.selectUnselectProducts(true, group.id);
    } else {
      this.kitchenDisplayService.kitchenDisplaySettings.groups.forEach(
        (element, index) => {
          if (element == group.id)
            this.kitchenDisplayService.kitchenDisplaySettings.groups.splice(
              index,
              1
            );
        }
      );
      this.selectUnselectProducts(false, group.id);
    }
  }

  selectUnselectProducts(isSelected: boolean, groupId: string) {
    if (isSelected) {
      this.getProducts(groupId).forEach((element) => {
        this.kitchenDisplayService.kitchenDisplaySettings.products.push(
          element.id
        );
      });
    } else {
      this.getProducts(groupId).forEach((product) => {
        this.kitchenDisplayService.kitchenDisplaySettings.products.forEach(
          (element, index) => {
            if (element == product.id)
              this.kitchenDisplayService.kitchenDisplaySettings.products.splice(
                index,
                1
              );
          }
        );
      });
    }
  }

  selectProduct(e, product: Product) {
    if (e.detail.checked) {
      if (!this.checkedProducts(product.id)) {
        this.kitchenDisplayService.kitchenDisplaySettings.products.push(
          product.id
        );
      }
    } else {
      this.kitchenDisplayService.kitchenDisplaySettings.products.forEach(
        (element, index) => {
          if (element == product.id)
            this.kitchenDisplayService.kitchenDisplaySettings.products.splice(
              index,
              1
            );
        }
      );
    }
  }

  checkedProducts(itemId) {
    if (
      this.kitchenDisplayService.kitchenDisplaySettings &&
      this.kitchenDisplayService.kitchenDisplaySettings.products.length > 0
    ) {
      return (
        this.kitchenDisplayService.kitchenDisplaySettings.products.filter(
          (x) => x == itemId
        ).length > 0
      );
    } else {
      return undefined;
    }
  }

  checkedGroups(itemId) {
    if (
      this.kitchenDisplayService.kitchenDisplaySettings &&
      this.kitchenDisplayService.kitchenDisplaySettings.groups.length > 0
    ) {
      return (
        this.kitchenDisplayService.kitchenDisplaySettings.groups.filter(
          (x) => x == itemId
        ).length > 0
      );
    } else {
      return undefined;
    }
  }

  getSettings() {
    // this.glb.showLoading()
    Device.getId().then(async (x) => {
      this.kitchenDisplayService.getSettings(x.identifier).subscribe((x) => {
        x.length > 0
          ? (this.kitchenDisplayService.kitchenDisplaySettings = x[0])
          : undefined;
        // this.glb.closeLoading()
      });
    });
  }

  saveSettings() {
    this.progress = true;
    setTimeout(() => {
      Device.getId().then(async (x) => {
        this.kitchenDisplayService.kitchenDisplaySettings.deviceId =
          x.identifier;
        this.kitchenDisplayService.saveSettings().then((x) => {
          x; //reserved
          this.progress = false;
          this.glb.toast(
            "Kitchen Screen",
            'Successfully Saved!',
            'bottom',
            'success'
          );
        });
      });
    }, 2000);
  }
}
