import { ServiceType } from "./orwi-folio"
import { Advices, groupImages, ModifierCover, Product, ProductGroup } from "./orwi-product"

export class OrwiStore {
    id = ""
    name = ""
    logo = ""
    address = ""
    branchCode: number
    serviceTypes: ServiceType[] = []
    products: Product[] = []
    productGroups: ProductGroup[] = []
    productGroupImages: groupImages[] = []
    productModifiers: ModifierCover[] = []
    productGroupModifiers: ModifierCover[] = []
    kerzzId = ""
    advices: Advices[] = []
    properties: Properties
    languages: string[] = []
    localPayTypes: [] = []
    payTypes: payTypes[] = []
    cloudId: string = ""
    mainAction: mainActions = "in-store"
    orwiPayPoint = 0.20
    payPoint = 0.10
    tipEnabled = true
    location = ""
    geoLocation = { latitude: 0, longitude: 0, approve: false }
    outOfService = false

    masterpass: masterpassConfig = new masterpassConfig()

    coupon: Coupon = new Coupon()

    isProduction = false

    storeCurrency = "₺"
    storeLocale = "tr"
    distance = 0

    pointTitle = "Orwi Point"
    pointLogo = ""
    maxPoint = 0
    isVisible = true

    phone = ""
    parentId = ""

    workingHours: WorkingHours[] = []
    attributes = []
    description = ""
    amountPerPerson = 0
    instagramAddress = ""
}

export class WorkingHours {
    day = 0
    open = "00:00"
    close = "23:59"
}

export class masterpassConfig {
    serviceUrl = ""
    clientId = ""
}

export class Properties {

    callWaiter = false
    callWallet = false
    requestCheck = false
    reservation = false
    invoiceActive = false

    stampActive = false
    stampConversionRate = 0



    pay = false // online payment
    order = false  // table Service

    webMenuActive = false
    webAppActive = false
    timedDineIn = false
    timedTakeAway = false
    delivery = false
    selfOrdering = false
    selfService = false
}


// export class coupon {
//     id = ""
//     storeId = ""
//     userId = ""
//     count = 0
//     useCount = 0
//     couponType: "orwi-point" | "product" = "orwi-point"
//     amount = 0
//     totalPoint = 0
//     isTemplate = false
//     products: {
//         productId: "",
//         qty: "",
//         price: "",
//     }



// }

export class Coupon {
    id = ""
    createDate = new Date()
    storeId = ""
    storeName = ""
    couponType: "orwi-point" | "free-product" | "discount-percent" = "orwi-point"
    amount = 0 //orwi puan ise tl miktari - discount-percent ise iskonto orani:0.1=%10
    link = ""
    products = new Array<string>()
    description = ""
    name = ""
    source: "gift-card" | "scratch" = "gift-card"
    validThru: undefined | Date
    //for multi
    count = 0
    useCount = 0
    //for user coupon
    userId = ""
    totalPoint = 0 //front-end icin - kullanici toplam puanini verir
    used = false
    loaded = false
    usedDate: undefined | Date
    loadDate: undefined | Date
    logo = ""
    selected = false
}

export class StoreCover {
    approved = true
    position = ""
    storeId = ""
    storeInfo: OrwiStore = new OrwiStore()
    userId = ""
    distance = 1000
}


export class storeTable {
    id = ""
    storeId = ""
    groupName = ""
    tableName = ""
    startAction = ""
    code = ""
    qrId = ""
    active = true
    tableQrs: tableQrs[] = []
}

export class tableQrs {
    shortCode = ""
    code = ""
}



export type payTypes = 'orwi-masterpass' | 'local' | 'offline-checkout' | 'offline-table'
export type mainActions = "in-store" | "take-away" | "loyalty" | "payment" | "self-ordering" | "only-menu" | "delivery" | "coupon" | "pre-paid" | "select-address"
export type paymentTypes = 'cash' | 'not-paid' | 'account' | 'bank' |
'staff' | 'meal-voucher' | 'expense-slip' | 'external'
| 'bank-transfer' | 'other';

export class PaymentType {
    storeId: string = ""
    id: string = ""
    name: string = ""
    payType: 'cash' | 'not-paid' | 'account' | 'bank' |
        'staff' | 'meal-voucher' | 'expense-slip' | 'external'
        | 'bank-transfer' | 'other' = "cash"
    active: boolean = true
    image: string = ""  //logo
    vendorId: string = ""
    selected = false
    kerzzId : string = ""
    askDescription: boolean = false;
    useApp : boolean = false
    bank: string = ""
}


export interface CheckInInfo {
    id: string
    tableName: number
    startAction: mainActions
    store: OrwiStore
    coupon: Coupon
}

export interface Attributes {
    id: string
    name: string
    image: string
    type: string
}
