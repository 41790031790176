<ion-card>
  <ion-card-header style="padding: 15px 28px;">
    <div class="delivery_form_customer_label">
      <div>
        <div *ngIf="!isEditing">
          <ion-card-title>
            {{'Customer Name' | transloco}}: {{ customerName }}
          </ion-card-title>
          <ion-card-subtitle>
            {{'Phone' | transloco}}: {{ customerGsm }}
          </ion-card-subtitle>
        </div>
        <div *ngIf="isEditing">
          <ion-card-title>
            {{'Edit Record' | transloco}}
          </ion-card-title>
        </div>
      </div>

      <div class="customer-card-btns">
        <ion-button
          style="--background: #4383ec"
          (click)="changeEditing()"
          *ngIf="!isEditing"
          >{{ "Edit Address" | transloco }}
        </ion-button>
        <ion-button
          style="--background: #ed4c4c"
          *ngIf="isEditing"
          (click)="cancel()"
          >{{ "Cancel" | transloco }}
        </ion-button>
        <ion-button
          style="--background: #4cd9ed"
          *ngIf="isEditing"
          (click)="save()"
          >{{ "Save" | transloco }}
        </ion-button>
        <ion-button
          style="--background: #184eae"
          *ngIf="!isEditing"
          (click)="SelectCustomer()"
          >{{ "Select Address" | transloco }}
        </ion-button>
      </div>
    </div>
  </ion-card-header>
  <ion-card-content>
    <ion-row [formGroup]="customerForm" *ngIf="isEditing">
      <ion-col size="12" style="padding: 0px 13px;">
        <ion-item class="selector-container">
          <ion-input
            label="{{ 'Customer Name' | transloco }}"
            formControlName="customerName"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-list>
      <ion-list-header>
        <div class="delivery_form_customer_label">
          <div style="color: #171e4e">
            {{ "Defined Addresses" | transloco }}
          </div>
          <div class="add-address-btn">
            <ion-button (click)="addNewAddress()">
              <ion-icon style="color: #4383ec" name="add-outline"></ion-icon>
              {{ "Add New Address" | transloco }}
            </ion-button>
          </div>
        </div>
      </ion-list-header>
      <ion-accordion-group [value]="selectedAddressAccordion">
        <ion-radio-group
          [value]="selectedAddressId"
          (ionChange)="changeSelectedAddress($event)"
        >
        <div
        *ngFor="let adres of Addresses.controls; let i = index"
        style="display: flex; align-items: center; width: 100%;"
        >
        <span>
          {{i + 1}}
        </span>
          <customer-address-item
            style="width: 100%;"
            [addressFormGroup]="adres"
            [isEditing]="isEditing"
            (onDeleteAddress)="onDeleteAddress.emit(i)"
            (onSaveAddress)="save()"
            (onCancelAddress)="cancel()"
          ></customer-address-item>
        </div>
        </ion-radio-group>
      </ion-accordion-group>
    </ion-list>
  </ion-card-content>
</ion-card>
