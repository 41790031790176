import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { FoodAppCancelReason } from './dto';
import { SessionQuery } from '../../session/state/session.query';

@Injectable({
  providedIn: 'root'
})
export class FoodAppsIntegrationOrderService {
  foodSeriviceUrl = "https://food-apps-service.kerzz.com:4400"

  constructor(
    private http: HttpClient,
    private glb: GlobalService,
    private sessionQuery : SessionQuery

  ) { }



  getOrders(sartDate: Date, endDate: Date, storeId: string) {
    return new Promise((resolve, reject) => {

      this.http.post(`${this.foodSeriviceUrl}/api/order/getOrders`, { startDate: sartDate, endDate: endDate, storeId: storeId }
        , this.getHeaders()
      ).toPromise().then((o: Folio[]) => {

        console.log("folios", o)

        resolve(true)

      }).catch(e => {
        if (e.error.statusCode == 500 &&( e.error.message as string).includes('client not found')) {
          this.glb.toast("I Can't Connect to the Business", "The computer may be off or there may be an internet problem.", "middle", "success")
          reject(e)
        } else {
          this.glb.toast("Error", e?.error?.message, "middle", "success")
          reject(e)
        }
      })


    })

  }

  getCancelOrderReason(orderId: string): Promise<FoodAppCancelReason[]> {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.foodSeriviceUrl}/api/order/getCancelReasons`, { orderId: orderId }
        , this.getHeaders()
      ).toPromise().then((o: FoodAppCancelReason[]) => {
        resolve(o)
      }).catch(e => {
        if (e.error.statusCode == 500) {
          this.glb.toast("An error occured", "Cancellation Reasons Could Not Be Received.", "middle", "success")
          reject([])
        } else {
          this.glb.toast("An error occured", e?.error?.message, "middle", "success")
          reject([])
        }
      })
    })
  }

  cancelOrder(orderId: string, reason: FoodAppCancelReason): Promise<any> {
    return new Promise((resolve, reject) => {

      this.http.post(`${this.foodSeriviceUrl}/api/order/cancelOrder`, { orderId: orderId, description: reason.description, reasonId: reason.id }
        , this.getHeaders()
      ).toPromise().then((o: any) => {
        resolve(o)
      }).catch(e => {
        if (e.error.statusCode == 500) {
          this.glb.toast("An error occured", "Order Could Not Be Canceled.", "middle", "success")
          reject(undefined)
        } else {
          this.glb.toast("An error occured", e?.error?.message, "middle", "success")
          reject(undefined)
        }
      })
    })
  }

  getHeaders() {
    console.log('getHeaders',this.sessionQuery.user.name)
    let httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
        'accept': 'application/json',
        'x-api-key': 'UjJ4dlltRnVJRmRsWWlCVGFYUmw',
        'x-user-name' : this.glb.notTurkishCharacter(this.sessionQuery?.user?.name || '')
      })
    }
    return httpOptions
  }

}
