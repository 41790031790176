import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Folio } from 'src/app/services/dto/orwi-folio';
import { EftPosService } from 'src/app/services/ecr/eft-post.service';
import { GlobalService } from 'src/app/services/global.service';
import { FolioQuery } from '../../state/folio.query';
import { FolioService } from '../../state/folio.service';
import { TranslocoService } from '@ngneat/transloco';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';

@Component({
  selector: 'eft-pos-list',
  templateUrl: './eft-pos-list.component.html',
  styleUrls: ['./eft-pos-list.component.scss'],
})
export class EftPosListComponent implements OnInit {
  constructor(
    private eftPosService: EftPosService,
    public fq: FolioQuery,
    private folioService: FolioService,
    private modalController: ModalController,
    private glb: GlobalService,
    private translocoService: TranslocoService,
    private ssoService: SSOSessionService
  ) {}
  selectedFolio: Folio = new Folio();
  eftPosList: Folio[] = [];
  ngOnInit() {
    console.log(this.eftPosList);
    console.log(this.selectedFolio);
  }

  close() {
    this.modalController.dismiss();
  }

  selectItem(folio: Folio) {
    this.selectedFolio = folio;
  }

  revokeEftPos() {
    if(!this.ssoService.checkPermission('folio-eft-recall')){
      this.glb.permissionToast()
      return
    }
    let msg = '';
    if (this.selectedFolio.creator.userID == '') {
      this.glb.toast('', 'Please select a folio first.', 'bottom', 'warning');
    } else {
      this.folioService.checkFolioEftPos(this.selectedFolio).then((x) => {

        if (x == 'payment-done') {
          msg = this.translocoService.translate(
            `Folio #{{sequenceNo}} has been closed as {{paymentType}}. No actions can be taken.`,
            {
              sequenceNo: this.selectedFolio.sequenceNo,
              paymentType: this.selectedFolio.rows.find(
                (x) => x.rowType == 'payment'
              ).name,
            }
          );
          this.glb.toast('TSM', msg, 'bottom', 'danger');
          return;
        } else if (x === 'sended-tsm') {
          this.glb.openAlert({
            header: this.translocoService.translate('EFT POS'),
            message: this.translocoService.translate(
              'eftRevokeConfirmation',
              { sequenceNo: this.selectedFolio.sequenceNo }
            ),
            buttons: [
              {
                text: this.translocoService.translate('No'),
                role: 'cancel',
              },
              {
                text: this.translocoService.translate('Yes'),
                handler: () => {
                  msg = this.translocoService.translate(
                    `Folio #{{sequenceNo}} has been retrieved from EFT POS.`,
                    { sequenceNo: this.selectedFolio.sequenceNo }
                  );
                  this.eftPosService.backFolioFromEftPos(this.selectedFolio);
                  this.glb.toast('TSM', msg, 'bottom', 'success');
                  this.eftPosList = this.fq
                    .getValue()
                    .openFolios.filter((x) => x.eftPos == true);
                  this.selectedFolio.creator.userID = '';
                  // this.close()

                },
              },
            ],
          });
        }
      });
    }
  }
}
