import { Pipe, PipeTransform } from '@angular/core';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';
import { FolioRowTotalPipe } from './folio-row-total.pipe';

@Pipe({
  name: 'orwiFoliosTotalPrice',
  pure: false,
})
export class OrwiFoliosTotalPricePipe implements PipeTransform {
  /**
   *
   */
  constructor(private folioRowTotal: FolioRowTotalPipe) {}

  transform(value: Folio[] | Folio, paymentRows: FolioRow[] = []): number {
    if (!value) {
      return 0;
    }

    let paymentsTotal = paymentRows
      .filter((el) => el.rowType != 'discount')
      .reduce((total, row) => total + row.price, 0);

    this.reduceFolio = this.reduceFolio.bind(this);
    this.reduceFolioRows = this.reduceFolioRows.bind(this);

    if (Array.isArray(value)) {
      return value.reduce(this.reduceFolio, 0) + paymentsTotal;
    } else {
      return (
        this.filterRow(value.rows).reduce(this.reduceFolioRows, 0) +
        paymentsTotal
      );
    }
  }
  reduceFolio(acc, current: Folio) {
    // console.log('current:', current);
    return acc + this.filterRow(current.rows).reduce(this.reduceFolioRows, 0);
  }
  reduceFolioRows(acc, current: FolioRow) {
    // console.log('reducedRows', acc + this.folioRowTotal.transform(current))
    // console.log('current', current)
    return acc + this.folioRowTotal.transform(current);
    // return acc + +current.qty * +current.price;
  }

  filterRow(row: FolioRow[]) {
    return row.filter(
      (rw) =>
        !['deleted', 'removed'].includes(rw.recordStatus) &&
        (['product', 'modifier'].includes(rw.rowType) ||
          ['discount'].includes(rw.rowType))
    );
  }
}
