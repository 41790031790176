import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AppUser } from '../../session/state/dto/sso-dto';
import { SessionQuery } from '../../session/state/session.query';
import { AppUsersState, AppUsersStore } from './pos-users.store';

@Injectable({ providedIn: 'root' })
export class AppUsersQuery extends Query<AppUsersState> {
  activeAppUser: AppUser;
  appUsers$ = this.select('appUsers');
  roles$ = this.select('roles');
  appUsers: AppUser[];

  getAppUser(role: 'Manager' | 'Admin' | 'Courier' | 'All' = 'All') {
    if (role == 'All') {
      return this.appUsers;
    } else {
      return this.appUsers.filter((appUser) =>
        appUser.licances.find(
          (lc) =>
            lc.roles.find((rl) => rl.name == role) &&
            lc.licanceId == this.sQ.activeLicense.licanceId
        )
      );
    }
  }

  constructor(protected store: AppUsersStore, private sQ: SessionQuery) {
    super(store);

    this.select('activeAppUser').subscribe((o) => {
      this.activeAppUser = o;
    });

    this.select('appUsers').subscribe((o) => {
      console.log(o);
      this.appUsers = o;
    });
  }
}
