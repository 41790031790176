import { Injectable } from '@angular/core';
import { IonicSafeString, Platform } from '@ionic/angular';
import {
  PavoActionType,
  PavoPackageType,
  PavoPaymentPlugin,
} from 'libs/pavo-pos-payment/src';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { Folio } from '../dto/orwi-folio';
import { GlobalService } from '../global.service';
import { PublicApiService } from '../public-api/public-api.service';
import { PavoCompleteSaleResult, TransactionHandle } from './dto/pavo.model';
import { EcrBaseService } from './ecr.base.service';
import { formatSale } from './ecr.pavo.service';

@Injectable({
  providedIn: 'root',
})
export class PavoIntentService implements EcrBaseService {
  _TransactionHandle: TransactionHandle;
  androidReceiverID: string = null;
  _TransactionSequence = 1;
  saleBehavior = new BehaviorSubject<{
    status: 'success' | 'error';
    data: any;
  }>(null);

  constructor(
    private glb: GlobalService,
    private folioQuery: FolioQuery,
    private plt: Platform,
    private publicApi: PublicApiService,
    private sessionQuery: SessionQuery
  ) {
    this.androidInit();
  }

  async androidInit() {
    if (this.plt.is('android') && this.plt.is('capacitor')) {
      // this.androidReceiverID = await CapacitorIntents.registerBroadcastReceiver(
      //   {
      //     filters: [
      //       'pavopay.intent.action.start.sale.result',
      //       'pavopay.intent.action.start.payment.result',
      //       'pavopay.intent.action.complete.sale.result',
      //       'pavopay.intent.action.complete.sale',
      //       'pavopay.intent.action.cancel.sale.result',
      //       'pavopay.intent.action.current.sale.result',
      //       'pavopay.intent.action.get.mediators.result',
      //       'pavopay.intent.action.completed.sale.result',
      //       'pavopay.intent.action.print.out.result',
      //       'pavopay.intent.action.create.report.result',
      //       'pavopay.intent.action.get.device.info.result',
      //       'pavopay.intent.action.send.offline.sales.result',
      //       'pavopay.intent.action.performEOD.result',
      //     ],
      //   },
      //   (data) => {
      //     console.log('data', data);
      //     alert(JSON.stringify(data));
      //   }
      // );

      await PavoPaymentPlugin.addListener(
        'OnLaunchResult',
        async (result: any) => {
          let resultAsPavoCompleteSaleResult = new PavoCompleteSaleResult(
            result.Data,
            result.HasError
          );

          await this.publicApi.upsert({
            _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
            col: 'ecr-logs',
            data: {
              createDate: new Date(),
              ecrResult: JSON.stringify(resultAsPavoCompleteSaleResult),
              methot: 'pavo-sale-result',
              folioId: this.folioQuery.getValue().activeFolio.id,
            },
          });

          if (
            !result ||
            typeof result === 'undefined' ||
            resultAsPavoCompleteSaleResult == undefined ||
            Object.keys(resultAsPavoCompleteSaleResult).length == 0
          )
            return;

          if (resultAsPavoCompleteSaleResult.HasError === true) {
            let errorMessage = 'Bir Sorun Oluştu';
            if (resultAsPavoCompleteSaleResult.Data) {
              resultAsPavoCompleteSaleResult.Data = JSON.parse(
                resultAsPavoCompleteSaleResult.Data as any
              );
              if (resultAsPavoCompleteSaleResult.Data.Errors.length > 0) {
                errorMessage = resultAsPavoCompleteSaleResult.Data.Errors.join(
                  '\n'
                );
              }
            }

            this.saleBehavior.next({
              data: errorMessage,
              status: 'error',
            });
            this.saleBehavior.complete();
            return;
          }
          if (resultAsPavoCompleteSaleResult.Data) {
            resultAsPavoCompleteSaleResult.Data = JSON.parse(
              resultAsPavoCompleteSaleResult.Data as any
            );
            if (
              resultAsPavoCompleteSaleResult.Data.StatusId === 6 ||
              resultAsPavoCompleteSaleResult.Data.StatusId === 4
            ) {
              this.saleBehavior.next({
                data: JSON.parse(resultAsPavoCompleteSaleResult as any),
                status: 'success',
              });
              this.saleBehavior.complete();
            } else {
              this.saleBehavior.next({
                data: null,
                status: 'error',
              });
              this.saleBehavior.complete();
            }
          }

          console.log(result);
        }
      );
    }
  }

  connect(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const TransactionHandle = {} as TransactionHandle;
        TransactionHandle.Fingerprint = 'test_fingerprint';
        TransactionHandle.SerialNumber = localStorage.getItem('ecrSerialNo');
        TransactionHandle.TransactionSequence = this._TransactionSequence;
        TransactionHandle.TransactionDate = new Date().toISOString();
        this._TransactionHandle = TransactionHandle;

        console.log('pavo.intent', 'getDeviceInfo entered');
        this.glb.showLoading();
        this.glb.toast('', 'Yazarkasaya bağlanılıyor...', 'bottom', 'success');
        //   await CapacitorIntents.sendBroadcastIntent({
        //     action: PavoAndroidIntents.GetDeviceInfo,
        //     value: {
        //       DeviceInfo: {
        //         AdditionalInfo: {
        //           serialNumber: true,
        //           fingerPrint: true,
        //           listTerminals: true,
        //         },
        //       },
        //     },
        //   })
        // await PavoPaymentPlugin.launch({
        //   action: PavoActionType.getDeviceInfo,
        //   package: PavoPackageType.demo,
        //   flags: [0x10000000], //FLAG_ACTIVITY_NEW_TASK,
        //   type: 'application/json',
        //   arguments: {
        //     DeviceInfo: {
        //       AdditionalInfo: {
        //         serialNumber: true,
        //         fingerPrint: true,
        //         listTerminals: true,
        //       },
        //     },
        //     TransactionHandle,
        //   },
        // }).then((x) => {
        //   console.log('pavo.intent', JSON.stringify(x));
        // });
        this.glb.closeLoading();
        resolve(true);
      } catch (error) {
        console.log('connect Error', error);
        reject(error);
      }
    });
  }
  async sale(folio: Folio, grandBankTotal?: any) {
    grandBankTotal; //reserved
    const folioTotal = this.folioQuery.total;
    const formattedSale = formatSale(
      folio,
      folioTotal,
      this._TransactionHandle
    );
    console.log(formattedSale);
    this._TransactionHandle = formattedSale.TransactionHandle;

    return new Promise(async (resolve, reject) => {
      try {
        //   await CapacitorIntents.sendBroadcastIntent({
        //     action: PavoAndroidIntents.CompleteSale,
        //     value: {
        //       Sale: formattedSale.Sale,
        //       TransactionHandle: this._TransactionHandle,
        //     },
        //   })
        PavoPaymentPlugin.launch({
          action: PavoActionType.completeSale,
          package: PavoPackageType.demo,
          flags: [0x10000000], //FLAG_ACTIVITY_NEW_TASK,
          type: 'application/json',
          arguments: {
            Sale: formattedSale.Sale,
          },
        });

        const res = await lastValueFrom(this.saleBehavior);
        if (res.status == 'success') {
          this.glb.toast(
            'Orwi Pos',
            '"Payment Transaction Successful."',
            'bottom',
            'success'
          );
          resolve(res.data);
        } else {
          this.glb.toast(
            'Orwi Ecr Service',
            res,
            // 'Yazarkasaya gönderilirken bir sorun oluştu.',
            'bottom',
            'danger'
          );
          resolve(false);
        }
        this.glb.closeLoading();
        this.saleBehavior = new BehaviorSubject<{
          status: 'success' | 'error';
          data: any;
        }>(null);
      } catch (error) {
        console.log('sale Error', error);
        this.glb.toast(
          'Orwi Ecr Service',
          new IonicSafeString(error),
          // 'Yazarkasaya gönderilirken bir sorun oluştu.',
          'bottom',
          'danger'
        );
        resolve(false);
        reject(error);
      }
    });
  }

  printXReport(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this._TransactionHandle.TransactionSequence = +1;
        // await CapacitorIntents.sendBroadcastIntent({
        //   action: PavoAndroidIntents.CreateReport,
        //   value: {
        //     ReportType: 'XReport',
        //     TransactionHandle: this._TransactionHandle,
        //   },
        // });

        await PavoPaymentPlugin.launch({
          action: PavoActionType.createReport,
          package: PavoPackageType.demo,
          flags: [0x10000000], //FLAG_ACTIVITY_NEW_TASK,
          type: 'application/json',
          arguments: {
            ReportType: 'XReport',
            TransactionHandle: this._TransactionHandle,
          },
        });
        resolve(true);
        this.glb.closeLoading();
      } catch (error) {
        console.log('PrintXRaport Error', error);
        reject(error);
      }
    });
  }

  printZReport(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this._TransactionHandle.TransactionSequence = +1;
        // await CapacitorIntents.sendBroadcastIntent({
        //   action: PavoAndroidIntents.CreateReport,
        //   value: {
        //     ReportType: 'ZReport',
        //     TransactionHandle: this._TransactionHandle,
        //   },
        // });
        await PavoPaymentPlugin.launch({
          action: PavoActionType.createReport,
          package: PavoPackageType.demo,
          flags: [0x10000000], //FLAG_ACTIVITY_NEW_TASK,
          type: 'application/json',
          arguments: {
            ReportType: 'ZReport',
            TransactionHandle: this._TransactionHandle,
          },
        });

        resolve(true);
        this.glb.closeLoading();
      } catch (error) {
        console.log('PrintXRaport Error', error);
        reject(error);
      }
    });
  }

  syncDepartments: (departments?: any) => Promise<any>;
  socketId: string;
  ipAddress: string;
  port: number;
  init(ipAddress: string, port: number): EcrBaseService {
    console.log('intent', 'initOK');
    this.ipAddress = ipAddress;
    this.port = port;
    console.log(port, ipAddress);
    return this;
  }
}
