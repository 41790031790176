import { Component, OnInit } from '@angular/core';
import { FolioStore } from '../../state/folio.store';
import { ParametersQuery } from 'src/app/modules/parameters/state/parameters.query';
import { TranslocoService } from '@ngneat/transloco';
import { ModalService } from 'src/app/services/helpers/modal.service';

@Component({
  selector: 'pax',
  templateUrl: './pax.component.html',
  styleUrls: ['./pax.component.scss'],
})
export class PaxComponent implements OnInit {
  child: number = 0
  male: number = 0
  female: number = 0
  isPaxRequired: boolean = false;

  constructor(
    public folioStore: FolioStore,
    private parametersQuery: ParametersQuery,
    private transloco: TranslocoService,
    private modalService: ModalService
  ) {
    this.isPaxRequired = this.parametersQuery.getValue()?.posParameters?.globalParameters?.paxSelectAutoShow ?? false;
  }

  ngOnInit() {
    this.setStartingSettings();
  }

  setStartingSettings() {
    this.folioStore.getValue().activeFolio.paxChild == undefined ? this.child = 0 : this.child = this.folioStore.getValue().activeFolio.paxChild;
    this.folioStore.getValue().activeFolio.paxFemale == undefined ? this.female = 0 : this.female = this.folioStore.getValue().activeFolio.paxFemale;
    this.folioStore.getValue().activeFolio.paxMale == undefined ? this.male = 0 : this.male = this.folioStore.getValue().activeFolio.paxMale;
  }

  pinFormatter(value: number) {
    return `${value}`;
  }

  pax(v, c) {
    let value;
    v.target.classList[0] == 'input' ? value = v.target.value : value = v.detail.value;
    if (Number(value) > 15) {
      value = 15;
    }
    if (c == "c") {
      this.child = value
      this.folioStore.getValue().activeFolio.paxChild = this.child
    } else if (c == "f") {
      this.female = value
      this.folioStore.getValue().activeFolio.paxFemale = this.female
    } else {
      this.male = value
      this.folioStore.getValue().activeFolio.paxMale = this.male
    }
    this.folioStore.getValue().activeFolio.pax = Number(this.folioStore.getValue().activeFolio.paxMale) + Number(this.folioStore.getValue().activeFolio.paxFemale) + Number(this.folioStore.getValue().activeFolio.paxChild)
  }

  getTotalPax(): number {
    return Number(this.child) + Number(this.male) + Number(this.female);
  }

  async showWarningToast() {
    const toast = document.createElement('ion-toast');
    toast.message = this.transloco.translate("Please enter the number of people");
    toast.duration = 2000;
    toast.color = 'danger';
    toast.position = 'bottom';
    document.body.appendChild(toast);
    await toast.present();
  }

  async handleClose() {
    if (this.isPaxRequired && this.getTotalPax() === 0) {
      await this.showWarningToast();
      return false;
    }
    return true;
  }

  async done() {
    if (await this.handleClose()) {
      this.modalService.dismiss()
    }
  }

}
