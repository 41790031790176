import { Injectable } from '@angular/core';
import {
  Attributes,
  CheckInInfo,
  mainActions,
  PaymentType,
  OrwiStore,
} from '../dto/orwi-store';

import * as moment from 'moment';
import {
  Modifier,
  ModifierCover,
  ModifierProduct,
  Product,
  ProductGroup,
} from '../dto/orwi-product';
import { ServiceType } from '../dto/orwi-folio';
import { NavController } from '@ionic/angular';
import { GlobalService } from '../global.service';
import { StoreEventsService } from '../events/store-events.service';
import { TranslocoService } from '@ngneat/transloco';
import { OrwiService } from '../orwi/orwi.service';
import { ITable, TableGroup } from '../dto/tables';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  childStores: OrwiStore[] = [];
  authorizedInactiveStores: OrwiStore[] = [];
  async getChildStores(storeId: string): Promise<OrwiStore[]> {
    try {
      const result = (await this.orwiService.serviceRequestPromise(
        '/api/store/getChildStores',
        { parentId: storeId }
      )) as { response: OrwiStore[] };
      this.childStores = result.response.filter(
        (res) =>
          res.isProduction &&
          this.sessionQuery.licances.some(
            (licance) => licance.orwiStore.id === res.id
          )
      );
      this.authorizedInactiveStores = result.response.filter((res) =>
        this.sessionQuery.licances.some(
          (licance) => licance.orwiStore.id === res.id
        )
      );
      return result.response?.length > 0
        ? result.response.filter((res) => res.isProduction)
        : [];
    } catch (error) {
      return [];
    }
  }

  fetchStore(id): Promise<OrwiStore> {
    return new Promise((resolve, reject) => {
      try {
        this.orwiService
          .serviceRequestPromise(
            '/api/store/storeInfo',
            { id: id },
            this.sessionQuery.token
          )
          .then(async (n: any) => {
            this.store = Object.assign(n.response);
            //await this.fetchTables(id);
            this.fetchMenu(id);
            // this.fetchPaymentTypes(id)
            // this.getStoreDepartments(id)
            resolve(this.store);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }

  getStoreInfo(storeId: string) {
    return new Promise((resolve, reject) => {
      try {
        this.orwiService
          .serviceRequestPromise(
            '/api/store/storeInfo',
            { id: storeId },
            this.sessionQuery.token
          )
          .then(async (n: any) => {
            this.store = Object.assign(n.response);
            resolve(this.store);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }

  async fetchTables(id): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.tableGroups = [];
        this.tables = [];
        this.orwiService
          .serviceRequestPromise(
            '/api/pos/table/getTables',
            { id: id },
            this.sessionQuery.token
          )
          .then((o: any) => {
            // console.log("tables", o)
            let storeTables = Object.assign(o.response);

            for (const iterator of storeTables.groups) {
              let tblgrp = new TableGroup();
              tblgrp.id = iterator.id;
              tblgrp.name = iterator.name;

              tblgrp.info = {
                occupancyRatio: 0,
                tables: storeTables.tables.filter(
                  (x) => x.groupName == iterator.id
                ).length,
                open: 0,
              };
              this.tableGroups.push(tblgrp);
            }

            for (const iterator of storeTables.tables) {
              let tb: ITable = {
                storeId: id,
                info: {
                  pax: 0,
                  waiter: '',
                  duration: 0,
                  amount: 0,
                  lastOrderDuration: 0,
                },
                id: iterator.id,
                name: iterator.name,
                groupId: iterator.groupId,
                state: 'close',
                folios: [],
                selected: false,
                lock: { status: 'unlocked' },
              };

              this.tables.push(tb);
            }

            resolve({ tables: this.tables, tableGroups: this.tableGroups });

            // console.log('getStoraTables', o);
          });
      } catch (err: any) {
        return reject(err);
      }
    });
    // }
  }

  attributes: Attributes[];

  tableId;
  tableName;
  schedulerTime;
  schedulerNote;
  addressId;

  // Store Ana Sayfasından
  // Menü görüntüle
  // Delivery
  // takeaway
  // readytable
  // Qr Okutarak Gelebilirim
  // Deeplink ile gelebilirim

  action: mainActions = 'only-menu';

  activeProduct: Product;
  activeProductGroup: ProductGroup;
  activeStoreId = '';
  storeBanners = [];
  store: OrwiStore;

  paymentTypes: PaymentType[] = [];

  tables: ITable[] = [];
  tableGroups: TableGroup[] = [];

  constructor(
    private se: StoreEventsService,
    private glb: GlobalService,
    private nav: NavController,
    private transloco: TranslocoService,
    private sessionQuery: SessionQuery,
    private orwiService: OrwiService
  ) {
    this.getAttributes();
  }

  fetchStoreInfo(id, menu = true): Promise<OrwiStore> {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.store && this.store.id == id) {
          if (menu) {
            await this.fetchMenu(id, false);
            resolve(this.store);
          } else {
            resolve(this.store);
          }
        } else {
          this.orwiService
            .serviceRequestPromise(
              '/api/store/storeInfo',
              { id: id },
              this.sessionQuery.token
            )
            .then(async (o: any) => {
              this.store = Object.assign({}, new OrwiStore(), o.response);
              if (menu) {
                await this.fetchMenu(id, false);
                resolve(this.store);
              } else {
                resolve(this.store);
              }
            });
        }
      } catch (err: any) {
        reject(err);
      }
    });
  }

  async fetchMenu(id, reload = false) {
    reload; //reserved
    return new Promise((resolve, reject) => {
      try {
        debugger;
        console.log('semih');
        if (
          this.store &&
          this.store.id == id &&
          this.store.products?.length > 0
        ) {
          resolve(this.store);
        } else {
          this.orwiService
            .serviceRequest(
              '/api/store/storeMenu',
              { id: id },
              this.sessionQuery.token
            )
            .subscribe((o: any) => {
              let menu: any = Object.assign(o?.response);
              resolve(menu);
            });
        }
      } catch (err: any) {
        reject(err);
      }
    });
  }

  groupImages = [];

  fetchGroupImages(storeId: string) {
    this.orwiService
      .serviceRequest(
        '/api/image/productGroups',
        { id: storeId },
        this.sessionQuery.token
      )
      .subscribe((o: any) => {
        this.groupImages = Object.assign(o.response);
      });
  }

  getStoreLogo(storeId) {
    return new Promise((resolve, reject) => {
      try {
        this.orwiService
          .serviceRequestPromise(
            '/api/image/imageStore',
            { id: storeId },
            this.sessionQuery.token
          )
          .then((o: any) => {
            resolve(o.response);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }

  // getStoreDepartments(storeId) : Promise<EcrDepartment[]> {
  //   return new Promise((resolve, reject) => {
  //     this.orwiService.serviceRequestPromise('/api/pos/ecrDepartments/getEcrDepartments', { id: storeId }, this.us.token).then((o: any) => {
  //       this.ecrDepartments = o.response
  //       resolve(o.response)
  //     })
  //   })
  // }

  getStoreBanners(storeId) {
    return new Promise((resolve, reject) => {
      try {
        this.orwiService
          .serviceRequest('/api/image/storeBanners', { id: storeId })
          .subscribe((o: any) => {
            this.storeBanners = Object.assign(o.response);
            resolve(this.storeBanners);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }

  getOpenClose(store: OrwiStore) {
    if (!store || !store.workingHours || store.workingHours.length == 0)
      return { text: '00:00' + '/' + '00:00', status: false };

    let s = moment().weekday();
    let ps = store.workingHours[s];

    let hrO = parseInt(ps.open.split(':')[0]);
    let mnO = parseInt(ps.open.split(':')[1]);

    let open = moment().hour(hrO);
    open.minute(mnO);

    let hrC = parseInt(ps.close.split(':')[0]);
    let mnC = parseInt(ps.close.split(':')[1]);

    let close = moment().hour(hrC);
    close.minute(mnC);
    let status = moment().isBetween(open, close);

    this.glb.consolelog(open.toLocaleString(), close.toLocaleString(), status);

    return { text: ps.open + '/' + ps.close, status: status };
  }

  fetchProductImage(itemId, size) {
    let prd: Product = this.store.products.find((z) => z.id == itemId);
    let image = prd.image;
    if (size == 'small') {
      image = prd.smallImage;
    }

    if (
      (image == undefined || image == '' || image.indexOf('temp.svg') != -1) &&
      prd.vat != 99
    ) {
      prd.vat = 99;
      this.orwiService
        .serviceRequest(
          '/api/image/product',
          { id: this.store.id, productId: itemId, size: size },
          this.sessionQuery.token
        )
        .subscribe(
          (o: any) => {
            prd.vat = 0;
            if (o.response == null || o.response == '') {
              image = 'error';
            } else {
              image =
                Object.assign(o.response).base64 == ''
                  ? 'error'
                  : Object.assign(o.response).base64;
            }

            if (size == 'small') {
              prd.smallImage = image;
            } else {
              prd.image = image;
            }

            if (prd.smallImage == 'error') {
              prd.smallImage = '/assets/images/home/400x250.png';
            }

            if (prd.image == 'error') {
              prd.image = '/assets/images/home/400x250.png';
            }
          },
          (e) => {
            e; //reserved
            if (size == 'small') {
              prd.smallImage = 'error';
            } else {
              prd.image = 'error';
            }

            if (prd.smallImage == 'error') {
              prd.smallImage = '/assets/images/home/400x250.png';
            }

            if (prd.image == 'error') {
              prd.image = '/assets/images/home/400x250.png';
            }
          }
        );
    }
    return image;
  }

  getModifiers(
    productID,
    productGroupID,
    st: ServiceType = 'table'
  ): Modifier[] {
    st;
    let groups: ModifierCover[];
    let products: ModifierCover[];
    let combine: Modifier[] = [];
    products = this.store.productModifiers.filter(
      (o) => o.productID == productID
    ); // await Object.assign(this.cs.readDocumentFromMongo('bigchefs/menu-product_modifiers', { productID: productID }).toPromise())
    groups = this.store.productGroupModifiers.filter(
      (o) => o.productGroupID == productGroupID
    ); //await Object.assign(this.cs.readDocumentFromMongo('bigchefs/menu-menu-product_group_modifiers', { productGroupID: productGroupID }).toPromise())
    for (const iterator of products) {
      for (const it of iterator.modif) {
        combine.push(it);
      }
    }
    for (const iterator of groups) {
      for (const it of iterator.modif) {
        combine.push(it);
      }
    }

    let custom = combine.filter((o) => o.type == 'custom');
    let product = combine.filter((o) => o.type == 'product');
    let group = combine.filter((o) => o.type == 'group');

    for (const iterator of product) {
      for (const it of iterator.products) {
        let prd = this.store.products.find((o) => o.id == it.productID);
        if (prd) {
          it.productName = prd.name;
        }
      }
    }

    for (const iterator of group) {
      iterator.products = [];

      let prd = this.store.products.filter(
        (o) => o.group == iterator.productGroup
      );

      for (const it of prd) {
        let mp: ModifierProduct = new ModifierProduct();
        mp.productName = it.name;
        mp.productID = it.id;
        mp.canBeAdded = iterator.canBeAdded;
        mp.detachable = iterator.detachable;
        mp.price = iterator.priceless ? 0 : it.price; //! implement service type?
        mp.priceless = iterator.priceless;
        mp.selected = false;
        iterator.products.push(mp);
      }
    }

    combine = [];
    for (const iterator of custom) {
      iterator.products.map((o) => {
        o.selected = false;
        o.temp_rowID = '';
        o.subModifiers = [];
      });
      combine.push(iterator);
    }
    for (const iterator of product) {
      iterator.products.map((o) => {
        o.selected = false;
        o.temp_rowID = '';
        o.subModifiers = [];
      });
      combine.push(iterator);
    }
    for (const iterator of group) {
      iterator.products.map((o) => {
        o.selected = false;
        o.temp_rowID = '';
        o.subModifiers = [];
      });
      combine.push(iterator);
    }

    return combine;
  }

  storeCheckIn(
    code: string,
    time = undefined,
    note = undefined,
    serviceType = undefined,
    addressId = undefined
  ): Promise<OrwiStore> {
    time;
    note;
    return new Promise((resolve, reject) => {
      this.glb.showLoading('');
      this.orwiService
        .serviceRequestPromise(
          '/api/store/storeCheckIn',
          { qrCode: code, serviceType: serviceType },
          this.sessionQuery.token
        )
        .then(
          async (o: any) => {
            if (o.response) {
              this.addressId = addressId;
              let response: CheckInInfo = o.response;
              this.activeStoreId = response.store.id;

              this.action = response.startAction;

              if (response.startAction == 'coupon') {
                this.initCoupon(response);
              } else if (response.startAction == 'select-address') {
                this.initSelectAddress(response);
              } else if (response.startAction == 'delivery') {
                this.initDelivery(response);
              } else if (response.startAction == 'in-store') {
                this.initInStore(response);
              } else if (response.startAction == 'loyalty') {
              } else if (response.startAction == 'only-menu') {
              } else if (response.startAction == 'payment') {
              } else if (response.startAction == 'pre-paid') {
              } else if (response.startAction == 'self-ordering') {
              } else if (response.startAction == 'take-away') {
                this.initTakeaway(response);
              }
              resolve(response.store);
            }

            if (o.error) {
              this.glb.closeLoading();
              if (o.error.desc == 'table_not_found') {
                this.showNoOrwiQr(code);
              }
            }
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  showNoOrwiQr(code) {
    code; //reserved

    //Tanımlanamayan Qr Code
    let title = this.transloco.translate('Unidentified Qr Code');

    this.glb.toast('', title, 'bottom', 'danger');

    //
    //let message = this.transloco.translate("")
    //this.op.showComponent({ title: title, message: message })
  }

  initCoupon(ci: CheckInInfo) {
    ci; //reserved
  }

  initSelectAddress(ci: CheckInInfo) {
    this.glb.closeLoading();
    this.action = ci.startAction;
    this.store = ci.store;
    this.nav.navigateForward('address-list', { replaceUrl: true });
  }

  initDelivery(ci: CheckInInfo) {
    this.action = ci.startAction;
    this.store = ci.store;
    this.tableId = ci.id;
    this.se.storeChecked.next('');
    this.fetchMenu(ci.store.id).then((o) => {
      o; //reserved
      this.nav.navigateForward('menu-home', { replaceUrl: true });
      this.glb.closeLoading();
    });
  }

  initTakeaway(ci: CheckInInfo) {
    this.action = ci.startAction;
    this.store = ci.store;
    this.tableId = ci.id;
    this.se.storeChecked.next('');
    this.fetchMenu(ci.store.id).then((o) => {
      o; //reserved
      this.nav.navigateForward('menu-home');
      this.glb.closeLoading();
    });
  }

  initInStore(ci: CheckInInfo) {
    this.action = ci.startAction;
    this.store = ci.store;
    this.tableId = ci.id;
    this.tableName = ci.tableName;
    this.se.storeChecked.next('');
    this.fetchMenu(ci.store.id).then((o) => {
      o; //reserved
      this.nav.navigateForward('in-store-home');
      this.glb.closeLoading();
    });
  }

  getAttributes() {
    return new Promise((resolve, reject) => {
      try {
        this.orwiService
          .serviceRequestPromise(
            '/api/category/getAttributes',
            {},
            this.sessionQuery.token
          )
          .then((o: any) => {
            if (o.response) {
              this.attributes = o.response;
            }
            resolve(o.response);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }

  getPointTitle() {
    return this.store?.pointTitle || 'Mekan Puan';
  }

  fetchPaymentTypes(id) {
    return new Promise((resolve, reject) => {
      try {
        this.orwiService
          .serviceRequestPromise(
            '/api/pos/payment/getPaymentTypes',
            { id: id },
            this.sessionQuery.token
          )
          .then((o: any) => {
            if (o.response) {
              this.paymentTypes = o.response;
              resolve(this.paymentTypes);
            }
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }
}
