import { Component, OnInit } from '@angular/core';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { FolioQuery } from '../../state/folio.query';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { FolioService } from '../../state/folio.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { GlobalService } from 'src/app/services/global.service';
import { PrintCoverService } from 'src/app/services/printer/print-cover.service';
import { FolioStore } from '../../state/folio.store';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'payment-type-list',
  templateUrl: './payment-type-list.component.html',
  styleUrls: ['./payment-type-list.component.scss'],
})
export class PaymentTypeListComponent implements OnInit {
  constructor(
    private OrwiStoreQuery: OrwiStoreQuery,
    private fq: FolioQuery,
    private fs: FolioService,
    private ms: ModalService,
    private glb: GlobalService,
    private printerService: PrintCoverService,
    private folioStore: FolioStore,
    private nav: NavController
  ) {}
  activeFolio: Folio = this.fq.getValue().activeFolio;

  ngOnInit() {}

  getPaymentType() {
    return this.OrwiStoreQuery.getValue().payTypes;
  }

  async chooseType(type) {
    console.log(type);
    this.activeFolio.deliveryPaymentType = type.payType;
    await this.fs._posSaveFolio(this.activeFolio);
    this.ms.dismiss({ selected: true });
    this.glb.showLoading();
    await this.printerService.printUnit(this.folioStore.getValue().activeFolio);
    await this.fs._posDoneFolio();
    this.glb.closeAllLoading();
    this.nav.back();
  }
}
