import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { FolioRow, ServiceType } from 'src/app/services/dto/orwi-folio';

export interface SideMenu {
  id: sideMenuId;
  selected?: boolean;
  route: string;
  title: string;
  icon: string;
  selectable?: boolean;
  menuType: 'pos' | 'folio';
  disabled?: boolean;
  badge?: string;
  hide?: boolean;
}

export interface SideMenuState {
  posMenu: SideMenu[];
  folioMenu: SideMenu[];
  selectedPosMenu: SideMenu;
  selectedFolioMenu: SideMenu;
  servicetType: ServiceType;
  deleteFolioRow: FolioRow;
}

export type sideMenuId =
  | 'open-folios'
  | 'table-service'
  | 'self-service'
  | 'take-away'
  | 'web-order'
  | 'delivery'
  | 'lock'
  | 'change'
  | 'combine'
  | 'gift'
  | 'delete'
  | 'note'
  | 'loyalty'
  | 'modifier'
  | 'qty'
  | 'transfer'
  | 'price'
  | 'pax'
  | 'eft'
  | 'sendEft';

export function createInitialState(): SideMenuState {
  let posMenu: SideMenu[] = [];

  posMenu.push({
    menuType: 'pos',
    id: 'open-folios',
    selected: false,
    route: '',
    title: 'open checks',
    icon: 'alarm',
  });
  posMenu.push({
    menuType: 'pos',
    id: 'table-service',
    selected: false,
    route: '',
    title: 'table service',
    icon: 'room_service',
  });
  posMenu.push({
    menuType: 'pos',
    id: 'self-service',
    selected: false,
    route: '',
    title: 'self service',
    icon: 'directions_run',
  });
  posMenu.push({
    menuType: 'pos',
    id: 'take-away',
    selected: false,
    route: '',
    title: 'take-away',
    icon: 'fastfood',
  });
  // posMenu.push({
  //   menuType: 'pos',
  //   id: 'web-order',
  //   selected: false,
  //   route: '',
  //   title: 'online orders',
  //   icon: 'rss_feed',
  // });
  posMenu.push({
    menuType: 'pos',
    id: 'delivery',
    selected: false,
    route: 'delivery-address-form',
    title: 'delivery',
    icon: 'moped',
  });

  posMenu.push({
    menuType: 'pos',
    id: 'change',
    selected: false,
    route: '',
    title: 'change',
    icon: 'swap_horiz',
  });
  posMenu.push({
    menuType: 'pos',
    id: 'combine',
    selected: false,
    route: '',
    title: 'combine',
    icon: 'call_merge',
  });
  posMenu.push({
    menuType: 'pos',
    id: 'eft',
    selected: false,
    route: '',
    title: 'Eft Pos',
    icon: 'call_merge',
  });
  //  posMenu.push({
  //   menuType: 'pos',
  //   id: 'lock',
  //   selected: false,
  //   route: '/lock-screen',
  //   title: 'lock',
  //   icon: 'lock_open',
  // });


  let folioMenu: SideMenu[] = [];

  return {
    selectedFolioMenu: null,
    selectedPosMenu: null,
    posMenu: posMenu,
    folioMenu: folioMenu,
    servicetType: 'table',
    deleteFolioRow: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'side-menu' })
export class SideMenuStore extends Store<SideMenuState> {
  constructor(
  ) {
    super(createInitialState());
  }
}
