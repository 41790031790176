import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardService } from './modules/session/state/guard.service';
import { UserInfoComponent } from './modules/folio/components/user-info/user-info.component';

const routes: Routes = [
  {
    path: 'folio-information',
    component: UserInfoComponent,
  },
  {
    path: 'food-app-integration',
    loadChildren: () =>
      import(
        './modules/food-apps-integration/food-apps-integration.module'
      ).then((m) => m.FoodAppsIntegrationModule),
    canActivate: [GuardService],
  },

  {
    path: 'folio-list',
    loadChildren: () =>
      import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [GuardService],
  },

  {
    path: 'courier-home',
    loadChildren: () =>
      import('./modules/courier/courier.module').then((m) => m.CourierModule),
    canActivate: [GuardService],
  },

  {
    path: 'parameters',
    loadChildren: () =>
      import('./modules/parameters/parameters.module').then(
        (m) => m.ParametersModule
      ),
    canActivate: [GuardService],
  },

  {
    path: 'pos-users-list',
    loadChildren: () =>
      import('./modules/pos-users/pos-users.module').then(
        (m) => m.PosUsersModule
      ),
    canActivate: [GuardService],
  },

  {
    path: 'pos-home',
    loadChildren: () =>
      import('./modules/pos/pos.module').then((m) => m.PosModule),
    canActivate: [GuardService],
  },

  {
    path: 'kitchen-display-home',
    loadChildren: () =>
      import('./modules/kitchen-display/order.module').then(
        (m) => m.KitchenDispalyModule
      ),
    canActivate: [GuardService],
  },

  // {
  //   path: 'pos-folio',
  //   loadChildren: () => import('./pages/pos/pos-folio/pos-folio.module').then(m => m.PosFolioPageModule),
  //   canActivate: [GuardService]
  // },
  // {
  //   path: 'pos-payment',
  //   loadChildren: () => import('./pages/pos/pos-payment/pos-payment.module').then(m => m.PosPaymentPageModule),
  //   canActivate: [GuardService]
  // },

  // {
  //   path: 'delivery-form',
  //   loadChildren: () => import('./modules/delivery/components/delivery-form/delivery-form.module').then(m => m.DeliveryFormPageModule),
  //   canActivate: [GuardService]
  // },
  {
    path: 'table-definitions',
    loadChildren: () =>
      import(
        './pages/pos-settings/table-definitions/table-definitions.module'
      ).then((m) => m.TableDefinitionsModule),
    canActivate: [GuardService],
  },
  {
    path: 'payment-definitions',
    loadChildren: () =>
      import(
        './pages/pos-settings/payment-definitions/payment-definitions.module'
      ).then((m) => m.PaymentDefinitionsModule),
    canActivate: [GuardService],
  },
  {
    path: 'discount-definitions',
    loadChildren: () =>
      import(
        './pages/pos-settings/discount-definitions/discount-definitions.module'
      ).then((m) => m.DiscountDefinitionsModule),
    canActivate: [GuardService],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'product-definitions',
    loadChildren: () =>
      import(
        './pages/pos-settings/product-definitions/product-definitions.module'
      ).then((m) => m.ProductDefinitionsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'gift-definitions',
    loadChildren: () =>
      import(
        './pages/pos-settings/gift-definitions/gift-definitions.module'
      ).then((m) => m.GiftDefinitionsModule),
    canActivate: [GuardService],
  },
  {
    path: 'integration-purchase',
    loadChildren: () =>
      import('./pages/integration-purchase/integration-purchase.module').then(
        (m) => m.IntegrationPurchasePageModule
      ),
    canActivate: [GuardService],
  },

  {
    path: 'printer-definitions',
    loadChildren: () =>
      import(
        './pages/pos-settings/printer-definitions/printer-definitions.module'
      ).then((m) => m.PrinterDefinitionsModule),
    canActivate: [GuardService],
  },
  {
    path: 'delete-product-reasons',
    loadChildren: () =>
      import(
        './pages/pos-settings/delete-product-reasons/delete-product-reasons.module'
      ).then((m) => m.DeleteProductReasonsModule),
    canActivate: [GuardService],
  },

  {
    path: 'brands-list',
    loadChildren: () =>
      import('./modules/brands/brands.module').then((m) => m.BrandsModule),
    canActivate: [GuardService],
  },
  {
    path: 'branch-list',
    loadChildren: () =>
      import('./modules/branches/branches.module').then(
        (m) => m.BranchesModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'component-list',
    loadChildren: () =>
      import('./components/components.module').then((m) => m.ComponentsModule),
    canActivate: [GuardService],
  },
  {
    path: 'hourly-sales-reports',
    loadChildren: () =>
      import('./modules/hourly-sales-reports/hourly-sales-reports.module').then(
        (m) => m.HourlySalesReportsPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'order-time-report',
    loadChildren: () =>
      import('./modules/order-time-report/order-time-report.module').then(
        (m) => m.OrderTimeReportPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'product-based-sales-report',
    loadChildren: () =>
      import(
        './modules/product-based-sales-report/product-based-sales-report.module'
      ).then((m) => m.ProductBasedSalesReportPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'store-time-report',
    loadChildren: () =>
      import(
        './modules/reports/store-time-report/store-time-report.module'
      ).then((m) => m.StoreTimeReportPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'cancellation-reports',
    loadChildren: () =>
      import(
        './modules/reports/cancellation-reports/cancellation-reports.module'
      ).then((m) => m.CancellationReportsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'store-sync-report',
    loadChildren: () =>
      import(
        './modules/reports/store-sync-report/store-sync-report.module'
      ).then((m) => m.StoreSyncReportPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'price-management',
    loadChildren: () =>
      import('./pages/price-management/price-management.module').then(
        (m) => m.PriceManagementPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'payment-type-sale-reports',
    loadChildren: () =>
      import(
        './modules/payment-type-sale-reports/payment-type-sale-reports.module'
      ).then((m) => m.PaymentTypeSaleReportsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [GuardService],
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('./modules/backOffice/inventory/inventory.module').then(
        (m) => m.InventoryPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'invoice',
    loadChildren: () =>
      import('./modules/backOffice/invoice/invoice.module').then(
        (m) => m.NewInvoicePageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'inbound-invoice',
    loadChildren: () =>
      import(
        './modules/backOffice/invoice/components/inbound-invoice/inbound-invoice.module'
      ).then((m) => m.InboundInvoicePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'outbound-invoice',
    loadChildren: () =>
      import(
        './modules/backOffice/invoice/components/outbound-invoice/outbound-invoice.module'
      ).then((m) => m.OutboundInvoicePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'new-order',
    loadChildren: () =>
      import(
        './modules/backOffice/central-order/new-order/new-order.module'
      ).then((m) => m.NewOrderPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'mobile-new-order',
    loadChildren: () =>
      import(
        './modules/backOffice/central-order/mobile-new-order/mobile-new-order.module'
      ).then((m) => m.MobileNewOrderPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'my-orders',
    loadChildren: () =>
      import(
        './modules/backOffice/central-order/my-orders/my-orders.module'
      ).then((m) => m.MyOrdersPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'accept-goods',
    loadChildren: () =>
      import(
        './modules/backOffice/central-order/accept-goods/accept-goods.module'
      ).then((m) => m.AcceptGoodsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'warehouse-transfer',
    loadChildren: () =>
      import(
        './modules/backOffice/warehouse-transfer/warehouse-transfer.module'
      ).then((m) => m.WarehouseTransferPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'staff-meal-actions',
    loadChildren: () =>
      import('./modules/backOffice/staff-meal/staff-meal.module').then(
        (m) => m.StaffMealActionsPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'waste-loss-actions',
    loadChildren: () =>
      import('./modules/backOffice/waste-loss/waste-loss.module').then(
        (m) => m.WasteLossActionsPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'production-actions',
    loadChildren: () =>
      import('./modules/backOffice/production/production.module').then(
        (m) => m.ProductionPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'fragmentation-actions',
    loadChildren: () =>
      import('./modules/backOffice/fragmentation/fragmentation.module').then(
        (m) => m.FragmentationPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'sales-production',
    loadChildren: () =>
      import(
        './modules/backOffice/sales-production/sales-production.module'
      ).then((m) => m.SalesProductionPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'stock-transactions',
    loadChildren: () =>
      import(
        './modules/backOffice/stock-transactions/stock-transactions.module'
      ).then((m) => m.StockTransactionsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'store-sync-report',
    loadChildren: () => import('./modules/reports/store-sync-report/store-sync-report.module').then( m => m.StoreSyncReportPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
