<ion-grid>
  <ion-row>
    <ion-col>
       <h3 style="text-align: center;">{{'Lock Screen' | transloco}}</h3>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-item id="pin-input" lines="none" class="pin-input animate__animated">
        <ion-input
          [clearInput]="true"
          [formControl]="pinControl"
          type="password"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col
      class="pin-number noselect"
      size="4"
      *ngFor="let number of numberAndSymbols"
      (click)="changeInput(number)"

    >
      <div [ngClass]="{ 'done-input': number == 'checkmark' }">
        <div style="color:black;" *ngIf="isNumeric(number)">{{ number }}</div>
        <ion-icon
          *ngIf="!isNumeric(number)"
          [icon]="number + '-sharp'"
        ></ion-icon>
      </div>
    </ion-col>
  </ion-row>
  <!-- <ion-row>
    <ion-col>
      <ion-button expand="block" (click)="logOut()">
        {{'New User Login' | transloco }}
      </ion-button>
    </ion-col>
  </ion-row> -->
</ion-grid>
