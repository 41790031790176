<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'Z-Report Form' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
   <ion-button (click)="close()">
    <span class="material-icons">
      close
      </span>
   </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

<ion-grid  [formGroup]="form">

  <ion-row [formGroup]="form">
    <ion-col size="12" sizeMd="12">
      <ion-item lines="none" (click)="showPickerStart = true">
        <ion-label> {{ "start date" | transloco }} </ion-label>
        <ion-text slot="end">{{ form.value.startDate | date: 'dd.M.yyyy HH:mm'}}</ion-text>
        <!-- <ion-datetime
          displayFormat="DD MM YYYY"
          placeholder="DD MM YYYY"
          [doneText]="'done' | transloco"
          [cancelText]="'cancel' | transloco"
          formControlName="startDate"
          [max]="form.get('endDate').value || undefined"
        ></ion-datetime> -->
      </ion-item>
      <ion-datetime
      [disabled]="!ssoService.checkPermission('z-report-create-limited')"
        #datetimePickerStart
        *ngIf="showPickerStart"
        show-default-buttons="true"
        displayFormat="DD MM YYYY"
        placeholder="DD MM YYYY"
        [doneText]="'done' | transloco"
        [cancelText]="'cancel' | transloco"
        formControlName="startDate"
        [max]="form.get('endDate').value || undefined"
        size="cover"
        (ionChange)="onDatetimeConfirm('startDate', $event)"
        (ionCancel)="onDatetimeCancel()"
        hourCycle="h23"
        [locale]="transloco.getActiveLang()"
        >
      <span slot="time-label">{{'time' | transloco}}</span>
      </ion-datetime>

      <ion-text color="danger" *ngIf="getError('startDate')">
        <small>{{ "field required" | transloco }}</small>
      </ion-text>
    </ion-col>
    <ion-col size="12" sizeMd="12">
      <ion-item lines="none" (click)="showPickerEnd = true">
        <ion-label> {{ "end date" | transloco }} </ion-label>
        <ion-text slot="end">{{ form.value.endDate | date: 'dd.M.yyyy HH:mm'}}</ion-text>
      </ion-item>
      <ion-datetime
      [disabled]="!ssoService.checkPermission('z-report-create-limited')"
          #datetimePickerEnd
          *ngIf="showPickerEnd"
          show-default-buttons="true"
          displayFormat="DD MM YYYY"
          placeholder="DD MM YYYY"
          [doneText]="'done' | transloco"
          [cancelText]="'cancel' | transloco"
          formControlName="endDate"
          [min]="form.get('startDate').value || undefined"
          size="cover"
          (ionChange)="onDatetimeConfirm('endDate', $event)"
          (ionCancel)="onDatetimeCancel()"
          hourCycle="h23"
          [locale]="transloco.getActiveLang()"
        >
        <span slot="time-label">{{'time' | transloco}}</span>
        </ion-datetime>

      <ion-text color="danger" *ngIf="getError('endDate')">
        <small>{{ "field required" | transloco }}</small>
      </ion-text>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12" sizeMd="12">
      <!-- <ng-select
        formControlName="printer"
        [items]="printers"
        bindLabel="name"
        bindValue="ip"
      >
      </ng-select> -->
      <ion-list style="display: flex; align-items: center; padding-left: 15px;">
        <!-- <ion-item lines="none">
          <ion-label> {{ "printer" | transloco }} </ion-label>
        </ion-item> -->
        <ion-select interface="action-sheet"  [okText]="'Done' | transloco"
        [cancelText]="'Cancel' | transloco" style="width: calc(100% - 40px);" placeholder="{{'printer' | transloco}}" formControlName="printer">
          <ion-select-option *ngFor="let item of printers" [value]="item.id">{{item.name}}</ion-select-option>
        </ion-select>
          <ion-icon style="font-size: 20px; font-weight: bold; margin-left: 8px;"
            (click)="clearSelection()" *ngIf="form.get('printer').value"
            name="close-outline">
          </ion-icon>
      </ion-list>
      <ion-text color="danger" *ngIf="getError('printer')">
        <small>{{ "field required" | transloco }}</small>
      </ion-text>
    </ion-col>
  </ion-row>
  <ion-row>

  </ion-row>
  <ion-col size="12" sizeMd="12">
    <ion-checkbox
    [disabled]="!ssoService.checkPermission('z-report-product-detail')"
    [checked]="isDetailed"
    (ionChange)="toggleDetail($event)"
  > {{'product detailed report' | transloco}} </ion-checkbox>
  </ion-col>
  <ion-row>
    <ion-col size="12" sizeMd="12">
      <ion-button (click)="send()" expand="block">
        {{ "zreport" | transloco }}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
</ion-content>
