import { Injectable } from '@angular/core';
import {
  Warehouse,
  Branch,
  InventoryUnit,
  InvoiceVehicle,
  InvoiceShipper,
  Cash,
} from 'kerzz-base';
import { GlobalService } from 'src/app/services/global.service';
import { BackOfficeService } from '../../shared/services/back-office.service';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { BackOfficeStore } from './backOfiice.global.store';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridComponent } from 'devextreme-angular';
import { saveAs } from 'file-saver';
import { TranslocoService } from '@ngneat/transloco';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
@Injectable({ providedIn: 'root' })
export class BackOfficeSharedService {
  warehouses: Warehouse[] = [];
  allWarehouses: Warehouse[] = [];
  branchList: Branch[] = [];
  inventoryUnit: InventoryUnit[] = [];
  selectedVehicle: InvoiceVehicle;
  selectedShipper: InvoiceShipper;
  constructor(
    private glb: GlobalService,
    private backOfficeStore: BackOfficeStore,
    private backOfficeService: BackOfficeService,
    private storeQuery: OrwiStoreQuery,
    private transloco: TranslocoService,
    private ssoService: SSOSessionService
  ) {}

  //DEPOLARI GETIRIR
  getWarehouses(): Promise<Warehouse[]> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/store/getWarehouses', {
          storeId: this.storeQuery._orwiStore.id,
        })
        .then((o: any) => {
          console.log('warehouses', o);
          this.warehouses = o;
          this.backOfficeStore.update({ warehouses: this.warehouses });
          this.glb.closeAllLoading()
          resolve(this.warehouses);
        })
        .catch((e) => {
          this.glb.closeAllLoading()
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No warehouses can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  //DEPOLARI GETIRIR
  getAllWarehouses(): Promise<Warehouse[]> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/store/getAllWarehouses', {
          storeId: this.storeQuery._orwiStore.id,
        })
        .then((o: any) => {
          console.log('warehouses', o);
          this.allWarehouses = o;
          this.backOfficeStore.update({ allWarehouses: this.allWarehouses });
          this.glb.closeAllLoading()
          resolve(this.warehouses);
        })
        .catch((e) => {
          this.glb.closeAllLoading()
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No warehouses can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            this.glb.closeAllLoading()
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  //ŞUBELERI GETIR
  getBranches(showInactive: boolean): Promise<Branch[]> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/store/getBranches', {
          storeId: this.storeQuery._orwiStore.id,
          showInactives: showInactive,
        })
        .then((o: Branch[]) => {
          this.branchList = o;
          this.backOfficeStore.update({ branchList: this.branchList });
          resolve(this.branchList);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No branch can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  //BIRIMLERI GETIRIR
  getInventoryUnits(): Promise<InventoryUnit[]> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/inventory-unit/getAllInventoryUnits', {
          storeId: this.storeQuery._orwiStore.id,
        })
        .then((o: InventoryUnit[]) => {
          this.backOfficeStore.update({
            inventoryUnits: o,
          });
          console.log('units', o);
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No inventory items can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  getShippers(): Promise<InvoiceShipper[]> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/invoice/getInvoiceShippers', {
          storeId: this.storeQuery._orwiStore.id,
        })
        .then((o: InvoiceShipper[]) => {
          this.backOfficeStore.update({
            shipperList: o,
          });
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No shipper items can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  getVehicles(): Promise<InvoiceVehicle[]> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/invoice/getInvoiceVehicles', {
          storeId: this.storeQuery._orwiStore.id,
        })
        .then((o: InvoiceVehicle[]) => {
          this.backOfficeStore.update({
            vehicleList: o,
          });
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No vehicle items can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }

  getCashRegisters(): Promise<Cash[]> {
    return new Promise((resolve, reject) => {
      this.backOfficeService
        .serviceRequestPromise('/store/getCashs', {
          storeId: this.storeQuery._orwiStore.id,
        })
        .then((o: Cash[]) => {
          this.backOfficeStore.update({
            cashRegisters: o,
          });
          resolve(o);
        })
        .catch((e) => {
          if (e?.error?.statusCode == 500) {
            this.glb.toast(
              'Not Found',
              'No cash registers can be found',
              'middle',
              'warning'
            );
            reject(e);
          } else {
            if (e?.error?.message !== 'setting_not_founnd') {
              this.glb.toast('Error', e?.error?.message, 'middle', 'warning');
            }
            reject(e);
          }
        });
    });
  }
  exportExcel(datagrid: DxDataGridComponent, documentName: string) {
    const workbook = new Workbook();
    documentName = this.transloco.translate(documentName);
    const worksheet = workbook.addWorksheet(documentName);
    exportDataGrid({
      component: datagrid.instance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          documentName + ' - ' + new Date().getTime() + '.xlsx'
        );
      });
    });
  }

  exportXlsx<T = {}>(
    data: T[],
    documentName: string,
    columns: { header: string; key: keyof T }[] = []
  ) {
    const workbook = new Workbook();
    documentName = this.transloco.translate(documentName);
    const worksheet = workbook.addWorksheet(documentName);
    columns = columns.filter(column => 
      !(column.key === 'unitPrice' && this.ssoService.checkPermission('cost-cannot-see-the-costs'))
    );

    if (columns.length > 0) {
      worksheet.columns = columns.map((col) => ({
        header: col.header,
        key: col.key as string,
      }));
  
      worksheet.addRows(
        data.map((el) => {
          const newObj: { [key: string]: any } = {};
          columns.forEach((col) => {
            newObj[col.key as string] = el[col.key];
          });
          return newObj;
        })
      );
  
      worksheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          maxLength = Math.max(maxLength, columnLength);
        });
        column.width = Math.max(maxLength, 10);
      });
  
      const currencyColumns = ['unitPrice', 'total', 'vatRateTotal', 'grandTotal'];
      currencyColumns.forEach((colKey) => {
        const colIndex = columns.findIndex((col) => col.key === colKey) + 1;
        if (colIndex > 0) {
          worksheet.getColumn(colIndex).numFmt = '0.00';
        }
      });
    } else {
      worksheet.addRows(data);
    }
  
    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${documentName} - ${new Date().getTime()}.xlsx`
      );
    });
  }
  
}
