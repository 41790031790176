import { EventEmitter, OnDestroy } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ChangeCombineModalType } from 'src/app/modules/tables/model/table.model';
import { TableGroup, ITable, TableState } from 'src/app/services/dto/tables';
import { GlobalService } from 'src/app/services/global.service';
import { TableQuery } from '../../state/table.query';
import { Subscription } from 'rxjs';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { TableService } from '../../state/table.service';

@Component({
  selector: 'orwi-table-list',
  templateUrl: './orwi-table-list.component.html',
  styleUrls: ['./orwi-table-list.component.scss'],
})
export class OrwiTableListComponent implements OnInit, OnDestroy {
  selectedTableGroup: TableGroup;
  selectedTable: ITable;
  openFolios: ITable[] = []
  private folioSubscription: Subscription;

  @Input() set ShowType(value: ChangeCombineModalType) {
    this.showType = value == ChangeCombineModalType.Change ? 'close' : 'open';
  }
  @Input() movedTable: ITable;
  @Output() onSelect: EventEmitter<ITable> = new EventEmitter<ITable>();
  showType: TableState = 'close';
  constructor(
    public tq: TableQuery,
    public glb: GlobalService,
    private folioQuery: FolioQuery,
    private tableService: TableService
  ) {}

  ngOnInit() {
    console.log(this.showType);
    this.folioSubscription = this.folioQuery.openFolios$.subscribe(folios => {
      folios.forEach(folio => {
        if(folio.type !== 'table'){
          this.openFolios.push(this.tableService.convertFolioToTable(folio))
        }
      });
    });
    console.log(this.openFolios)
  }

  ngOnDestroy(): void {
    if (this.folioSubscription) {
      this.folioSubscription.unsubscribe();
    }
  }


  tableGroupSelected(tableGroup: TableGroup) {
    this.selectedTableGroup = tableGroup;
    this.tableSelected(null);
  }
  async tableSelected(tb: ITable) {
   
    this.selectedTable = tb;
    this.onSelect.emit(tb);
  }
  isHide(e: ITable): boolean {
    return this.movedTable?.id == e.id;
  }
  isSelected(e: ITable): boolean {
    return this.selectedTable?.id == e.id;
  }
  isShowTableGroup(e: TableGroup): boolean {
    const tables: ITable[] = this.tq
      .getValue()
      .tables.filter((el) => el.groupId == e.id);

    return !tables.some((el) => el.state == this.showType);
  }
  clear() {
    this.tableSelected(null);
  }

}
