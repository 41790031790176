import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'orwi-folio-button',
  templateUrl: './orwi-folio-button.component.html',
  styleUrls: ['./orwi-folio-button.component.scss'],
})
export class OrwiFolioButtonComponent implements OnInit {
  @Input() active: boolean = false;
  @Input() folio: Folio;
  @Output() onClickInfo: EventEmitter<Folio> = new EventEmitter<Folio>();
  @Output() onSelect: EventEmitter<Folio> = new EventEmitter<Folio>();
  constructor(
    private globalService: GlobalService
  ) { }

  ngOnInit() { }

  info(e, folio) {
    e.preventDefault();
    e.stopPropagation();
    this.onClickInfo.emit(folio);
  }

  select() {
   
    if (this.folio.eftPos) {
      this.globalService.toast("", "You cannot make transactions on the ticket sent via EFT Pos.", "bottom", "warning")
      return
    }
    else {
      this.onSelect.emit(this.folio);
    }

  }
}
