import { inject, Injectable } from '@angular/core';
import {
  addRxPlugin,
  createRxDatabase,
  // isRxDocument,
  MangoQuerySelectorAndIndex,
  MangoQuery as MQ,
  RxDatabase,
} from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump';
addRxPlugin(RxDBCleanupPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBJsonDumpPlugin);

import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
@Injectable({ providedIn: 'root' })
export class RxDBService {
  constructor() {}
  fileSystem = inject(FileSystemService);

  myDatabase: RxDatabase;

  async initDB() {
    return new Promise(async (resolve) => {
      if (Capacitor.isNativePlatform()) {
        this.fileSystem.deleteFile('invoicelist');
      }
      const activeStore = localStorage.getItem('activeStore');
      const databases = await indexedDB.databases();
      databases.forEach(async (database) => {
        console.log('RXDB DATABASE NAME:', database.name);
        if (database.name.includes('kerzz-cloud')) {
          indexedDB.deleteDatabase(database.name);
        }
      });
      console.log('RXDB STORENAME', activeStore);
      setTimeout(async () => {
        this.myDatabase = await createRxDatabase({
          name: 'kerzz-cloud',
          // storage: getRxStorageMemory(),
          storage: getRxStorageDexie(),
        });
        let collections = this.myDatabase?.collections;
        console.log('RXDB:::', collections);
        await this.createModel();
        return resolve(this.myDatabase.collections);
      }, 1000);
    });
  }

  async resetDB() {
    if (Capacitor.isNativePlatform()) {
      this.fileSystem.deleteFile('invoicelist');
    }
    if (!this.myDatabase) return null;
    if (!this.myDatabase.collections?.invoicelist) return null;
    const recordsID = await this.getDataFromCollection('invoicelist');
    console.log('RXDB', [...recordsID.map((rc) => rc.id)]);
    const delRes = await this.myDatabase.collections.invoicelist.bulkRemove([
      ...recordsID.map((rc) => rc.id),
    ]);
    await this.myDatabase.collections.invoicelist.cleanup(0);

    console.log('RXDB', delRes);
  }

  async createModel() {
    console.log('RXDB ', this.myDatabase);
    if (!this.myDatabase) return null;
    if (!this.myDatabase.collections?.invoicelist) {
      this.myDatabase.collections = await this.myDatabase.addCollections({
        invoicelist: {
          schema: {
            version: 0,
            primaryKey: 'id',
            type: 'string',
            properties: {
              barcode: {
                type: 'string',
                maxLength: 100,
              },
              groupName: {
                type: 'string',
              },
              inventoryName: {
                type: 'string',
                maxLength: 100,
              },
              inventoryCode: {
                type: 'string',
                maxLength: 100, // <- the primary key must have set maxLength
              },
              id: {
                type: 'string',
                maxLength: 100, // <- the primary key must have set maxLength
              },
              inventoryType: {
                type: 'string',
              },
              vatRate: {
                type: 'number',
              },
              unitName: {
                type: 'string',
              },
            },
            required: ['barcode', 'inventoryName', 'inventoryCode'],
            indexes: ['barcode', 'inventoryName', 'inventoryCode'],
            internalIndexes: [['barcode', 'inventoryName', 'inventoryCode']],
          },
        },
      });
    }
    return this.myDatabase.collections;
  }

  async countOfData<DocumentType = any>(
    collectionName: CollectionName,
    filter: MangoQuerySelectorAndIndex<DocumentType> = {}
  ): Promise<number> {
    return new Promise(async (resolve) => {
      try {
        // console.log(
        //   'RXDB Collections',
        //   collectionName,
        //   this.myDatabase.collections,
        //   this.myDatabase.collections[collectionName]
        // );

        if (Capacitor.isNativePlatform()) {
          const data = await this.fileSystem.dataCount(collectionName);
          return resolve(data);
        }

        console.time('rxdb countOfData');
        let resolvedData = await this.myDatabase.collections[collectionName]
          .count(filter)
          .exec();
        console.timeEnd('rxdb countOfData');

        return resolve(resolvedData); // Yoksa veri düzgün gelmiyor
      } catch (error) {
        resolve(0);
        console.warn(error);
      }
    });
  }

  async getDataFromCollection<DocumentType = any>(
    collectionName: CollectionName,
    filter: MQ<DocumentType> = {},
    filterType: 'like' | 'exact' = 'exact'
  ): Promise<any[]> {
    return new Promise(async (resolve) => {
      try {
        // console.log(
        //   'RXDB Collections',
        //   collectionName,
        //   this.myDatabase.collections,
        //   this.myDatabase.collections[collectionName]
        // );
        if (Capacitor.isNativePlatform()) {
          const data = await this.fileSystem.getData(
            collectionName,
            filter.selector['barcode'],
            filterType
          );
          return resolve(data);
        }
        console.time('rxdb');
        let resolvedData = await this.myDatabase.collections[collectionName]
          .find(filter)
          .exec();
        console.timeEnd('rxdb');
        return resolve(
          resolvedData
            // .filter((item) => isRxDocument(item))
            .map((item) => item.toMutableJSON()) // Yoksa veri düzgün gelmiyor
        );
      } catch (error) {
        resolve([]);
        console.warn(error);
      }
    });
  }

  async insertDataToCollection(collectionName: CollectionName, data: any) {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log(
        //   'RXDB Collections',
        //   collectionName,
        //   this.myDatabase.collections,
        //   this.myDatabase.collections[collectionName]
        // );
        const collection = this.myDatabase.collections[collectionName];
        const response = await collection.insert(data);
        resolve(response.toMutableJSON());
      } catch (error) {
        reject(error);
      }
    });
  }

  async bulkInsertDataToCollection(
    collectionName: CollectionName,
    data: any[]
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log(
        //   'RXDB Collections',
        //   collectionName,
        //   this.myDatabase.collections,
        //   this.myDatabase.collections[collectionName]
        // );
        if (Capacitor.isNativePlatform()) {
          await this.fileSystem.writeFile(collectionName, data);
          return resolve([]);
        }
        console.time('rxdb bulkInsertDataToCollection');

        const collection = this.myDatabase.collections[collectionName];

        const response = await collection.bulkInsert(data);

        console.timeEnd('rxdb bulkInsertDataToCollection');
        resolve(response.success);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export type CollectionName = 'invoicelist';

@Injectable({
  providedIn: 'root',
})
export class FileSystemService {
  constructor() {
    Filesystem.requestPermissions();
  }
  async writeFile(fileName: CollectionName, data: any) {
    return new Promise(async (resolve) => {
      await Filesystem.writeFile({
        path: fileName + '.json',
        data: JSON.stringify(data),
        directory: Directory.Cache,
        encoding: Encoding.UTF8,
      });
      return resolve(true);
    });
  }

  async dataCount(fileName: CollectionName): Promise<any> {
    return new Promise(async (resolve) => {
      try {
        const file = await Filesystem.readFile({
          path: fileName + '.json',
          directory: Directory.Cache,
          encoding: Encoding.UTF8,
        });
        if (typeof file.data == 'string') {
          const data = JSON.parse(file.data);
          return resolve(data.length);
        }
      } catch (error) {
        return resolve(0);
      }
    });
  }

  async getData(
    fileName: CollectionName,
    filter: any,
    filterType: 'like' | 'exact' = 'exact'
  ): Promise<any> {
    return new Promise(async (resolve) => {
      const file = await Filesystem.readFile({
        path: fileName + '.json',
        directory: Directory.Cache,
        encoding: Encoding.UTF8,
      });
      try {
        if (typeof file.data == 'string') {
          let data: any[] = JSON.parse(file.data);
          let formattedData = [];
          if (filterType == 'exact') {
            let finded = data.find((el) => el.barcode == filter);
            if (finded) {
              formattedData.push(finded);
            }
          } else {
            formattedData = data.filter((el) =>
              this.searchTermInObjects(el, filter)
            );
          }
          return resolve(formattedData);
        }
      } catch (error) {
        return resolve([]);
      }
    });
  }

  async deleteFile(fileName: CollectionName) {
    return new Promise(async (resolve) => {
      try {
        await Filesystem.deleteFile({
          path: fileName + '.json',
          directory: Directory.Cache,
        });
        return resolve(true);
      } catch (error) {
        return resolve(true);
      }
    });
  }

  searchTermInObjects(object: any = {}, searchTerm: string = ''): boolean {
    if (!searchTerm) return true;

    if (Array.isArray(object)) {
      return object.some((elm) => this.searchTermInObjects(elm, searchTerm));
    }

    if (typeof object === 'object') {
      return Object.keys(object).some((el) =>
        this.searchTermInObjects(object[el], searchTerm)
      );
    }
    return String(object)
      .toLocaleLowerCase()
      .includes(String(searchTerm).toLocaleLowerCase());
  }
}
