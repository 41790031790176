import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { OrwiCountry, OrwiCity, OrwiTown } from 'src/app/services/dto/orwi-address';
import { GlobalService } from 'src/app/services/global.service';
import { CountryCodesService } from 'src/app/services/location/country-codes.service';

@Component({
  selector: 'account-add-modal',
  templateUrl: './account-add-modal.component.html',
  styleUrls: ['./account-add-modal.component.scss'],
})
export class AccountAddModalComponent implements OnInit {
  modalRef = inject(ModalController);
  formBuilder = inject(FormBuilder);
  customerService = inject(CustomerService);
  globalService = inject(GlobalService);
  transloco = inject(TranslocoService);  
  countryCodesService = inject(CountryCodesService);

  form = this.formBuilder.group({
    customerName: new UntypedFormControl('', Validators.required),
    gsm: new UntypedFormControl('', Validators.required),
    accountCode: new UntypedFormControl('', Validators.required),
    identityNumber: new UntypedFormControl(null),
    taxNumber: new UntypedFormControl(null),
    taxOffice: new UntypedFormControl(null),
    mail: new UntypedFormControl('', Validators.required),
    city: new UntypedFormControl('', Validators.required),
    town: new UntypedFormControl('', Validators.required),
    address: new UntypedFormControl('', Validators.required),
  });

  get GSM() {
    return this.form.controls.gsm;
  }

  get City(): UntypedFormControl {
    return this.form.controls.city as UntypedFormControl;
  }
  get Town(): UntypedFormControl {
    return this.form.controls.town as UntypedFormControl;
  }


  CountryInfo: {
    countries: OrwiCountry[];
    cities: OrwiCity[];
    towns: OrwiTown[];
  } = {
    countries: [],
    cities: [],
    towns: [],
  };

  constructor() {
    this.City.valueChanges.subscribe((city) => {
      let selectedCity = this.CountryInfo.cities.filter(
        (elm) => elm.name == city
      );
      if (city !== null && city !== undefined && city.length > 2) {
        city = selectedCity[0].code;
      }
      console.log('city triggered', city);
      if (city !== null && city !== undefined && city.length > 0) {
        this.getTown(city);
      } else {
        this.getTown(null);
      }
    });
    this.getCity('TR');
  }

  async getCity(country: string | 'TR') {
    this.CountryInfo.cities = (await this.countryCodesService.getCities(
      country
    )) as OrwiCity[];
    this.CountryInfo.cities.sort((a, b) => +a.code - +b.code);
  }

  async getTown(city: string) {
    if (city !== null && city !== undefined && city.length > 0) {
      this.CountryInfo.towns = (await this.countryCodesService.getTowns(
        'TR',
        city
      )) as OrwiTown[];
      this.CountryInfo.towns.sort((a, b) => +a.code - +b.code);
    }
  }

  ngOnInit() {}

  close() {
    this.modalRef.dismiss();
  }

  save() {
    const form = this.form;
    form.markAllAsTouched();
    if (form.invalid) {
      return;
    }
    this.customerService.save(form.value).then((data) => {
      this.globalService.toast(
        '',
        'Successfully Added',
        'bottom',
        'success'
      );
      this.modalRef.dismiss({
        ...data,
        ...form.value
      });
    });
  }
  
}
