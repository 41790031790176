import { Component, OnInit } from '@angular/core';
import { OrwiDiscontPipe } from 'src/app/helpers/pipes/orwi-discont.pipe';
import { Discount } from 'src/app/services/dto/orwi-definitions';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { DiscontService } from 'src/app/services/folio/discont.service';
import { GlobalService } from 'src/app/services/global.service';
import { PaymentQuery } from '../../state/payment.query';
import { PaymentStore } from '../../state/payment.store';
import { ModalController } from '@ionic/angular';
import { arrayAdd } from '@datorama/akita';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { TranslocoService } from '@ngneat/transloco';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';

@Component({
  selector: 'payment-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
  providers: [OrwiDiscontPipe],
})
export class DiscountComponent implements OnInit {
  discont: Discount = undefined;
  type: 'rate' | 'amount' = 'rate';

  stringValue = '0';
  value = 0;
  constructor(
    public pq: PaymentQuery,
    public ps: PaymentStore,
    private discontService: DiscontService,
    private orwiDiscontPipe: OrwiDiscontPipe,
    private globalService: GlobalService,
    private mc: ModalController,
    private idGen: IdGeneratorService,
    private translocoService: TranslocoService,
    private ssoService: SSOSessionService
  ) {}
  async getDiscontReasons() {
    const reasons: Discount[] = await this.discontService.getDiscountReasons();
    this.ps.update({ predefinedDiscounts: reasons });

    console.log('reasons :', reasons);
  }

  ngOnInit() {
    if (!this.ssoService.checkPermission('payment-use-discount-percent')) {
      this.type = 'amount';
    }
    if (!this.ssoService.checkPermission('payment-use-discount-amount')) {
      this.type = 'rate';
    }
    if (
      !this.ssoService.checkPermission('payment-use-discount-amount') &&
      !this.ssoService.checkPermission('payment-use-discount-percent')
    ) {
      this.type = undefined;
    } else {
      this.select(0);
    }
  }

  async select(value) {
    if (
      !this.ssoService.checkPermission('payment-use-discount-amount') &&
      !this.ssoService.checkPermission('payment-use-discount-percent')
    ) {
      this.globalService.permissionToast();
      return;
    }
    if (value == 'delete') {
      this.stringValue = this.delete(this.stringValue);
      this.value = parseFloat(this.stringValue) || 0;
    } else if (value == 'enter') {
      if (this.value == 0) {
        this.value = 1;
      }
      this.value = 0;
      this.stringValue = '0';
      return;
    } else {
      const percentec: number = this.stringValue.indexOf('.');

      if (this.stringValue == '0') {
        this.stringValue = '';
      }
      this.stringValue += value;

      if (percentec != -1) {
        if (value == '.') {
          this.stringValue = this.removeLast(this.stringValue);
        }
        this.stringValue = this.stringValue.substr(0, percentec + 3);
        this.value = parseFloat(this.stringValue);
      }
      this.value = parseFloat(this.stringValue);
    }

    if (this.stringValue == '') {
      this.stringValue = '0';
    }

    this.refreshDiscont();
  }

  async saveDiscont() {
    const id: string = this.pq.getValue().activeFolioId;
    const activeFolio: Folio = this.pq
      .getValue()
      .folios.find((el) => el.id == id);

    if (!activeFolio) {
      this.globalService.toast(
        'Folio not found.',
        '',
        'middle',
        'danger',
        2000
      );
      return false;
    }
    if (!this.discont) {
      this.globalService.toast(
        'Please select a discont.',
        '',
        'middle',
        'danger',
        2000
      );
      return false;
    }
    this.ps.update(({ history }) => ({
      history: history.filter((item) => item.rowType !== 'discount'),
    }));

    let discount = this.orwiDiscontPipe.transform(
      this.pq.balance,
      this.discont
    );

    // let c = this.orwiDiscontPipe.transform(this.pq.balance, this.discont)
    console.log(
      activeFolio,
      this.discont,
      this.orwiDiscontPipe.transform(activeFolio, this.discont)
    );
    // Save discount to History of Payments
    if (discount > this.pq.balance) {
      let id = this.idGen.generate();
      let pu = this.pq.balance - discount;
      this.ps.update(({ history }) => ({
        history: arrayAdd(history, {
          folioId: this.ps.getValue().activeFolioId,
          desc: this.discont.name,
          value: discount,
          id: id,
          parentId: '0',
          paymentId: this.discont?.id,
          rowType: 'discount',
          payType: 'cash',
        }),
      }));

      this.ps.update(({ history }) => ({
        history: arrayAdd(history, {
          folioId: this.ps.getValue().activeFolioId,
          desc: this.translocoService.translate('Change Money'),
          value: pu,
          id: this.idGen.generate(),
          parentId: id,
          paymentId: this.discont?.id,
          rowType: 'discount',
          payType: 'cash',
        }),
      }));
    } else {
      this.ps.update(({ history }) => ({
        history: arrayAdd(history, {
          folioId: this.ps.getValue().activeFolioId,
          desc: this.discont.name,
          value: discount,
          id: this.idGen.generate(),
          parentId: '0',
          paymentId: this.discont?.id,
          rowType: 'discount',
          payType: 'cash',
        }),
      }));
    }

    // await this.folioService._posSaveFolio(activeFolio, 'low');
    return true;
  }

  delete(stringValue: string): string {
    let value: string = this.removeLast(stringValue);
    if (value[value.length - 1] == '.') {
      value = this.delete(value);
    }
    return value;
  }

  removeLast(str: string) {
    let value: string = str.substr(0, str.length - 1);
    return value;
  }

  cancel() {
    this.ps.update({ discountVisible: false });
    this.mc.dismiss();
  }

  async done() {
    if (!this.discont.amount) this.discont.amount = 0;
    if (!this.discont.rate) this.discont.rate = 0;
    console.log(this.discont.amount, this.discont.rate);
    const errors = [];
    if (this.discont.amount > this.pq.balance || this.discont.rate > 100) {
      // this.globalService.toast(
      //   '',
      //   this.translocoService.translate(
      //     'Discount amount or rate cannot bigger than balance'
      //   ),
      //   'middle',
      //   'warning'
      // );
      // return;
      errors.push(
        this.translocoService.translate(
          'Discount amount or rate cannot bigger than balance'
        )
      );
    }
    if (
      this.discont.rate == 100 ||
      this.discont.amount == +this.pq.balance.toFixed(2)
    ) {
      // this.globalService.toast(
      //   '',
      //   this.translocoService.translate(
      //     'Discount amount or rate cannot equal balance'
      //   ),
      //   'middle',
      //   'warning'
      // );
      // return;

      errors.push(
        this.translocoService.translate(
          'Discount amount or rate cannot equal balance'
        )
      );
    }
    if (this.discont.amount == 0 && this.discont.rate == 0) {
      errors.push('Discount amount or rate cannot be 0');
    }
    if (errors.length > 0) {
      this.globalService.toast('', errors[0], 'middle', 'warning');

      this.value = 0;
      this.stringValue = '0';
      this.refreshDiscont();
      return;
    }

    const isSaved: boolean = await this.saveDiscont();
    if (isSaved) {
      this.ps.update({ discountVisible: false });
    }
    this.mc.dismiss();
  }

  selectDiscont(e: Discount) {
    this.discont = e;
    this.type = undefined;
    this.done();
  }

  setDiscont(type: 'rate' | 'amount') {
    if (
      !this.ssoService.checkPermission('payment-use-discount-amount') &&
      !this.ssoService.checkPermission('payment-use-discount-percent')
    ) {
      this.globalService.permissionToast();
      return;
    }
    if (
      !this.ssoService.checkPermission('payment-use-discount-percent') &&
      type === 'rate'
    ) {
      this.globalService.permissionToast();
      return;
    }
    if (
      !this.ssoService.checkPermission('payment-use-discount-amount') &&
      type === 'amount'
    ) {
      this.globalService.permissionToast();
      return;
    }
    this.type = type;
    this.refreshDiscont();
  }

  refreshDiscont() {
    if (this.type == undefined) {
      return;
    }
    const discont: Discount = { name: 'İndirim' } as Discount;
    discont[this.type.toString()] = this.value;
    this.discont = discont;
  }
}
