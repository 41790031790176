import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Folio, FolioRow, RecordStatus } from 'src/app/services/dto/orwi-folio';
import { Old_FolioService as __FolioService__ } from 'src/app/services/folio/folio.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { FolioState, FolioStore } from './folio.store';

@Injectable({ providedIn: 'root' })
export class FolioQuery extends Query<FolioState> {
  openFolios$ = this.select('openFolios');
  openSelfFolio$ = this.select((s) =>
    s.openFolios?.filter((o) => o.type == 'self')
  );
  openTakeawayFolio$ = this.select((s) =>
    s.openFolios?.filter((o) => o.type == 'take-away')
  );

  closedFolio$ = this.select('closedFolios');
  activeFolio$ = this.select('activeFolio');
  activeFolio: Folio;

  activeFolioRows$ = this.select((s) => s.activeFolio.rows);
  activeFolioRow$ = this.select('activeFolioRow');
  newRow$ = this.select('newRow');
  selectedRow$ = this.select('selectedRow');

  activeModifiersGroups$ = this.select('activeModifiersGroup');

  activeFolioPaymentRows$ = this.select((s) =>
    s.activeFolio.rows.filter(
      (fr) => fr.isPayment && fr.recordStatus !== 'deleted'
    )
  );
  activeFolioDiscount$ = this.select((s) =>
    s.activeFolio.rows.filter(
      (fr) => fr.isDiscount && fr.recordStatus !== 'deleted'
    )
  );
  total$ =
    this.select((s) =>
      s.activeFolio?.rows
        .filter((fr) => fr.recordStatus !== 'deleted')
        .reduce((sum, item) => sum + item.unitPrice * item.qty, 0)
    ) || 0;

  folioRowsWODeleted$ = this.select((s) =>
    s.activeFolio.rows.filter((o) => o.recordStatus !== 'deleted')
  );

  changedRows$ = this.select('changedRows');

  showModifiers$ = this.select('showModifiers');
  showModiferGroup$ = this.select('showModifiersGroup');

  prepareDelete$ = this.select('prepareDelete');

  activeSegment$ = this.select('activeSegment');

  MainRowsAll$ = this.select((s) =>
    s.activeFolio?.rows
      .filter((o) => o.parentID == '' && o.recordStatus !== 'deleted')
      .sort(this.rowSort)
  );

  MainRowsNew$ = this.select((s) =>
    s.activeFolio?.rows
      .filter((o) => o.parentID == '' && o.recordStatus == 'new')
      .sort(this.rowSort)
  );

  MainRowsOld$ = this.select((s) =>
    s.activeFolio?.rows
      .filter((o) => o.parentID == '' && o.recordStatus == 'old')
      .sort(this.rowSort)
  );

  MainProductRowsAll$ = this.select((s) =>
    s.activeFolio?.rows
      .filter(
        (o) =>
          o.parentID == '' &&
          o.recordStatus !== 'deleted' &&
          o.rowType == 'product'
      )
      .sort(this.rowSort)
  );

  //deleteReasons$ = this. select("deleteReasons")

  get total() {
    return this.__fs__.getFolioTotal(
      'all',
      true,
      true,
      true,
      this.store.getValue().activeFolio
    );
  }

  get discount() {
    return this.__fs__.getFolioTotal(
      'all',
      true,
      true,
      true,
      this.store.getValue().activeFolio
    );
  }

  modifiers(parentId) {
    return this.store
      .getValue()
      .activeFolio.rows.filter((modifier) => modifier.recordStatus != 'deleted')
      .filter((o) => o.parentID == parentId && o.rowType !== 'note');
  }

  mustModifiers(parentId) {
    return this.store
      .getValue()
      .activeFolio.rows.filter(
        (o) =>
          o.parentID == parentId && o.rowType == 'modifier' && o.isMustModifier
      );
  }

  notes(parentId) {
    const rows: FolioRow[] = this.store
      .getValue()
      .activeFolio.rows.filter(
        (o) =>
          o.parentID == parentId &&
          o.rowType === 'note' &&
          o.recordStatus !== 'deleted'
      );
    return rows;
  }

  mainRows(type: 'all' | 'old' | 'new' = 'all') {
    if (!this.getValue().activeFolio) return [];
    let rows = this.store
      .getValue()
      .activeFolio.rows.filter(
        (o) => o.parentID == '' && o.recordStatus !== 'deleted'
      );
    if (type == 'new') rows = rows.filter((o) => o.recordStatus == 'new');
    if (type == 'old') rows = rows.filter((o) => o.recordStatus == 'old');

    return rows;
  }

  mainRows$() {
    if (this.store.getValue().activeSegment == 'all') {
      return this.MainRowsAll$;
    } else if (this.store.getValue().activeSegment == 'old') {
      return this.MainRowsOld$;
    } else {
      return this.MainRowsNew$;
    }
  }

  folioTotal(fp: IfolioTotal) {
    let _folio = fp.folio ? fp.folio : this.getValue().activeFolio;
    fp.rs = fp.rs || 'all';
    fp.withSpendedPoints = fp.withSpendedPoints || true;
    fp.withDiscount = fp.withDiscount || true;
    fp.withTip = fp.withTip || false;

    let filteredRows = _folio.rows.filter(
      (o) =>
        !o.isGift && !o.isPayment && !o.isLoyalty && o.recordStatus != 'deleted'
    );
    if (!fp.withDiscount) {
      filteredRows = filteredRows.filter((o) => o.isDiscount == false);
    }
    if (fp.rs !== 'all') {
      filteredRows = filteredRows.filter((o) => o.recordStatus == fp.rs);
    }
    let total = filteredRows.reduce(
      (sum, item) => sum + item.unitPrice * item.qty,
      0
    );

    if (fp.withSpendedPoints) {
      total = total - _folio.orwi.spent;
    }
    if (fp.withTip) {
      total += _folio.tip;
    }
    total = parseFloat(total.toFixed(2));
    return total;
    // return filteredRows.reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);
    //TODO:yuvarlama hatası duzeltilmeli
  }

  constructor(
    protected store: FolioStore,
    private __fs__: __FolioService__,
    private ms: ModalService
  ) {
    super(store);

    this.select('activeFolio').subscribe((o) => {
      this.activeFolio = o;
    });

    this.showModifiers$.subscribe((o) => {
      if (o) {
        this.ms.showModifier();
      }
    });

    this.openFolios$.subscribe((o) => {
      if (o) {
        // for (const iterator of o) {
        //     this.store.update(({ openFolios }) => ({
        //         openFolios: arrayUpsert(openFolios, iterator.id, iterator)
        //     }));
        // }
        /*       let act = o.find(p => p.id == this.getValue().activeFolio.id)
                      if (act) {

                          setTimeout(() => {
                              this.store.update({ activeFolio: act })
                          }, 500);

                      } */
      }
    });
  }

  rowSort = (a, b) => {
    if (new Date(a.creation).getTime() < new Date(b.creation).getTime()) {
      return 1;
    } else if (
      new Date(a.creation).getTime() > new Date(b.creation).getTime()
    ) {
      return -1;
    } else {
      return 0;
    }
  };
}

export interface IfolioTotal {
  rs?: RecordStatus;
  withSpendedPoints?: boolean;
  withTip?: boolean;
  withDiscount?: boolean;
  folio?: Folio;
}
