<div
  style="
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  "
>
  <ion-toolbar>
    <ion-item lines="none">
      <ion-icon
        src="/assets/icons/back-btn.svg"
        style="cursor: pointer"
        (click)="segmentHandler('ecr-settings')"
      ></ion-icon>
      <ion-title>{{ this.segments | transloco }}</ion-title>
    </ion-item>
  </ion-toolbar>
  <!--CARDS HOME-->
  <ng-container *ngIf="segments === 'ecr-settings'">
    <div class="modalContainer">
      <ion-row style="width: 100%">
        <ion-col *ngFor="let segment of segmentCards">
          <ion-card
            class="segment-card"
            (click)="segmentHandler(segment.segment)"
          >
            <ion-card-header>
              <ion-icon
                [name]="segment.icon"
                style="font-size: 40px; color: #4cd9ed"
              ></ion-icon>
            </ion-card-header>
            <ion-card-content style="padding-bottom: 0px !important">
              <ion-card-title> {{ segment.title | transloco }} </ion-card-title>
              <ion-card-subtitle>
                {{ segment.type | transloco }}
              </ion-card-subtitle>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </div>
  </ng-container>
  <!--ECR SETTINGS-->
  <ng-container *ngIf="segments === 'ecrSettings'">
    <div class="modalContainer" [formGroup]="ecrForm">
      <div class="cover" style="top: 0px !important">
        <ion-row class="cover_container">
          <div
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <ion-card-title style="padding: 17px; font-weight: 600">{{
              "ECR Settings" | transloco
            }}</ion-card-title>
          </div>
          <ion-col class="input-container" size="12" *ngIf="!printerToggle">
            <ion-row class="items-center">
              <ion-item lines="none">
                <dx-text-box
                  id="ipAddress"
                  formControlName="ecrIp"
                  [useMaskedValue]="true"
                  placeholder="{{ 'ECR IP Address' | transloco }}"
                >
                </dx-text-box>
                <!-- <ng-select *ngIf="printerIpList.length > 0" [items]="printerIpList"
                                        formControlName="ecrIp" placeholder="Cihaza Ait IP Adresini Seçin">
                                    </ng-select> -->
              </ion-item>
            </ion-row>
            <ion-row class="items-center">
              <ion-item lines="none">
                <dx-text-box
                  id="ecrPort"
                  formControlName="port"
                  placeholder="{{ 'ECR Port' | transloco }}"
                >
                </dx-text-box>
              </ion-item>
            </ion-row>
            <ion-row class="items-center">
              <ion-item lines="none">
                <dx-text-box
                  id="ecrSerialNo"
                  formControlName="ecrSerialNo"
                  placeholder="{{ 'ECR Serial No' | transloco }}"
                >
                </dx-text-box>
              </ion-item>
            </ion-row>
            <ion-row class="items-center">
              <ion-item lines="none">
                <dx-text-box
                  id="terminalIpAddress"
                  formControlName="terminalIp"
                  [useMaskedValue]="true"
                  placeholder="{{ 'Terminal IP Address' | transloco }}"
                >
                </dx-text-box>
              </ion-item>
            </ion-row>
            <ion-row class="items-center">
              <!-- <ion-item lines="none"> -->
              <ion-item lines="none">
                <ion-select
                  [interfaceOptions]="customECRoptions"
                  formControlName="ecrModel"
                  [placeholder]="'ECR Service Type' | transloco"
                  okText="{{ 'Done' | transloco }}"
                  cancelText="{{ 'Cancel' | transloco }}"
                  >>
                  <ion-select-option
                    [value]="item.value"
                    *ngFor="let item of ecrServices"
                    >{{ item.label }}
                  </ion-select-option>
                </ion-select>
                <!-- <ion-select  appendTo="body"
                                            formControlName="ecrModel" bindValue="value"
                                            [clearable]="false" placeholder="{{'ECR Service Type'}}"></ion-select> -->
              </ion-item>
              <!-- </ion-item> -->
            </ion-row>
          </ion-col>
          <div *ngIf="printerToggle">
            <ion-col class="input-container" size="12">
              <ion-row class="items-center" *ngFor="let item of printerIpList">
                <ion-item
                  class="printer-selector"
                  *ngIf="printerIpList.length > 0"
                  lines="none"
                  (click)="selectPrinter(item)"
                >
                  <b>{{ item.name }}:</b>
                  <p style="margin-left: 20px">{{ item.ip }}</p>
                </ion-item>
                <ion-item
                  class="printer-selector"
                  *ngIf="printerIpList.length === 0"
                  lines="none"
                >
                  <b>{{ "No printers can be found" | transloco }}</b>
                </ion-item>
              </ion-row>
            </ion-col>
            <footer>
              <ion-buttons slot="primary">
                <ion-button
                  size="small"
                  (click)="printerToggle = false"
                  class="header_giveUp"
                >
                  {{ "GiveUp" | transloco }}
                </ion-button>
              </ion-buttons>
            </footer>
          </div>
          <footer *ngIf="!printerToggle">
            <ion-buttons slot="primary">
              <ion-button
                size="small"
                (click)="segmentHandler('ecr-settings')"
                class="header_giveUp"
              >
                {{ "GiveUp" | transloco }}
              </ion-button>
              <ion-button
                size="small"
                (click)="save()"
                class="header_complete"
                [disabled]="ecrForm.invalid"
              >
                {{ "Complete" | transloco }}
              </ion-button>
            </ion-buttons>
          </footer>
        </ion-row>
      </div>
      <!-- 
            <ion-button size="large" color="danger" (click)="save()">
                {{"Save" | transloco}}
            </ion-button> -->
    </div>
  </ng-container>

  <!--DEPARTMENT DEFINITIONS-->
  <ng-container *ngIf="segments === 'departmentDefinitions'">
    <department-definitions
      class="department-definitions"
    ></department-definitions>
  </ng-container>

  <!--TAX DEFINITIONS-->
  <ng-container *ngIf="segments === 'taxDefinitions'">
    <tax-definitions class="tax-definitions"></tax-definitions>
  </ng-container>
</div>
