<div class="delivery_modal">
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-title>
        {{ "Delivery" | transloco }}
      </ion-title>
      <ion-buttons slot="primary">
        <ion-button size="small" (click)="close()" class="header_giveUp">
          {{ "GiveUp" | transloco }}
        </ion-button>

        <!-- <ion-button
          size="small"
          color="success"
          (click)="post()"
          class="header_complete"
        >
          {{ "Complete" | transloco }}
        </ion-button> -->
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content class="delivery_form" [scrollY]="true">
    <ion-grid [formGroup]="form">
      <ion-row style="position: relative">
        <ion-col size="9" sizeMd="9">
          <ion-item
            lines="none"
            class="ion-no-padding"
            [ngClass]="{
              'orwi-invalid': GSM.invalid,
              'orwi-touched': GSM.touched || GSM.dirty
            }"
          >
            <ion-label position="stacked">{{ "Phone" | transloco }}</ion-label>
            <orwi-phone-input
              class="orwi-phone-input"
              formControlName="gsm"
              (ionBlur)="gsmBlur()"
              (onCountryChange)="gsmBlur()"
            >
            </orwi-phone-input>
          </ion-item>
        </ion-col>
        <ion-col size="3" style="display: flex; align-items: flex-end">
          <button
            class="apply-number-button"
            [disabled]="customer.customerName"
            (click)="addNewCustomer()"
          >
            <span style="color: white !important">{{ "Search" | transloco }}</span>
          </button>
        </ion-col>
      </ion-row>
      <!--  <ion-row>
        <ion-col size="12" [sizeMd]="6">
          <ion-item lines="none">
            <ion-label style="padding-right: 10px" position="stacked">
              {{ "Customer Name" | transloco }}
            </ion-label>
            <ion-input
              class="default_modal_input"
              bindLabel="name"
              bindValue="code"
              formControlName="customerName"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12" [sizeMd]="6">
          <ion-item lines="none">
            <ion-label position="stacked">
              {{ "City" | transloco }}
            </ion-label>

            <ion-select
              formControlName="city"
              [placeholder]="'Select City' | transloco"
              >>
              <ion-select-option
                [value]="item.code"
                *ngFor="let item of CountryInfo.cities"
                >{{ item.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col> 
      </ion-row> -->
      <!-- <ion-row>

                <ion-col size="12" [sizeMd]="6">
                    <ion-item lines="none">
                        <ion-label position="stacked">
                            {{ "Town" | transloco }}
                        </ion-label>

                        <ion-select formControlName="town" [placeholder]="'Select Town' | transloco">
                            <ion-select-option [value]="item.code" *ngFor="let item of CountryInfo.towns">{{ item.name }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-col>
                <ion-col size="12" [sizeMd]="6">
                    <ion-item lines="none">
                        <ion-label position="stacked">
                            {{ "Addres Name" | transloco }}
                        </ion-label>
                        <ng-select appendTo="body" class="addresName" formControlName="adressName" [items]="customer.addresses" bindLabel="addressName" [addTag]="addTagFn" [addTagText]="'Add New Address' | transloco" [loading]="gsmStatus.loading" (change)="addresChanged($event)">
                        </ng-select>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="12" [sizeMd]="6" offsetMd="6" offsetXs="0">
                    <ion-item lines="none">
                        <ion-label position="stacked">
                            {{ "Address" | transloco }}
                        </ion-label>
                        <ion-textarea formControlName="address"></ion-textarea>
                    </ion-item>
                </ion-col>
            </ion-row> -->
    </ion-grid>

    <customer-card
      *ngIf="gsmStatus.loaded && form"
      [isEditing]="isNewCustomer"
      [customerForm]="form"
      (onSelectCustomer)="post()"
      (onAddNewAddress)="addAddressToForm($event)"
      (onDeleteAddress)="removeAddressFromForm($event)"
    ></customer-card>

    <!-- <ion-grid class="ion-padding" *ngIf="gsmStatus.loaded">
      <ion-row>
        <ion-col>
          <div class="customer-information">
            <div class="customer-title">
              {{ customer.customerName | titlecase }}
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item lines="none">
            <ion-card-subtitle>
              {{ "defined addresses" | transloco }}
            </ion-card-subtitle>
          </ion-item>
        </ion-col>
        <ion-col size="12" *ngIf="customer.addresses.length == 0">
          <ion-item lines="none">
            <ion-icon
              style="margin-inline-end: 10px; margin-right: 10px"
              icon="information-circle"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{
                "The defined address could not be found. You can add a new address."
                  | transloco
              }}
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-col>
        <ion-list>
          <ion-radio-group>
            <customer-address-item
              *ngFor="let adres of Addresses.controls"
              [addressFormGroup]="adres"
            ></customer-address-item>
          </ion-radio-group>
        </ion-list>
      </ion-col>
    </ion-grid> -->

    <!-- <ion-grid style="margin-top: 16px" *ngIf="gsmStatus.loaded && form">
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <h4>
              <b> {{ "Past Orders" | transloco }} </b>
            </h4>
          </ion-item>
        </ion-col>
        <ion-col style="display: flex; justify-content: flex-end">
          <ion-button fill="clear">{{ "All" | transloco }}</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col
          size="12"
          [sizeMd]="12"
          [sizeLg]="12"
          size="12"
          sizeMd="12"
          *ngFor="let folio of customerFolios"
        >
          <ion-card
            style="
              padding: 10px;
              box-shadow: 0px 5px 15px #f0f2fe;
              border-radius: 8px;
            "
          >
            <ion-card-header style="padding: 0">
              <ion-card-title>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;
                    align-items: center;
                    color: #171e4e;
                  "
                >
                  <div>
                    {{ folio.creationDate | date: "dd MMM yyyy HH:mm" }}
                  </div>
                  <div>
                    {{ folio.grandTotal | orwiCurrency }}
                  </div>
                  <ion-buttons class="order-btns" slot="end">
                    <ion-button style="margin-right: 16px">
                      <ion-icon name="receipt-outline"></ion-icon>
                      {{ "Order Details" | transloco }}</ion-button
                    >
                    <ion-button (click)="repeat(folio)">
                      <ion-icon name="reload-outline"></ion-icon>
                      {{ "Repeat" | transloco }}</ion-button
                    >
                  </ion-buttons>
                </div>
              </ion-card-title>
            </ion-card-header>

            <ion-card-content style="padding: 0">
              <div class="past-order-content">
              </div>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid> -->
  </ion-content>
</div>
