<div style="margin-left: 10px; font-weight: bold">{{"Tables" | transloco}}</div>
<div>
  <ion-grid>
    <ion-row>
      <ion-col><b>{{ "Name" | transloco }}</b></ion-col>
      <ion-col size="2"><b>#</b></ion-col>
      <ion-col size="2"><b> {{ "Person Count" | transloco}} </b></ion-col>
      <ion-col size="3"><b>{{"Total" | transloco}}</b></ion-col>
    </ion-row>
  </ion-grid>
</div>
<div style="height: calc(100% - 3rem); overflow-y: scroll">
  <ion-grid>
    <ion-row
      *ngFor="
        let item of combinedFolios
        let index = index;
        let isEven = even"
      class="item"
      [ngStyle]="{'background-color': isEven ? '#E4E7F8' : ''}"
      [ngClass]="{'selected':isChecked(item)}"
      (click)="onRowClick(item)">
      <ion-col>{{ item.name | slice: 0:12 }}</ion-col>
      <ion-col size="2">#{{ index + 1 }}</ion-col>
      <ion-col size="2"> {{ item.info?.pax }} </ion-col>
      <ion-col size="3">
        {{ item.folios | orwiFoliosTotalPrice | orwiCurrency }}
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
