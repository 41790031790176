import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import {
  OrwiCity,
  OrwiCountry,
  OrwiTown,
} from 'src/app/services/dto/orwi-address';
import { Folio, deliveryFolio } from 'src/app/services/dto/orwi-folio';
import { CountryCodesService } from 'src/app/services/location/country-codes.service';
import { DeliveryQuery } from '../../state/delivery.query';
import {
  Customer,
  CustomerAddress,
} from 'src/app/services/customer/customer.model';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';

@Component({
  selector: 'orwi-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss'],
})
export class DeliveryFormPage implements OnInit {
  form: UntypedFormGroup;
  customer: Customer = { addresses: [] };
  gsmStatus = {
    loading: false,
    loaded: false,
  };
  customerFolios: deliveryFolio[] = [
    {
      id: '12312asdasd12312',
      grandTotal: 50,
      creationDate: new Date(),
      storeName: 'Orwi Test Demo',
      storeId: '123123132',
      status: 'done',
      showDetail: false,
      deliveryStatus: 'completed',
      type: '???',
    },
    {
      id: '12312asdasd12312',
      grandTotal: 50,
      creationDate: new Date(),
      storeName: 'Orwi Test Demo',
      storeId: '123123132',
      status: 'done',
      showDetail: false,
      deliveryStatus: 'completed',
      type: '???',
    },
    {
      id: '12312asdasd12312',
      grandTotal: 50,
      creationDate: new Date(),
      storeName: 'Orwi Test Demo',
      storeId: '123123132',
      status: 'done',
      showDetail: false,
      deliveryStatus: 'completed',
      type: '???',
    },
  ];

  get GSM(): UntypedFormControl {
    return this.form.get('gsm') as UntypedFormControl;
  }

  get Addresses(): UntypedFormArray {
    return this.form.get('addresses') as UntypedFormArray;
  }
  CountryInfo: {
    countries: OrwiCountry[];
    cities: OrwiCity[];
    towns: OrwiTown[];
  } = {
    countries: [],
    cities: [],
    towns: [],
  };
  constructor(
    private fb: UntypedFormBuilder,
    public deliveryQuery: DeliveryQuery,
    private countryCodesService: CountryCodesService,
    private folioService: FolioService,
    private glb: GlobalService,
    private folioStore: FolioStore,
    private customerService: CustomerService,
    private idGeneratorService: IdGeneratorService,
    private ssoService: SSOSessionService
  ) {
    this.initForm();
    // this.getCountries();
    this.getCity('TR');
  }
  async getCountries() {
    this.CountryInfo.countries = (await this.countryCodesService.getCountries()) as OrwiCountry[];
  }
  async getCity(country: string) {
    country; //reserved
    // this.City.setValue(null);
    // if (country !== null && country !== undefined && country.length > 0) {
    //   this.CountryInfo.cities = (await this.countryCodesService.getCities(
    //     country
    //   )) as OrwiCity[];
    //   this.CountryInfo.cities.sort((a, b) => +a.code - +b.code);
    // }
  }

  async getTown(country: string, city: string) {
    country;
    city; //reserved

    // this.Town.setValue(null);
    // if (
    //   (city !== null && city !== undefined && city.length > 0) ||
    //   (country !== null && country !== undefined && country.length > 0)
    // ) {
    //   this.CountryInfo.towns = (await this.countryCodesService.getTowns(
    //     country,
    //     city
    //   )) as OrwiTown[];
    //   this.CountryInfo.towns.sort((a, b) => +a.code - +b.code);
    // }
  }
  initForm() {
    this.form = this.fb.group({
      gsm: [, Validators.required],
      customerName: [, Validators.required],
      // country: [, Validators.required],
      addresses: new FormArray([]),
    });
    // this.Country.valueChanges.subscribe((country) => {
    //   if (country !== null && country !== undefined && country.length > 0) {
    //     this.getCity(country);
    //   } else {
    //     this.getCity(null);
    //   }
    // });
    // this.City.valueChanges.subscribe((city) => {
    //   if (city !== null && city !== undefined && city.length > 0) {
    //     this.getTown('TR', city);
    //   } else {
    //     this.getTown(null, null);
    //   }
    // });
  }

  ngOnInit() {}
  //close(value?: boolean) {
  //if (!value) {
  //this.modalController.dismiss();
  //this.router.pop();
  //return;
  //}
  //this.post();
  //}
  close() {
    this.glb.closeModal();
  }
  async post() {
    if(!this.ssoService.checkPermission('folio-open')){
      this.glb.permissionToast();
      return
    }
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.glb.showLoading('');

    const customer: Customer = await this.saveCustomer();
    let selectedAddress = customer.addresses.find(
      (address) => address.selected
    );
    console.log('customerAddresses', customer.addresses)
    console.log("selectedAddress", selectedAddress)
    if (!selectedAddress) {
      if (customer.addresses.length > 0) {
        selectedAddress = customer.addresses[0];
      } else {
        this.glb.toast('', 'Please Select Address', 'middle', 'warning');
        return;
      }
    }
    const folio: Folio = await this.folioService.createFolio(
      null,
      null,
      'delivery'
    );

    folio.deliveryStatus = 'prepared';
    folio.status = 'open';
    folio.table.id = customer.gsm
    folio.table.name = customer.customerName;
    folio.customerName = customer.customerName;
    folio.type = 'delivery';
    folio.deliveryAddressId = selectedAddress.id;
    folio.deliveryAddress = selectedAddress.address
    await this.folioService._posSaveFolio(folio, 'low');
    this.folioStore.update({ activeFolio: folio });

    this.glb.closeLoading();

    this.glb.navigateFolioForm(false);

    this.close();
  }
  async saveCustomer() {
    let address = this.Addresses.value.find((el) => el.selected);
    // this.customer.addresses.find((el) => el.selected) ??
    // ({ id: this.idGeneratorService.generateMaxi() } as CustomerAddress);
    if (!address && this.Addresses.value.length == 1) {
      address = this.Addresses.value[0];
    }
    this.customer.customerName = this.form.get('customerName').value;
    this.customer.addresses = this.Addresses.value;
    this.customer.gsm = this.form.get('gsm').value;
    console.log('CUSTOMER ', this.customer);
    await this.customerService.save(this.customer);
    return this.customer
  }

  isNewCustomer = false;

  async gsmBlur() {
    console.log('try');
    // 5556038390
    this.gsmStatus.loading = true;
    this.form.reset({ gsm: this.GSM.value });
    this.customer = {};
    try {
      if (!this.GSM.value) return;
      this.glb.showLoading();
      this.customer = await this.customerService.getAddressByGSM(
        this.GSM.value
      );
      console.clear();
      this.removeAllAddress();
      console.log('REMOVED ADRES', this.Addresses);
      if (!this.customer.customerName) {
        // this.glb.toast('', 'Customer Not Found', 'middle', 'warning');
        this.isNewCustomer = true;
        this.gsmStatus.loading = false;
        this.gsmStatus.loaded = true;
        this.glb.closeLoading();
        this.addAddressToForm({ addressName: 'Yeni Adres' });
        return;
      }
      console.log('CUSTOMER Adres', this.customer);
      this.form
        .get('customerName')
        .setValue(this.customer.customerName ?? null);
      this.customer.addresses.forEach((elm) => {
        this.addAddressToForm(elm);
      });
      console.log('customer', this.customer);
      console.log('form', this.form.value);
      await this.folioService.getOldFolios('all', 5);
      //this.customerFolios = await this.folioService.getOldFolios('all', 5);
      console.log('customerFolios', this.customerFolios);

      this.gsmStatus.loading = false;
      this.gsmStatus.loaded = true;
      this.glb.closeAllLoading();
      this.glb.closeLoading();
    } catch (error) {
      console.warn('error', error);
      this.glb.closeLoading();

      this.gsmStatus.loading = false;
    }
  }

  resetCustomer() {
    this.gsmStatus = {
      loading: false,
      loaded: false,
    };
    this.GSM.setValue(null);
  }

  addAddressToForm(data?: CustomerAddress) {
    this.Addresses.push(
      new FormGroup({
        id: new FormControl(data?.id ?? this.idGeneratorService.generate()),
        addressName: new FormControl(data?.addressName, Validators.required),
        city: new FormControl(data?.city, Validators.required),
        town: new FormControl(data?.town, Validators.required),
        address: new FormControl(data?.address, Validators.required),
        selected: new FormControl(data?.selected),
      })
    );
  }

  removeAddressFromForm(index: number, force = false) {
    if (this.Addresses.controls.length > 0 || force)
      this.Addresses.removeAt(index);
  }

  removeAllAddress() {
    console.log(this.Addresses.controls.length);
    while (this.Addresses.controls.length !== 0) {
      this.removeAddressFromForm(0, true);
    }
  }

  selectMainAddress(index: number) {
    this.Addresses.controls.forEach((element) => {
      element.get('selected').setValue(false);
      element.updateValueAndValidity();
    });
    this.Addresses.at(index).get('selected').setValue(true);
    this.Addresses.updateValueAndValidity();
  }

  addTagFn(name) {
    return { addressName: name, id: -1, tag: true };
  }

  addresChanged(item: CustomerAddress) {
    console.log('step1', item);
    if (+item.id === -1) {
      return;
      console.log('step2 if');
      this.form.reset(
        { gsm: this.GSM.value, adressName: item },
        { emitEvent: false }
      );
    } else {
      //  this.form.patchValue(item);
      this.form.get('adressName').setValue(item);
      this.form.get('city').setValue(item.city);
      setTimeout(() => {
        this.form.get('town').setValue(item.town);
      }, 250);
      this.form.get('address').setValue(item.address);
    }
  }

  repeat(folio: deliveryFolio) {
    console.log(folio);
  }

  addNewCustomer() {
    this.customer = {
      customerName: null,
      addresses: [],
    };
  }
}
