import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IonDatetime, ModalController, Platform } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { ParametersQuery } from 'src/app/modules/parameters/state/parameters.query';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { Printer } from 'src/app/services/dto/orwi-definitions';
import { ZReport } from 'src/app/services/dto/zReport';
import { GlobalService } from 'src/app/services/global.service';
import { PrintCoverService } from 'src/app/services/printer/print-cover.service';
import { PrinterPrepareService } from 'src/app/services/printer/printer.service';
import { ZreportService } from 'src/app/services/zReport/zreport.service';

@Component({
  selector: 'z-report-form',
  templateUrl: './z-report-form.component.html',
  styleUrls: ['./z-report-form.component.scss'],
})
export class ZReportFormComponent implements OnInit {
  printers: Printer[] = [];
  showPickerStart = false;
  showPickerEnd = false;
  dateValue = new Date();
  dateValueStart = this.dateValue;
  dateValueEnd = this.dateValue;
  isDetailed: boolean = true;
  @ViewChild('datetimePickerStart', { static: false }) datetimePickerStart: IonDatetime;
  @ViewChild('datetimePickerEnd', { static: false }) datetimePickerEnd: IonDatetime;

  constructor(
    private printerPrepareService: PrinterPrepareService,
    private printerCoverService: PrintCoverService,
    private fb: UntypedFormBuilder,
    private glb: GlobalService,
    private mdlController: ModalController,
    private plt: Platform,
    private zReportService: ZreportService,
    public ssoService: SSOSessionService,
    private paramQuery: ParametersQuery,
    public transloco: TranslocoService
  ) {
    this.printerPrepareService.getPrinters().then((res) => {
      this.printers = res;
    });
  }

  form = this.fb.group({
    printer: [null, Validators.required],
    startDate: [moment( moment(this.dateValue).format("YYYY-MM-DD") + 'T' + moment.utc(this.paramQuery.getValue().posParameters.globalParameters.openTime).format("HH:mm")).utc(true).format("YYYY-MM-DDTHH:mm:ss.SSS")],
    endDate: [moment( moment(this.dateValue).add(1,'days').format("YYYY-MM-DD") + 'T' + moment.utc(this.paramQuery.getValue().posParameters.globalParameters.closeTime).format("HH:mm")).utc(true).format("YYYY-MM-DDTHH:mm:ss.SSS")]
  });

  async ngOnInit() {
    // const startDate = await this.paramQuery.startDate();
    // const endDate = await this.paramQuery.endDate();
    // this.form.patchValue({
    //   startDate,
    //   endDate,
    // });
    if (!this.ssoService.checkPermission('z-report-product-detail')) {
      this.isDetailed = false;
    }
    console.log(this.form.value);
    console.log();
  }

  onDatetimeConfirm(field: string, event: any) {
    const selectedValue = event.detail.value;

    if (selectedValue) {
      const formattedDate = moment(selectedValue).isValid()
        ? moment(selectedValue).format('YYYY-MM-DDTHH:mm:ss.SSS')
        : null;

      if (formattedDate) {
        this.form.patchValue({ [field]: formattedDate });
      }
    }

    if (field === 'startDate') {
      this.showPickerStart = false;
    } else if (field === 'endDate') {
      this.showPickerEnd = false;
    }
  }

  onDatetimeCancel() {
    this.showPickerStart = false;
    this.showPickerEnd = false;
  }

  close() {
    this.glb.closeModal();
  }

  clearSelection() {
    this.form.patchValue({ printer: null });
  }

  getError(ctrlName: string) {
    return (
      this.form.get(ctrlName).touched &&
      this.form.get(ctrlName).hasError('required')
    );
  }

  async print(ipAddress: string, zreport: ZReport, startDate, endDate) {
    let readySource = await this.plt.ready();
    //  ipAddress = "192.168.1.100"
    let printStr = this.printerPrepareService.convertZReportV2(
      zreport,
      startDate,
      endDate,
      this.isDetailed
    );
    console.log('detailed', this.isDetailed);
    if (readySource == 'cordova') {
      this.glb.toast('', 'Z Report Sent', 'bottom', 'success');
      console.log('socket connect baslatiliyor');
      await this.printerCoverService.printToNativeAppV2(
        this.printers.find((x) => x.ip === ipAddress),
        printStr
      );
    } else {
      console.log('platform:' + readySource);
      this.glb.toast('', 'Z Report Sent', 'bottom', 'success');
      await this.printerCoverService.printToServerV2(printStr, ipAddress, 1);
    }
    //
  }

  toggleDetail(e) {
    console.log('isdetailed', e);
    this.isDetailed = e.detail.checked;
  }

  async send() {
    if (!this.ssoService.checkPermission('z-report-create')) {
      this.glb.permissionToast();
      return;
    }
    this.form.markAllAsTouched();
    let sDate;
    let eDate;
    console.log('Form DATA :', this.form.value.printer);
    if (this.form.valid) {
      const selectedPrinter = this.printers.find(p => p.id === this.form.value.printer);
      // this.form.value.startDate == null ?
      //   sDate = moment(this.dateValue).startOf("day").utc(true).toISOString() :
      //   sDate = moment(this.form.value.startDate).startOf("day").utc(true).toISOString();
      //   sDate = moment(moment(sDate).format("YYYY-MM-DD") + 'T' + moment(this.paramQuery.getValue().posParameters.globalParameters.openTime).format("HH:mm")).utc(true).toISOString();

      // this.form.value.endDate == null ?
      //   eDate = moment(this.dateValue).endOf("day").utc(true).toISOString() :
      //   eDate = moment(this.form.value.endDate).endOf("day").utc(true).toISOString();
      //   eDate = moment( moment(eDate).format("YYYY-MM-DD") + 'T' + moment(this.paramQuery.getValue().posParameters.globalParameters.closeTime).format("HH:mm")).utc(true).toISOString();

      //   this.form.value.startDate = sDate
      //   this.form.value.endDate = eDate
      // let start = moment(moment(this.form.value.startDate).format("YYYY-MM-DD") + 'T' + moment.utc(this.paramQuery.getValue().posParameters.globalParameters.openTime).format("HH:mm")).utc(true).toISOString();
      // let start = await this.paramQuery.startDate(this.form.value.startDate);
      //  let eDate = moment(this.dateFilterForm.value.endDate).endOf("day").utc(true).toISOString();
      //  let end = moment( moment(this.form.value.endDate).format("YYYY-MM-DD") + 'T' + moment.utc(this.paramQuery.getValue().posParameters.globalParameters.closeTime).format("HH:mm")).utc(true).toISOString();
      // let end = await this.paramQuery.endDate(this.form.value.endDate);

      // if (
      //   moment(start).format('YYYY-MM-DD') === moment(end).format('YYYY-MM-DD')
      // ) {
      //   end = moment(end).add(1, 'd').toISOString();
      // }
      this.zReportService.getZReportChoosenDate(this.form.value.startDate + 'Z', this.form.value.endDate + 'Z').then((x) => {
        console.log('dates', sDate, eDate);
        console.log('zreport data', x);
        this.mdlController.dismiss();
        this.print(selectedPrinter.ip, x, this.form.value.startDate + 'Z', this.form.value.endDate + 'Z');
      });
    }
  }
}
